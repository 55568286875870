import Head from "next/head";
import { useRouter } from "next/router";

const defaultMeta = {
  title: "Apply to Teach Courses as a Mentor | Novatr",
  site_name: "Novatr website",
  description:
    "Our mission drives us to build the Design University of the Future. It all started with us with a goal to. We do this by creating online courses for designers to upskill at every stage of their career, by teaching relevant skills that get designers better professional growth and by building a community of designers that can help each other be infinite learners.",
  url: process.env.NEXT_PUBLIC_WEB_URL,
  image: process.env.NEXT_PUBLIC_WEB_URL + "/preview.jpg",
  type: "website",
  robots:
    process.env.NEXT_PUBLIC_ENVIRONMENT === "prod"
      ? "follow, index"
      : "nofollow, noindex",
};

type SeoProps = {
  title?: string;
  description?: string;
  image?: string;
  data?: any;
} & Partial<typeof defaultMeta>;

export default function Seo(props: SeoProps) {
  const data = props.data;
  const meta = {
    ...defaultMeta,
    ...props,
  };
  const siteUrl = "https://www.novatr.com";
  const { asPath } = useRouter();
  const cleanPath = asPath.split("#")[0].split("?")[0];
  const canonicalUrl = `${siteUrl}` + (asPath === "/" ? "" : cleanPath);

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
      <title>{meta.title}</title>
      {/* <meta name="robots" content={meta.robots} />
      <meta name="googlebot" content={meta.robots} />
      <meta content={meta.description} name="description" />
      <meta property="og:url" content={`${meta.url}${router.asPath}`} />
      <link rel="canonical" href={`${meta.url}${router.asPath}`} /> */}

      {/* Open Graph */}
      {/* <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content={meta.site_name} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:image:url" content={meta.image} />
      <meta property="og:image:alt" content={meta.title} />
      <meta property="og:image:type" content="images/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" /> */}

      {/* Twitter */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <meta property="twitter:image:alt" content={meta.title} /> */}

      {/* Favicon  */}
      {/* <link rel="shortcut icon" href={"/favicon.ico"} />

      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={meta.image} />
      <meta name="theme-color" content="#ffffff" /> */}

      {data &&
        data.map((ele: any, ind: any) => {
          return (
            <meta
              key={ind}
              name={ele.name ? ele.name : undefined}
              content={ele.content ? ele.content : undefined}
              data-hid={ele["data-hid"] ? ele["data-hid"] : undefined}
              property={ele.property ? ele.property : undefined}
              dangerouslySetInnerHTML={
                ele.dangerouslySetInnerHTML
                  ? ele.dangerouslySetInnerHTML
                  : undefined
              }
            />
          );
        })}
    </Head>
  );
}
