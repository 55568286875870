import React from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";

import { AnimateQuestionChange } from "../AnimateQuestion";
import BinaryScale from "../QuestionForm/BinaryScale";
import EmojiScale from "../QuestionForm/EmojiScale";
import MultipleChoice from "../QuestionForm/MultipleChoice/MultipleChoice";
import { OptionType, choiceEnum } from "../QuestionForm/MultipleChoice/type";
import OpinionScale from "../QuestionForm/OpinionScale";
import QuestionFormHoc from "../QuestionForm/QuestionFormHoc";
import RatingScale from "../QuestionForm/RatingScale";
import TextField from "../QuestionForm/TextField";
import {
  FEEDBACK_QUESTION_TYPE,
  FeedbackOptions,
  FeedbackQuestions,
  QuestionObjPropType,
} from "../type";
const StyledBox = styled(Box)`
  width: calc(100% + 10px);
`;

interface FormBodyPropType {
  payload: any[];
  setPayload: (data: any[]) => void;
  questions: FeedbackQuestions[];
  selectQuestionIndex: number;
  setSelectedQuestionIndex: (index: number) => void;
  mixpanelObj: any;
  isBottomContainer: boolean;
}
export default function FormBody({
  payload,
  setPayload,
  questions,
  selectQuestionIndex,
  setSelectedQuestionIndex,
  mixpanelObj,
  isBottomContainer,
}: FormBodyPropType) {
  const handleChange = (
    questionObj: QuestionObjPropType,
    dataToSubmit: FeedbackOptions[] | string | number
  ) => {
    const currentPayload = [...payload];
    const length = questionObj?.options?.length;
    const newMixpanelObj = {
      ...mixpanelObj,
      Button_text: dataToSubmit || null,
      question_content: questionObj.question || null,
      question_tag: questionObj.meta.questionTag || null,
      category: questionObj.meta.category || null,
      sub_category: questionObj.meta.subCategory || null,
      min_score: length > 0 ? questionObj?.options[0].optionId : null,
      max_score: length > 0 ? questionObj?.options[length - 1].optionId : null,
    };
    switch (questionObj.questionType) {
    case FEEDBACK_QUESTION_TYPE.MULTI_CHOICE: {
      const index = currentPayload.findIndex(
        (element) => element.questionId === questionObj.questionId
      );
      if (index !== -1) {
        currentPayload[index].entries = (dataToSubmit as FeedbackOptions[]).map((element) => {
          return { optionId: element.optionId, text: element.text };
        });
      } else {
        currentPayload.push({
          questionId: questionObj.questionId,
          entries: (dataToSubmit as FeedbackOptions[]).map((element) => {
            return { optionId: element.optionId, text: element.text };
          }),
        });
      }
      trackleadform("Button_Click", newMixpanelObj);
      break;
    }
    case FEEDBACK_QUESTION_TYPE.SUBJECTIVE: {
      const index = currentPayload.findIndex(
        (element) => element.questionId === questionObj.questionId
      );
      if (index !== -1) {
        currentPayload[index].entries = [
          {
            optionValue: dataToSubmit,
          },
        ];
      } else {
        currentPayload.push({
          questionId: questionObj.questionId,
          entries: [
            {
              responseValue: dataToSubmit,
            },
          ],
        });
      }
      break;
    }
    case FEEDBACK_QUESTION_TYPE.SINGLE_CHOICE: {
      trackleadform("Button_Click", newMixpanelObj);
      currentPayload.push({
        questionId: questionObj.questionId,
        entries: dataToSubmit,
      });
      break;
    }
    default: {
      trackleadform("Button_Click", newMixpanelObj);
      currentPayload.push({
        questionId: questionObj.questionId,
        entries: [
          {
            optionId: dataToSubmit,
          },
        ],
      });
      break;
    }
    }
    setPayload(currentPayload);
  };

  const selectForms = (elementConfig: any) => {
    const { questionType, questionId, question, meta, options } = elementConfig;
    switch (elementConfig.questionType) {
    case FEEDBACK_QUESTION_TYPE.SCALE_NUMBER: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <OpinionScale
            questionObj={{ questionType, questionId, question, meta, options }}
            options={elementConfig.options}
            handleChange={handleChange}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            min={0}
            max={elementConfig.options.length}
            bottomValueText={"negative"}
            topValueText={"positive"}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.BINARY_LIKE_DISLIKE: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <BinaryScale
            handleChange={handleChange}
            options={elementConfig.options}
            questionObj={{ questionType, questionId, question, meta, options }}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.BINARY_TRUE_FALSE: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <BinaryScale
            questionObj={{ questionType, questionId, question, meta, options }}
            handleChange={handleChange}
            options={elementConfig.options}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.BINARY_YES_NO: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <BinaryScale
            questionObj={{ questionType, questionId, question, meta }}
            handleChange={handleChange}
            options={elementConfig.options}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.SCALE_EMOJI: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <EmojiScale
            questionObj={{ questionType, questionId, question, meta, options }}
            handleChange={handleChange}
            options={elementConfig.options}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.SINGLE_CHOICE: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <MultipleChoice
            options={elementConfig.options}
            handleChange={(options: Array<OptionType>) => {
              handleChange({ questionType, questionId, question, meta, options }, options);
            }}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            choiceType={choiceEnum.SINGLE_CHOICE}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.MULTI_CHOICE: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <MultipleChoice
            options={elementConfig.options}
            handleChange={(options: Array<OptionType>) => {
              handleChange({ questionType, questionId, question, meta, options }, options);
            }}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            choiceType={choiceEnum.MULTIPLE_CHOICE}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.SCALE_STAR: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <RatingScale
            handleChange={handleChange}
            questionObj={{ questionType, questionId, question, meta, options }}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            min={0}
            max={5}
          />
        </QuestionFormHoc>
      );
    }
    case FEEDBACK_QUESTION_TYPE.SUBJECTIVE: {
      return (
        <QuestionFormHoc
          isBottomContainer={isBottomContainer}
          key={questionId}
          question={elementConfig.question}
          questionSubtext={""}
        >
          <TextField
            questionObj={{ questionType, questionId, question, meta }}
            handleChange={handleChange}
          />
        </QuestionFormHoc>
      );
    }
    }
  };

  return (
    <StyledBox position={"relative"} left={-5}>
      <AnimateQuestionChange id={questions[selectQuestionIndex].questionId} direction={1}>
        {selectQuestionIndex !== questions.length && selectForms(questions[selectQuestionIndex])}
      </AnimateQuestionChange>
    </StyledBox>
  );
}
