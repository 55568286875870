import React, { ReactNode } from "react";

import Box from "@/design-system/Box";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

import { QuestionFormHocPropTypes } from "../type";

export default function QuestionFormHoc({
  question,
  questionSubtext,
  children,
  isBottomContainer,
}: QuestionFormHocPropTypes) {
  return (
    <Box
      px={{ __: theme.spacing(2), md: theme.spacing(12) }}
      py={{ __: theme.spacing(4), md: theme.spacing(12) }}
      borderRadius={theme.radius(3)}
      background={theme.colors.base.white}
      display={"flex"}
      flexDirection={"column"}
      gap={theme.spacing(8)}
    >
      <Box py={theme.spacing(8)}>
        <Box
          px={{ __: theme.spacing(2), md: theme.spacing(2) }}
          display={"flex"}
          flexDirection={"column"}
          gap={theme.spacing(4)}
        >
          <Typography
            color={theme.colors.purple[800]}
            varient={isBottomContainer ? "heading5" : "bodyl"}
            weightVarient={isBottomContainer ? "semibold" : "medium"}
          >
            {question}
          </Typography>
          <Typography color={theme.colors.gray[600]} varient="bodys" weightVarient="regular">
            {questionSubtext}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
}
