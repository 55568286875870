import React, { useState } from "react";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { theme } from "@/theme";

import { RatingScalePropTypes } from "../type";

export default function RatingScale({
  min,
  max,
  setSelectedQuestionIndex,
  handleChange,
  questionObj,
}: RatingScalePropTypes) {
  const [rating, setRating] = useState<number>(0);
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const handleRatingChange = (index: number) => {
    handleChange(questionObj, index);
    setRating(index);
    setTimeout(
      () => setSelectedQuestionIndex((prevValue: number) => (prevValue + 1) as number),
      1000
    );
  };
  return (
    <Box
      borderRadius={theme.radius(3)}
      background={theme.colors.base.white}
      display={"flex"}
      flexDirection={"column"}
      gap={theme.spacing(8)}
    >
      <FlexContainer alignItems={"center"} gap={theme.spacing(2)}>
        {Array.from({ length: max - min }).map((_, index) => {
          const showColored = index < rating;
          const showHoverStar = index < hoverIndex;
          return (
            <UnstyledButton
              key={index}
              onClick={() => {
                handleRatingChange(index + 1);
              }}
              onMouseEnter={() => setHoverIndex(index + 1)}
              onMouseLeave={() => setHoverIndex(0)}
            >
              <Icon
                key={index}
                varient={"lms"}
                type={
                  showColored ? "gradient-star" : showHoverStar ? "hover-star" : "unselected-star"
                }
                size={40}
              />
            </UnstyledButton>
          );
        })}
      </FlexContainer>
    </Box>
  );
}
