import { useEffect, useState } from "react";

function useMediaQuery(query: string, isMobileCurrently = false) {
  const [matches, setMatches] = useState(isMobileCurrently);
  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    function handleChange(e: { matches: boolean }) {
      setMatches(e.matches);
    }
    setMatches(matchQueryList.matches);
    if (matchQueryList?.addEventListener) matchQueryList.addEventListener("change", handleChange);
    return () => {
      if (matchQueryList?.addEventListener)
        matchQueryList.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}

export default useMediaQuery;
