import { appBaseCreateApi } from "./BaseQueryApiClient";

export const mentorPerformanceApi = appBaseCreateApi({
  reducerPath: "mentorPerformanceApi",
  endpoints: (builder) => ({
    getMentorPerformanceData: builder.query({
      query: (cohortId) => `v1/client/mentor/performance/leaderboard/${cohortId}`,
    }),
    getLearnerLeaderBoardData: builder.query({
      query: (cohortId) => `v2/client/lms/user/${cohortId}/leaderboard`,
    }),
  }),
});

export const { useGetMentorPerformanceDataQuery, useGetLearnerLeaderBoardDataQuery } =
  mentorPerformanceApi;
