import { baseQueryApiClient } from "./BaseQueryApiClient";

export const FeedbackClient = baseQueryApiClient.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackForm: builder.query({
      query: (payload) =>
        `v1/client/in-app-notification/user-event?page=${payload.page}&cohortId=${payload.cohortId}`,
    }),
    saveFormResponse: builder.mutation({
      query(payload) {
        return {
          url: `v1/client/in-app-notification/user-event-response?isSkipped=${payload.isSkipped}&cohortId=${payload.cohortId}&notification_id=${payload.notificationId}`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["ONBOARDING-UPDATE"],
    }),
  }),
});

export const { useGetFeedbackFormQuery, useSaveFormResponseMutation } = FeedbackClient;
