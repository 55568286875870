import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { QuizV2State } from "@/utils/types.enums";

import { UtilitySliceIntialState } from "./types/utilitySlice.type";

const initialState: UtilitySliceIntialState = {
  isMobile: false,
};

const utilitySlice = createSlice({
  name: "utilitySlice",
  initialState,
  reducers: {
    setIsMobileScreen: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsMobileScreen } = utilitySlice.actions;
export default utilitySlice.reducer;
