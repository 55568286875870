import Link from "next/link";

export const LinkWrapper = ({ href, disabled, children, ...props }) => {
  if (disabled) {
    return <>{children}</>;
  }
  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};
