import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nodeState: null,
  skills: null,
  preview: false,
  selectedProject: null,
  selectedZone: null,
  isPreview: false,
};

const nodeSlice = createSlice({
  name: "nodeSlice",
  initialState,
  reducers: {
    setNodeState(state, action) {
      state.nodeState = action.payload;
    },
    setSkills(state, action) {
      state.skills = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setSelectedZone(state, action) {
      state.selectedZone = action.payload;
    },
    setIsPreview(state, action) {
      state.isPreview = action.payload;
      state.preview = action.payload;
    },
  },
});

export const { setNodeState, setSkills, setSelectedProject, setSelectedZone, setIsPreview } =
  nodeSlice.actions;
export default nodeSlice.reducer;
