import { FC } from "react";

import { TertiaryStyledButton } from "./tertiaryButton.styles";
import { TertiaryButtonProps } from "./types";

const TertiaryButton: FC<TertiaryButtonProps> = ({ color, ...rest }) => {
  return <TertiaryStyledButton color={color} {...rest} />;
};

export default TertiaryButton;

TertiaryButton.defaultProps = {
  color: "purple",
};
