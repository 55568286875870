import { CourseType } from "@/client/type";

export interface EligibilityData {
  placementFormAvailable?: boolean;
  placementFormFilled?: boolean;
  overallPercent: number;
  eligiblePercent: number;
  isGraduated: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  cvSubmitted: boolean;
  portfolioSubmitted: boolean;
  profileCompleted: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  reviewDayDate?: string;
  attendedReviewDay: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  isEligibleForPlacements:
    | "ELIGIBILITY_IN_PROGRESS"
    | "ELIGIBLE"
    | "NOT_ELIGIBLE"
    | "NOT_COMPLETED"
    | "BASIC_PROFILE_COMPLETED";
  cohortPlacementUnlocked: boolean;
  interestedInPlacements: boolean;
  jobsAvailable: boolean;
  jobsUnlocked: boolean;
  hideEvaluation: boolean;
  hidePlacement: boolean;
  userPlacementData: {
    jobsApplied: number;
    jobsInProgress: number;
    isJobOffered: boolean;
    isUserPlaced: boolean;
    didUserDecline: boolean;
    isUserDeclineInvalid: boolean;
    disqualifiedUserJobsCount: number;
    placedUserJob: any;
    placedJob: {
      meta: any;
      jobId: number;
      companyId: number;
      companyName: string;
      companyLogoUrl: string;
      positionId: string;
    }[];
  };
}

export interface SelfPlacedFormApiPayload {
  userId: string;
  formResponse: {
    questionId: string;
    response?: string;
  }[];
}

export interface ContactUsApiPayload {
  userId: string;
  title: string;
  message: string;
  jobId?: string;
}

export interface InterestDetailsFormApiPayload {
  cohortId: string;
  placementDetails: {
    projectSpecialization?: string[];
    priorRevitExp?: string;
    willingToRelocate?: boolean;
    locationPreferences?: string;
    currentCtc?: string;
    expectedCtc?: string;
    noticePeriod?: number;
    currency?: string;
    interestedInPlacements?: boolean;
    closeInterestPopup?: boolean;
  };
  location?: {
    country?: {
      code: string;
      label: string;
    };
    state?: string;
    city?: string;
  };
}
export enum BannerType {
  "PLACEMENT_FORM_NOT_AVAILABLE" = "PLACEMENT_FORM_NOT_AVAILABLE",
  "PLACEMENT_FORM_NOT_FILLED" = "PLACEMENT_FORM_NOT_FILLED",
  "PLACEMENT_FORM_FILLED" = "PLACEMENT_FORM_FILLED",
  "ELIGIBLE_FOR_PLACEMENT" = "ELIGIBLE_FOR_PLACEMENT",
  "NOT_INTERESTED_IN_PLACEMENT" = "NOT_INTERESTED_IN_PLACEMENT",
  "NOT_ELIGIBLE_FOR_PLACEMENT" = "NOT_ELIGIBLE_FOR_PLACEMENT",
  "APPLIED_AT_LEAST_ONE_JOB" = "APPLIED_AT_LEAST_ONE_JOB",
  "ELIGIBLE_BUT_FORM_NOT_FILLED" = "ELIGIBLE_BUT_FORM_NOT_FILLED",
}

export interface BannerTypeResponse {
  bannerType: BannerType;
  hidePlacement: boolean;
}

export enum WORK_TYPE {
  ON_SITE = "ON_SITE",
  REMOTE = "REMOTE",
  HYBRID = "HYBRID",
}

export enum ROUND_TYPE {
  INTRODUCTION = "INTRODUCTION",
  TECHNICAL = "TECHNICAL",
  HR = "HR",
  TOOL_TEST = "TOOL_TEST",
}

export enum HIRING_PARTNER_JOB_STATE {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export class ChangeLogs {
  state?: HIRING_PARTNER_JOB_STATE;
  isLive?: boolean;
  loggedAt: Date;
}

export type JobType = "expiredJobs" | "relevantJobs" | "nonRelevantJobs";

export interface HiringPartnerJob {
  companyId: number;
  jobId: number;
  cohortId: string;
  positionId: string;
  openings: number;
  filled: number;
  profilesShared: number;
  location: Location;
  meta: {
    position: string;
    description: string;
    experience: string;
    minExp?: number;
    maxExp?: number;
    skillsRequired: string[];
    background: string[];
    allowedCohortTypes?: CourseType[];
    minCTC: string;
    maxCTC: string;
    jobDescriptionUrl: string;
    workType: WORK_TYPE;
    numInterviewRounds: number;
    roundTypes: ROUND_TYPE[];
    projectSpecialization?: string[];
  };
  state?: HIRING_PARTNER_JOB_STATE;
  makeLive?: boolean;
  dateOfOpening: Date;
  expectedDateToClose: string;
  additionalDetails?: string;
  placementCoordinator?: string;
  changeLogs?: ChangeLogs[];
}

export type JobDataObj = {
  [key in JobType]: HiringPartnerJob[];
};
export interface CohortId {
  cohortId: string;
}

export interface UserCohortPlacementDetails {
  interestedInPlacements: boolean;
  countryPreferences: string[];
  locationPreferences: string[];
  currentCtc: string;
  expectedCtc: string;
  currency: string;
  noticePeriod: string;
  languages: string[];
  internshipExperienceInMonths: string;
  willingToRelocate: boolean;
  projectSpecialization: string[];
  priorRevitExp: string;
  closeInterestPopup?: boolean;
  closeGraduationPopup?: boolean;
  isDisengaged?: boolean;
  cohortId: string;
}
export type UserCohortPlacementPreferenceResponse = UserCohortPlacementDetails[];
