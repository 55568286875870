import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";

import Typography from "@/design-system-v3/Typography";

import { routerPage } from "@/utils/utils";

import { theme } from "@/theme";

const ModifiedBox = styled(Box)<{ isBottomContainer: boolean }>`
  background-image: url(${process.env.NEXT_PUBLIC_ASSETS}/images-v2/Feedback/Orbitsframe.png);
  background-repeat: no-repeat;
  background-size: 500px 500px;
  background-position-x: center;
  background-position-y: ${({ isBottomContainer }: any) =>
    isBottomContainer ? "-160px" : "-80px"};
`;

export const CloseButton = styled(UnstyledButton)`
  display: grid;
  place-items: center;
  position: absolute;
  right: 8px;
  width: 28px;
  z-index: 1;
  height: 28px;
  top: 8px;
  background-color: ${theme.colors.grayCool[25]};
  border-radius: 50%;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    right: 20px;
    top: 15px;
  }
`;
export default function Thankyou({
  submitContent,
  isBottomContainer = false,
  handleClose,
  mixpanelObj,
  saveForm,
  payload,
  notificationId,
  cohortId,
  isAutoSubmitTrigger,
}: any) {
  const { thankyouText, acknowledgeText, updateText } = submitContent || {};
  const router = useRouter();
  const routePageName = routerPage(router.pathname);
  const callOneTimeRef = useRef(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (
      payload &&
      mixpanelObj &&
      routePageName &&
      cohortId &&
      notificationId &&
      !callOneTimeRef.current
    ) {
      trackleadform("Form_submit", mixpanelObj);
      if (isAutoSubmitTrigger) {
        saveForm({
          page: routePageName,
          cohortId: cohortId,
          isSkipped: false,
          notificationId: notificationId,
          body: payload,
        });
      }
      callOneTimeRef.current = true;
    }
  }, [
    mixpanelObj,
    isBottomContainer,
    routePageName,
    router?.query?.cohortId,
    notificationId,
    payload,
    isAutoSubmitTrigger,
  ]);
  return (
    <ModifiedBox
      px={{ __: 0, md: isBottomContainer ? theme.spacing(10) : theme.spacing(12) }}
      py={{ __: 0, md: isBottomContainer ? theme.spacing(8) : theme.spacing(12) }}
      background={theme.colors.base.white}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={isBottomContainer ? "26px" : "32px"}
      borderRadius={theme.radius(3)}
      border={`1px solid ${theme.colors.grayCool[200]}`}
      height={isBottomContainer ? "100%" : "540px"}
      isBottomContainer={isBottomContainer}
    >
      <ExportedImage
        src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/Feedback/Thankyou.svg`}
        width={150}
        height={120}
        sizes=""
        alt="thank you"
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={theme.spacing(2)}
      >
        <Typography
          textAlign={"center"}
          color={theme.colors.purple[700]}
          varient="heading3"
          weightVarient="semibold"
        >
          {thankyouText}
        </Typography>
        <Typography
          textAlign={"center"}
          color={theme.colors.gray[600]}
          varient="bodym"
          weightVarient="regular"
        >
          {acknowledgeText}
        </Typography>
      </Box>
      <Typography varient="bodyl" weightVarient="semibold" color={theme.colors.purple[800]}>
        {updateText}
      </Typography>
      {!isBottomContainer && (
        <CloseButton onClick={handleClose}>
          <Icon type="x" varient="line" color={theme.colors.gray[400]} />
        </CloseButton>
      )}
    </ModifiedBox>
  );
}
