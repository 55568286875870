import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import React from "react";

import Box from "@/design-system/Box";

import FlexContainer from "@/design-system-v2/FlexContainer";

import Button from "@/design-system-v3/Button";
import PrimaryButton from "@/design-system-v3/Button/PrimaryButton";
import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

const LearnerHubBanner = ({ placementHubCohortId = {} }) => {
  const router = useRouter();
  return (
    <FlexContainer
      background={theme.colors.indigo[50]}
      flexDirection={{ _: "column-reverse", md: "row" }}
      borderRadius={theme.spacing(6)}
      mt={theme.spacing(4)}
      p={theme.spacing(12)}
      width={{ _: "100%", md: theme.spacing(206) }}
    >
      <FlexContainer flexDirection={"column"} gap={theme.spacing(8)}>
        <FlexContainer flexDirection={"column"} gap={theme.spacing(2)}>
          <Typography varient="heading5" weightVarient="bold" color={theme.colors.indigo[700]}>
            Welcome to Your Career Gateway!{" "}
          </Typography>
          <Typography varient="bodyl" weightVarient="semibold">
            Dive into the Placement Hub to discover, apply, and accelerate your journey towards your
            dream job.{" "}
          </Typography>
        </FlexContainer>
        <FlexContainer>
          <PrimaryButton
            size="sm"
            label={"Go to Placement Hub"}
            onClick={() => router.push(`/placement-hub?cohortId=${placementHubCohortId}`)}
          />
        </FlexContainer>
      </FlexContainer>

      <Box order={{ _: 0, md: 1 }} mb={{ _: theme.spacing(4) }} alignSelf={{ _: "center" }}>
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/placement-hub/learner-hub-banner.svg`}
          width={220}
          height={154}
          sizes=""
          alt="placement-banner"
        />
      </Box>
    </FlexContainer>
  );
};

export default LearnerHubBanner;
