import { createSlice } from "@reduxjs/toolkit";

export const placementHubSlice = createSlice({
  name: "placementhub",
  initialState: {
    trackObj: {},
  },
  reducers: {
    setTrackObj: (state, action) => {
      const { payload } = action;
      state.trackObj = payload;
    },
  },
});
