import { filters } from "@/pages/learner-hub";

import { getCardType, getCohortStartDate } from "@/components/LearningHub/Hub";

import { appBaseCreateApi } from "./BaseQueryApiClient";
import { UserCohortRequestParams, UserCohortResponse } from "./type";

export const getUserData = appBaseCreateApi({
  reducerPath: "getUserData",
  tagTypes: ["PROFILE-DATA"],
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: () => "/v1/client/user/profile",
      providesTags: ["PROFILE-DATA"],
    }),
    updateUserData: builder.mutation({
      query(userData) {
        return {
          url: `/v1/client/user/profile`,
          method: "PUT",
          body: userData,
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ["PROFILE-DATA"],
    }),
    updateUserDataV2: builder.mutation({
      query(userData) {
        return {
          url: `/v2/client/user/profile`,
          method: "PUT",
          body: userData,
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ["PROFILE-DATA"],
    }),
    updateUserProjectData: builder.mutation({
      query(userProjectData) {
        return {
          url: `/v1/client/user/project`,
          method: "PUT",
          body: userProjectData,
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: ["PROFILE-DATA"],
    }),
    uploadIgVerificationDocument: builder.mutation({
      query(data) {
        return {
          url: `/v1/client/user/identity-verification`,
          method: "PUT",
          body: data,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getUserCohortData: builder.query<UserCohortResponse, UserCohortRequestParams>({
      query: () => "v1/client/user/user-cohorts-info",
      transformResponse: (baseQueryReturnValue: UserCohortResponse, meta, arg) => {
        if (arg.source === "learner-hub") {
          return baseQueryReturnValue.map((item) => {
            return {
              ...item,
              filterId: filters.find(
                (filterItem) =>
                  filterItem.text ===
                  getCardType(
                    item?.cohortUserData?.state,
                    getCohortStartDate(item?.cohort?.timelines),
                    false
                  )
              )?.id,
            };
          });
        }

        return baseQueryReturnValue;
      },
    }),
    updateQueryCoachMarksDone: builder.mutation({
      query: (cohortId) => {
        return {
          url: `v2/client/lms/${cohortId}/query/coachmarks-viewed`,
          method: "PUT",
        };
      },
    }),
    updateQueryOnboardingDone: builder.mutation({
      query: (cohortId) => {
        return {
          url: `v2/client/lms/${cohortId}/query/onboarding-done`,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useUpdateQueryCoachMarksDoneMutation,
  useUpdateQueryOnboardingDoneMutation,
  useGetUserCohortDataQuery,
  useGetProfileDataQuery,
  useUpdateUserDataMutation,
  useUpdateUserDataV2Mutation,
  useUpdateUserProjectDataMutation,
  useUploadIgVerificationDocumentMutation,
} = getUserData;
