import Link from "next/link";
import { useEffect } from "react";
import styled from "styled-components";

import { authCheckStatus } from "@/actions/auth";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import Typography from "@/design-system-v2/Typography";

import TypographyV2 from "@/design-system-v3/Typography";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { redirectionUrl } from "@/utils/common.map";

import { theme } from "@/theme";

import { DotStyle } from "../Curriculum/CourseContent/Task/Icon/DotStyle";

import {
  NavbarImage,
  NavbarItem,
  NavbarLeftContainer,
  NavbarRightContainer,
  NavbarWrapper,
} from "./NavigationBarStyle";
import NavigationMiddle from "./NavigationMiddle";
import DropdownV2 from "./QuizDetailsDropDown/QuizDetailsDropDown";
import Dropdown from "./UserDetailsDropDown/UserDetailsDropDown";

const StyledTypography = styled(TypographyV2)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
`;
const Navbar = ({
  data,
  setGlobalSideBarToggle,
  globalSideBarToggle,
  readonly,
  setActiveStep,
  maxActiveStep,
  showTabs,
  activeStep,
  isQuizLayout,
}: any) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    !loginDetails?.isAuthenticated && dispatch(authCheckStatus());
  }, []);

  useEffect(() => {
    const handleSetIntervalAuth = () => {
      dispatch(authCheckStatus());
    };
    const timerId = setInterval(handleSetIntervalAuth, 24 * 60 * 60 * 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <NavbarWrapper>
      <NavbarLeftContainer>
        {data.toggle && (
          <div className="pointer">
            <Icon
              type="menu-03"
              varient="line"
              size={20}
              color="#667085"
              onClick={() => {
                setGlobalSideBarToggle(!globalSideBarToggle);
              }}
            />
          </div>
        )}
        <NavbarItem isQuizLayout={isQuizLayout}>
          {
            <Link href={redirectionUrl(loginDetails?.userData?.roles)}>
              <NavbarImage src={data.image} alt={data.text} />
            </Link>
          }
          {isQuizLayout ? (
            <Box
              display={"flex"}
              ml={theme.spacing(2)}
              borderLeft={`1px solid ${theme.colors.gray[200]}`}
              gap={theme.spacing(2)}
              alignItems={"center"}
            >
              <TypographyV2
                varient="bodyl"
                weightVarient="semibold"
                color={theme.colors.purple[900]}
                mr={theme.spacing(2)}
                ml={theme.spacing(8)}
              >
                {data.mainText || ""}
              </TypographyV2>
              {isMobile || !data.credits ? (
                <></>
              ) : (
                <>
                  <DotStyle size={4} color={theme.colors.gray[300]} />
                  <Icon
                    type={data?.credits ? "coins-stacked-01" : "coins-stacked-04"}
                    varient="solid"
                    color={data?.credits ? theme.colors.warning[400] : theme.colors.gray[400]}
                    size={12}
                  />
                  <TypographyV2
                    varient="bodys"
                    weightVarient="medium"
                    color={theme.colors.gray[700]}
                  >
                    {data?.credits || "No"} credits
                  </TypographyV2>
                  <DotStyle size={4} color={theme.colors.gray[300]} />
                  <StyledTypography
                    varient="bodys"
                    weightVarient="medium"
                    color={theme.colors.gray[700]}
                  >
                    {data?.title}
                  </StyledTypography>
                </>
              )}
            </Box>
          ) : (
            <Box
              display={"flex"}
              ml={theme.spacing(3)}
              borderLeft={`1px solid ${theme.colors.gray[200]}`}
            >
              <Typography
                varient="textsm"
                weightVarient="semibold"
                color={theme.colors.gray[700]}
                mr={theme.spacing(2)}
                ml={theme.spacing(8)}
              >
                {data.mainText}
              </Typography>
              {data.trailingText && (
                <>
                  <Typography
                    mr={theme.spacing(2)}
                    varient="textsm"
                    weightVarient="regular"
                    color={theme.colors.gray[400]}
                  >
                    /
                  </Typography>
                  <Typography
                    varient="textsm"
                    weightVarient="regular"
                    color={theme.colors.gray[400]}
                  >
                    {data.trailingText}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </NavbarItem>
      </NavbarLeftContainer>
      {showTabs && (
        <NavigationMiddle
          readonly={readonly}
          setActiveStep={setActiveStep}
          maxActiveStep={maxActiveStep}
          activeStep={activeStep}
        />
      )}
      <NavbarRightContainer>{!isQuizLayout && <Dropdown />}</NavbarRightContainer>
    </NavbarWrapper>
  );
};

const NavigationBar = ({
  setGlobalSideBarToggle,
  globalSideBarToggle,
  mainText,
  trailingText,
  toggle,
  readonly,
  quizData,
  setActiveStep,
  maxActiveStep,
  activeStep,
  showTabs = false,
  isQuizLayout = false,
}: any) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const data = {
    image: isMobile
      ? `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/lms/company-logo-mobile.svg`
      : `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/lms/company-logo.svg`,
    // text: text,
    mainText: quizData?.mainText,
    credits: quizData?.credits,
    title: quizData?.title,
    trailingText: trailingText,
    toggle: toggle,
  };

  return (
    <Navbar
      data={data}
      setGlobalSideBarToggle={setGlobalSideBarToggle}
      globalSideBarToggle={globalSideBarToggle}
      readonly={readonly}
      setActiveStep={setActiveStep}
      maxActiveStep={maxActiveStep}
      showTabs={showTabs}
      activeStep={activeStep}
      isQuizLayout={isQuizLayout}
    />
  );
};

export default NavigationBar;
