import { DefaultTheme } from "styled-components";

import { colors } from "./colors";

export const theme: DefaultTheme = {
  breakpoints: {
    xs: "0px",
    sm: "600px",
    md: "1080px",
    lg: "1368px",
    xl: "1520px",
    xxl: "1840px",
  },
  colors: colors,

  spacing: (factor: number) => `${0.25 * factor}rem`,
  radius: (factor: number) => `${0.25 * factor}rem`,
  shadows: {
    primary: {
      xs: "0px 6px 4px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      sm: "0px 16px 10px rgba(0, 0, 0, 0.02), 0px 7px 7px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      md: "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      lg: "0px 48px 29px rgba(0, 0, 0, 0.02), 0px 21px 21px rgba(0, 0, 0, 0.03), 0px 5px 12px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      xl: "0px 79px 48px rgba(0, 0, 0, 0.02), 0px 35px 35px rgba(0, 0, 0, 0.03), 0px 9px 19px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      xxl: "0px 111px 67px rgba(0, 0, 0, 0.02), 0px 49px 49px rgba(0, 0, 0, 0.03), 0px 12px 27px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
    },
    light: {
      xs: "0px 6px 4px rgba(165, 117, 244, 0.03), 0px 3px 3px rgba(165, 117, 244, 0.04), 0px 1px 2px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      sm: "0px 16px 10px rgba(165, 117, 244, 0.03), 0px 7px 7px rgba(165, 117, 244, 0.04), 0px 2px 4px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      md: "0px 32px 19px rgba(165, 117, 244, 0.03), 0px 14px 14px rgba(165, 117, 244, 0.04), 0px 4px 8px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      lg: "0px 48px 29px rgba(165, 117, 244, 0.03), 0px 21px 21px rgba(165, 117, 244, 0.04), 0px 5px 12px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      xl: "0px 79px 48px rgba(165, 117, 244, 0.03), 0px 35px 35px rgba(165, 117, 244, 0.04), 0px 9px 19px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      xxl: "0px 111px 67px rgba(165, 117, 244, 0.03), 0px 49px 49px rgba(165, 117, 244, 0.04), 0px 12px 27px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
    },
    dark: {
      xs: "0px 6px 4px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      sm: "0px 16px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      md: "0px 32px 19px rgba(0, 0, 0, 0.05), 0px 14px 14px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      lg: "0px 48px 29px rgba(0, 0, 0, 0.05), 0px 21px 21px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      xl: "0px 79px 48px rgba(0, 0, 0, 0.05), 0px 35px 35px rgba(0, 0, 0, 0.09), 0px 9px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      xxl: "0px 111px 67px rgba(0, 0, 0, 0.05), 0px 49px 49px rgba(0, 0, 0, 0.09), 0px 12px 27px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
    },
  },
  gradients: {
    primaryGradients45deg: {
      "1": `linear-gradient(45deg, ${colors.primary[600]} 0%, ${colors.primary[500]} 100%)`,
      "2": `linear-gradient(45deg, ${colors.primary[700]} 0%,  ${colors.primary[500]} 100%)`,
      "3": `linear-gradient(45deg, ${colors.primary[700]} 0%, ${colors.primary[600]} 100%)`,
      "4": `linear-gradient(45deg, ${colors.primary[800]} 0%, ${colors.primary[500]} 100%)`,
      "5": `linear-gradient(45deg, ${colors.primary[800]} 0%, ${colors.primary[600]} 100%)`,
      "6": `linear-gradient(45deg, ${colors.primary[900]} 0%, ${colors.primary[600]} 100%)`,
      "7": `linear-gradient(45deg, ${colors.primary[900]} 0%, ${colors.primary[700]} 100%)`,
    },
    primaryGradients90deg: {
      "1": `linear-gradient(90deg, ${colors.primary[600]} 0%, ${colors.primary[500]} 100%)`,
      "2": `linear-gradient(90deg, ${colors.primary[700]} 0%,  ${colors.primary[500]} 100%)`,
      "3": `linear-gradient(90deg, ${colors.primary[700]} 0%, ${colors.primary[600]} 100%)`,
      "4": `linear-gradient(90deg, ${colors.primary[800]} 0%, ${colors.primary[500]} 100%)`,
      "5": `linear-gradient(90deg, ${colors.primary[800]} 0%, ${colors.primary[600]} 100%)`,
      "6": `linear-gradient(90deg, ${colors.primary[900]} 0%, ${colors.primary[600]} 100%)`,
      "7": `linear-gradient(90deg, ${colors.primary[900]} 0%, ${colors.primary[700]} 100%)`,
    },
    misc: {
      "1": `linear-gradient(90deg, #7860FC 0%, #21B7DE 50%, #7FEB8C 100%)`,
      "2": `linear-gradient(45deg, #198FED 0%, #85F575 100%)`,
      "3": `linear-gradient(45deg, #54E4AD 0%, #27B6DA 50%, #198BEE 100%)`,
      "4": `linear-gradient(66deg, #ECE346 0%, #D757A4 50%, #8957FE 100%)`,
      "5": `linear-gradient(90deg, #6938EF 0%, #DA45C2 50%, #E5C360 100%)`,
      "6": `linear-gradient(45deg, #F29E62 0%, #C263B5 50%, #8A57FD 100%)`,
      "7": `linear-gradient(45deg, #9C57E9 0%, #EF5489 50.87%, #E7807C 100%)`,
      "8": `linear-gradient(270deg, #FFCF71 0.07%, #4DA4A7 51.43%, #2376DD 102.78%)`,
      "9": `linear-gradient(270deg, #2376DD 0.07%, #4DA4A7 51.43%, #FFCF71 102.78%)`,
      "10": `linear-gradient(359deg, #FFCF71 -12.39%, #4DA4A7 58.86%, #2376DD 130.11%)`,
      "11": `linear-gradient(179deg, #FFCF71 -7%, #4DA4A7 75.42%, #2376DD 157.84%)`,
      "12": `linear-gradient(45deg, #002063 0%, #8154F6 100%)`,
      "13": `linear-gradient(0deg, #E5C360 0%, #DA45C2 50%, #6938EF 100%)`,
      "14": `linear-gradient(180deg, #E5C360 0%, #DA45C2 50%, #6938EF 100%)`,
    },
    grayNeutral: {
      "1": `linear-gradient(180deg, #FFFFFF 100%, #F3F5F7 100%)`,
      "2": `linear-gradient(180deg, #F9FAFB 100%, #EDF0F3 100%)`,
      "3": `linear-gradient(180deg, #F9FAFB 100%, #E7EBEF 100%)`,
      "4": `linear-gradient(180deg, #F3F5F7 100%, #E0E5EB 100%)`,
      "5": `linear-gradient(180deg, #EDF0F3 100%, #D4DBE2 100%)`,
      "6": `linear-gradient(180deg, #E7EBEF 100%, #C8D1DA 100%)`,
      "7": `linear-gradient(180deg, #E0E5EB 100%, #B6C2CE 100%)`,
    },
    grayTrue: {
      "1": `linear-gradient(180deg, #FFFFFF 100%, #F5F5F5 100%)`,
      "2": `linear-gradient(180deg, #FAFAFA 100%, #F0F0F0 100%)`,
      "3": `linear-gradient(180deg, #FAFAFA 100%, #EBEBEB 100%)`,
      "4": `linear-gradient(180deg, #F5F5F5 100%, #E5E5E5 100%)`,
      "5": `linear-gradient(180deg, #F0F0F0 100%, #DBDBDB 100%)`,
      "6": `linear-gradient(180deg, #EBEBEB 100%, #D1D1D1 100%)`,
      "7": `linear-gradient(180deg, #E5E5E5 100%, #C2C2C2 100%)`,
    },
    colorLinear: {
      "1": `linear-gradient(180deg, #A5C0EE 0%, #FBC5EC 100%)`,
      "2": `linear-gradient(180deg, #FBC2EB 0%, #A18CD1 105.25%)`,
      "3": `linear-gradient(180deg, #FFD1FF 0%, #FAD0C4 100%)`,
      "4": `linear-gradient(225deg, #FAD0C4 0%, #FF9A9E 100%)`,
      "5": `linear-gradient(270deg, #FCB69F 0%, #FFECD2 100%)`,
      "6": `linear-gradient(180deg, #FECFEF 0%, #FF989C 100%)`,
      "7": `linear-gradient(45deg, #FF9DE4 0%, #FFEAF6 100%)`,
      "8": `linear-gradient(180deg, #E6DEE9 0%, #FDCAF1 100%)`,
      "9": `linear-gradient(45deg, #A6C0FE 0%, #FFEAF6 100%)`,
      "10": `linear-gradient(0deg, #CFC7F8 0%, #EBBBA7 100%)`,
      "11": `linear-gradient(135deg, #FCCB90 0%, #D57EEB 100%)`,
      "12": `linear-gradient(45deg, #7B6AE0 0%, #FFBB89 100%)`,
      "13": `linear-gradient(135deg, #E0C3FC 0%, #8EC5FC 100%)`,
      "14": `linear-gradient(0deg, #FED6E3 0%, #A8EDEA 100%)`,
      "15": `linear-gradient(135deg, #F5F7FA 0%, #C3CFE2 100%)`,
      "16": `linear-gradient(45deg, #DFD1C5 0%, #FFF6EB 100%)`,
      "17": `linear-gradient(135deg, #FFF6B7 0%, #FB758A 100%)`,
      "18": `linear-gradient(45deg, #FF7EC7 0%, #FFED46 100%)`,
      "19": `linear-gradient(0deg, #FEAFA8 0%, #F5EFEF 100%)`,
      "20": `linear-gradient(45deg, #E9DEFA 0%, #FFF6EB 100%)`,
      "21": `linear-gradient(0deg, #FFF1EB 0%, #ACE0F9 100%)`,
      "22": `linear-gradient(0deg, #C1DFC4 0%, #DEECDD 100%)`,
      "23": `linear-gradient(45deg, #A1C4FD 0%, #C2E9FB 100%)`,
      "24": `linear-gradient(0deg, #ACCBEE 0%, #E7F0FD 100%)`,
      "25": `linear-gradient(0deg, #84FAB0 0%, #ACCBEE 100%)`,
      "26": `linear-gradient(45deg, #39A0FF 0%, #8FFF85 100%)`,
      "27": `linear-gradient(270deg, #74EBD5 0%, #9FACE6 100%)`,
      "28": `linear-gradient(45deg, #4A879A 0%, #C5EDF5 100%)`,
      "29": `linear-gradient(0deg, #9890E3 0%, #B1F4CF 100%)`,
      "30": `linear-gradient(45deg, #7CDADA 0%, #F697AA 100%)`,
      "31": `linear-gradient(45deg, #B1FF96 0%, #FFADF7 100%)`,
      "32": `linear-gradient(0deg, #96FBC4 0%, #F9F586 100%)`,
      "33": `linear-gradient(45deg, #4DEF8E 0%, #FFEB3A 100%)`,
      "34": `linear-gradient(135deg, #F0FF00 0%, #58CFFB 100%)`,
      "35": `linear-gradient(0deg, #D1FDFF 0%, #FDDB92 100%)`,
      "36": `linear-gradient(0deg, #EBC0FD 0%, #D9DED8 100%)`,
      "37": `linear-gradient(45deg, #FFA4F6 0%, #B7DCFF 100%)`,
      "38": `linear-gradient(0deg, #CD9CF2 0%, #F6F3FF 100%)`,
      "39": `linear-gradient(315deg, #F5C8F5 0%, #DADDFA 83.85%)`,
      "40": `linear-gradient(0deg, #E6DEE9 0%, #BDC2E8 100%)`,
      "41": `linear-gradient(0deg, #6A85B6 0%, #BAC8E0 100%)`,
      "42": `linear-gradient(45deg, #8B8B8B 0%, #EAEAEA 100%)`,
      "43": `linear-gradient(135deg, #E2B0FF 0%, #9F44D3 100%)`,
      "44": `linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%)`,
      "45": `linear-gradient(135deg, #72EDF2 0%, #5151E5 100%)`,
      "46": `linear-gradient(0deg, #A3BDED 0%, #6991C7 100%)`,
      "47": `linear-gradient(0deg, #FBC8D4 0%, #9795F0 100%)`,
      "48": `linear-gradient(0deg, #A7A6CB 0%, #8989BA 100%)`,
      "49": `linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)`,
      "50": `linear-gradient(135deg, #81FFEF 0%, #F067B4 100%)`,
      "51": `linear-gradient(135deg, #DCB0ED 0%, #9999CC 100%)`,
      "52": `linear-gradient(135deg, #FFF5C3 0%, #9452A5 100%)`,
      "53": `linear-gradient(135deg, #F1CA74 0%, #A64DB6 100%)`,
      "54": `linear-gradient(45deg, #4D6AD0 0%, #FF9D7E 100%)`,
      "55": `linear-gradient(135deg, #FFCF71 0%, #2376DD 100%)`,
      "56": `linear-gradient(135deg, #E8D07A 0%, #5312D6 100%)`,
      "57": `linear-gradient(180deg, #BFD9FE 0%, #DF89B5 100%)`,
      "58": `linear-gradient(0deg, #FA71CD 0%, #C471F5 100%)`,
      "59": `linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)`,
      "60": `linear-gradient(180deg, #7579FF 0%, #B224EF 100%)`,
      "61": `linear-gradient(45deg, #AD00FE 0%, #00E0EE 100%)`,
      "62": `linear-gradient(135deg, #89F7FE 0%, #66A6FF 100%)`,
      "63": `linear-gradient(0deg, #009EFD 0%, #2AF598 100%)`,
      "64": `linear-gradient(45deg, #FFB800 0%, #FFF500 100%)`,
      "65": `linear-gradient(135deg, #FFA8A8 0%, #FCFF00 100%)`,
      "66": `linear-gradient(45deg, #FF7A00 0%, #FFD439 100%)`,
      "67": `linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)`,
      "68": `linear-gradient(180deg, #F9D423 0%, #E14FAD 100%)`,
      "69": `linear-gradient(117deg, #F74FAC 0%, #FCB24F 100%)`,
      "70": `linear-gradient(135deg, #F49062 0%, #FD371F 100%)`,
      "71": `linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)`,
      "72": `linear-gradient(135deg, #F97794 0%, #623AA2 100%)`,
      "73": `linear-gradient(180deg, #C569CF 0%, #EE609C 100%)`,
      "74": `linear-gradient(0deg, #C7EAFD 0%, #E8198B 100%)`,
      "75": `linear-gradient(135deg, #F093FB 0%, #F5576C 100%)`,
      "76": `linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)`,
      "77": `linear-gradient(135deg, #EE9AE5 0%, #5961F9 100%)`,
      "78": `linear-gradient(270deg, #6A11CB 0%, #2575FC 100%)`,
      "79": `linear-gradient(45deg, #0017E4 0%, #3793FF 100%)`,
      "80": `linear-gradient(0deg, #00C6FB 0%, #005BEA 100%)`,
      "81": `linear-gradient(45deg, #4B73FF 0%, #7CF7FF 100%)`,
      "82": `linear-gradient(135deg, #5EFCE8 0%, #736EFE 100%)`,
      "83": `linear-gradient(0deg, #7028E4 0%, #E5B2CA 100%)`,
      "84": `linear-gradient(45deg, #7873F5 0%, #EC77AB 100%)`,
      "85": `linear-gradient(135deg, #B01EFF 0%, #E1467C 100%)`,
      "86": `linear-gradient(45deg, #D079EE 0%, #8A88FB 100%)`,
      "87": `linear-gradient(135deg, #C99FFF 0%, #981ED2 100%)`,
      "88": `linear-gradient(0deg, #9B23EA 0%, #5F72BD 100%)`,
      "89": `linear-gradient(135deg, #B39FFF 0%, #6A1ED2 100%)`,
      "90": `linear-gradient(45deg, #4300B1 0%, #A531DC 100%)`,
      "91": `linear-gradient(315deg, #764BA2 0%, #667EEA 100%)`,
    },
  },
};
