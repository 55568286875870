import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useGetFeedbackFormQuery, useSaveFormResponseMutation } from "@/client/FeedbackApiClient";
import { getUserRegion, getUserLocation } from "@/client/OneistoxApiClient";

import useMediaQuery from "@/design-system/useMediaQuery";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { authSlice } from "@/reducers/auth";
import { setIsPreview } from "@/reducers/nodeSlice";
import { setIsMobileScreen } from "@/reducers/utilitySlice";

import { routerPage } from "@/utils/utils";

import FormModal from "@/components/Feedback/FormModal";

import { theme } from "@/theme";

interface WrapperComponentType {
  children: React.ReactNode;
  previousRoute: string;
}
export default function WrapperComponent({ children, previousRoute }: WrapperComponentType) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const { cohortUserDataInfo } = useAppSelector((state) => state?.rootReducer?.cohortInfo);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const renderRef = useRef(false);

  useEffect(() => {
    dispatch(setIsMobileScreen(isMobile));
  }, [isMobile, dispatch]);

  const cohortId = useMemo(() => {
    if (router.query.cohortId) {
      return router.query.cohortId;
    }
    if (cohortUserDataInfo?.length > 0) {
      return cohortUserDataInfo[cohortUserDataInfo.length - 1]?.cohortUserData.cohortId;
    }
    return "";
  }, [cohortUserDataInfo, router?.query]);

  const routePageName = routerPage(router.pathname);
  const { data } = useGetFeedbackFormQuery(
    {
      page: routePageName,
      cohortId: cohortId,
    },
    { skip: !Boolean(loginDetails?.userId) || !cohortId }
  );
  const [saveForm] = useSaveFormResponseMutation();

  useEffect(() => {
    const isPreview = router?.query?.preview === "true";
    dispatch(setIsPreview(isPreview));
  }, [router?.query?.preview]);

  useEffect(() => {
    if (
      data &&
      Object.keys(data)?.length > 0 &&
      !(data?.form?.questions.length === 1 && isMobile) &&
      !renderRef.current
    ) {
      setIsOpen(true);
    } else setIsOpen(false);
  }, [data, isMobile]);

  const handleFormSubmission = useCallback((data: any) => {
    saveForm(data);
    renderRef.current = true;
  }, []);

  useEffect(() => {
    async function handleLocation() {
      try {
        const userLocation = await getUserRegion();
        if (userLocation?.data != loginDetails.userLocation) {
          dispatch(authSlice.actions.updateLocation({ location: userLocation?.data }));
        }
        const userCountryCode = await getUserLocation();
        if (userCountryCode?.data != loginDetails.userCountryCode) {
          dispatch(
            authSlice.actions.updateUserLocationCountryCode({ location: userCountryCode?.data })
          );
        }
      } catch (error) {
        console.warn(error);
      }
    }
    handleLocation();
  }, [loginDetails]);

  return (
    <>
      <FormModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
        saveForm={handleFormSubmission}
        previousRoute={previousRoute}
        cohortId={cohortId as string}
      />
      {children}
    </>
  );
}
