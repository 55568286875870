import { createSlice } from "@reduxjs/toolkit";

import { QuizV2State } from "@/utils/types.enums";

const initialState = {
  quizState: QuizV2State.LANDINGPAGE,
  individualQuizData: null,
};

const quizSlice = createSlice({
  name: "quizSlice",
  initialState,
  reducers: {
    setQuizState(state, action) {
      state.quizState = action.payload;
    },
    setQuizData(state, action) {
      state.individualQuizData = action.payload;
    },
  },
});

export const { setQuizState, setQuizData } = quizSlice.actions;
export default quizSlice.reducer;
