import { appBaseCreateApi } from "./BaseQueryApiClient";

export const groupPerformanceApi = appBaseCreateApi({
  reducerPath: "groupPerformanceApi",
  endpoints: (builder) => ({
    getGroupPerformanceData: builder.query({
      query: ({ cohortId, nodeType, nodeId }) =>
        `v1/client/mentor/group-performance/${cohortId}?node-type=${nodeType}&nodeId=${nodeId}`,
    }),
    getLearnerPerformanceData: builder.query({
      query: ({ cohortId, userId }) =>
        `v1/client/mentor/learner-performance/${cohortId}?user-id=${userId}`,
    }),
    getMentorGroupsData: builder.query({
      query: ({ cohortId }) => `v1/client/mentor/mentor-groups/${cohortId}`,
    }),
  }),
});

export const {
  useGetGroupPerformanceDataQuery,
  useGetLearnerPerformanceDataQuery,
  useGetMentorGroupsDataQuery,
} = groupPerformanceApi;
