import { FC, memo } from "react";

import Button from "..";
import { ButtonProps, ButtonType } from "../types";

export interface PrimaryButtonType extends ButtonProps {
  buttonType?: Extract<
    ButtonType,
    "primary" | "primaryNeutral" | "primaryDark" | "primaryNeutralDark"
  >;
}

const PrimaryButton: FC<PrimaryButtonType> = ({ buttonType = "primary", ...props }) => {
  return <Button buttonType={buttonType} {...props} />;
};

export default memo(PrimaryButton);
