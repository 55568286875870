import ExportedImage from "next-image-export-optimizer";
import React, { useState } from "react";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { theme } from "@/theme";

import { EmojiStyledButton } from "../StyleComponent";
import { EmojiScalePropTypes } from "../type";

export default function EmojiScale({
  setSelectedQuestionIndex,
  questionObj,
  handleChange,
  options,
}: EmojiScalePropTypes) {
  const [rating, setRating] = useState<number>(0);
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const handleRatingChange = (index: number) => {
    handleChange(questionObj, index);
    setRating(index);
    setTimeout(() => setSelectedQuestionIndex((prevValue: number) => prevValue + 1), 500);
  };
  return (
    <FlexContainer alignItems={"center"} gap={theme.spacing(2)}>
      {options &&
        options?.map((option, index) => {
          return (
            <EmojiStyledButton
              key={option.icon}
              onClick={() => {
                handleRatingChange(index + 1);
              }}
              onMouseEnter={() => setHoverIndex(index + 1)}
              onMouseLeave={() => setHoverIndex(0)}
              isHovered={index + 1 === hoverIndex}
              active={index + 1 === rating}
              activeColor={"#FEF7C3"}
            >
              <ExportedImage
                src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/Feedback/${option.icon}.png`}
                width={30}
                height={30}
                sizes=""
                alt="YCombinator"
              />
            </EmojiStyledButton>
          );
        })}
    </FlexContainer>
  );
}
