import { useContext, useEffect } from "react";

import { trackleadform } from "@/analytics/analytics";

import { useGetUserCohortDataQuery } from "@/client/userDataClient";

import Seo from "@/components/Seo";

import { TabObject } from "@/design-system-v2/Tabs/Tabs.type";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import usePlacementHub from "@/hooks/usePlacementHub";

import { placementHubSlice } from "@/reducers/placementHub";

import { getScrollPositionByPercentage } from "@/utils/utils";

import Hub from "@/components/LearningHub/Hub";
import LearnerHubLayout from "@/components/LMSV3/LearnerHubLayout/LearnerHubLayout";

import { theme } from "@/theme";

import "react-loading-skeleton/dist/skeleton.css";
import { PreviousRouteProvider } from "../_app";

const LearnerHub = () => {
  const dispatch = useAppDispatch();
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);

  const { placementHubCohortId } = usePlacementHub();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const { isLoading, data } = useGetUserCohortDataQuery({
    source: "learner-hub",
  });

  const showReferLink = data?.length ? true : false;

  useEffect(() => {
    if (loginDetails?.userProfile?.userId && data?.length) {
      const cohorts = data?.filter((cohort) => {
        const state = cohort?.cohortUserData?.state;
        return state !== "TRANSFERRED" && state !== "CANCELLED";
      });
      const trackObjectTempData: any = {
        user_id: loginDetails.userId,
        name: loginDetails.userProfile.firstName,
        email: loginDetails.userData.emailId,
        cohortid: placementHubCohortId || cohorts[cohorts?.length - 1]?.cohortUserData.cohortId,
        Page_path: window.location.pathname,
        Current_Page: window.location.href,
        Page_Referrer: previousRoute,
        percent_scrolled: getScrollPositionByPercentage(),
        loginDetails: loginDetails.userData,
        isLoggedIn: loginDetails.isAuthenticated,
      };
      trackleadform("Page_view", trackObjectTempData);
      dispatch(placementHubSlice?.actions?.setTrackObj(trackObjectTempData));
    }
  }, [data, loginDetails, previousRoute]);

  return (
    <LearnerHubLayout
      usedInLearnerHub={true}
      placementHubCohortId={placementHubCohortId}
      sideBar={true}
      text={"Learner Hub"}
      showReferLink={showReferLink}
    >
      <Seo title="Learner | Hub" />
      <Hub
        isLoading={isLoading}
        isUserMentor={false}
        placementHubCohortId={placementHubCohortId}
        userCourseList={data}
        chipsData={chipsData}
        showReferBanner={showReferLink}
      />
    </LearnerHubLayout>
  );
};

export default LearnerHub;

const chipsData: Array<TabObject> = [
  {
    content: "Enrolled",
    tabKeyName: "enrolled",
    icon: "",
    badgeStyle: "dark",
    colorVarient: "black",
    size: "md",
    key: "1",
  },
  {
    content: "Transferred",
    tabKeyName: "transferred",
    icon: "",
    badgeStyle: "dark",
    colorVarient: "purple",
    size: "md",
    key: "2",
  },
  {
    content: "Cancelled",
    tabKeyName: "cancelled",
    icon: "",
    badgeStyle: "dark",
    colorVarient: "red",
    size: "md",
    key: "3",
  },
];

export const filters = [
  { text: "enrolled", id: "1", selectedColor: theme.colors.gray[800] },
  { text: "transferred", id: "2", selectedColor: theme.colors.purple[500] },
  { text: "cancelled", id: "3", selectedColor: theme.colors.error[500] },
];
