import { useCallback, useEffect, useRef } from "react";

/**
 * Custom React hook to track screen time on a web page.
 *
 * @param {number} - Optional initial time to start tracking from. Defaults to the current time.
 * @returns {{ getFormattedScreenTime: () => string, getScreenTime: () => number }}
 *   - getFormattedScreenTime: Returns the screen time formatted as HH:MM:SS.
 *   - getScreenTime: Returns the raw screen time in milliseconds.
 */
export const useScreenTime = (time = new Date().getTime()) => {
  const internalInitTime = useRef(time);

  const lastScreenDuration = useRef(0);

  const getScreenTime = useCallback(() => {
    return lastScreenDuration.current + (new Date().getTime() - internalInitTime.current);
  }, []);

  const getFormattedScreenTime = useCallback(() => {
    return getFormattedTime(getScreenTime());
  }, [getScreenTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        lastScreenDuration.current = getScreenTime();
      } else {
        internalInitTime.current = new Date().getTime();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [getScreenTime]);

  return { getFormattedScreenTime, getScreenTime };
};

export const getFormattedTime = (time: number) => {
  const minutes = Math.floor(time / 60000);
  const hrs = Math.floor(time / 3600000);
  const seconds = ((time % 60000) / 1000).toFixed(0);
  return `${Number(hrs) < 10 ? "0" : ""}${hrs}:${
    Number(minutes) < 10 ? "0" : ""
  }${minutes}:${Number(seconds) < 10 ? "0" : ""}${seconds}`;
};
