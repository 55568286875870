import Cookies from "js-cookie";
import Router from "next/router";

import { resetUser, setUser, trackauth, tracklogin, tracksignup } from "@/analytics/analytics";

import { placementHubClient } from "@/client/placementHubClient";

import { redirectUrlAfterLogin } from "@/utils/common.map";

import {
  emailLogin,
  emailSignUp,
  getUserDataWithUserProfile,
  sendEmailVerification,
  socialLogin,
  socialSignUp,
} from "../client/OneistoxApiClient";
import { authSlice } from "../reducers/auth";

// logout a user
export const logout = (redirectPath?: string) => (dispatch: any) => {
  resetUser();
  dispatch(placementHubClient.util.resetApiState());
  dispatch(
    authSlice.actions.logout({
      redirect: true,
      redirectPath,
    })
  );
};

// signup a user
export const signupStart = (data: any) => (dispatch: any) => {
  // tracksignupStart(data);
  dispatch(authSlice.actions.signUpStart());
};

// login failed
export const signupFail = () => async (dispatch: any) => {
  resetUser();
  dispatch(authSlice.actions.signUpFail(null));
};

// clear erros
export const clearErrors = () => async (dispatch: any) => {
  dispatch(authSlice.actions.clearErrors());
};

// signup
export const signup = (data: any) => async (dispatch: any) => {
  try {
    const res = await emailSignUp(data.email, data.password, data.firstName, data.lastName);

    dispatch(authSlice.actions.signUpSuccess(res?.data));

    const resUDUP = (await getUserDataWithUserProfile())?.data;
    const userDataRes = resUDUP?.userData || {};
    const userProfileRes = resUDUP?.userProfile || {};
    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      })
    );

    // tracksignup(
    //   userDataRes.userId,
    //   {
    //     name: userProfileRes.firstName,
    //     email: userDataRes.emailId,
    //     mobile: userDataRes.phone,
    //   },
    //   "Sign Up Completed",
    //   {
    //     user_id: userDataRes.userId,
    //     first_Name: userProfileRes.firstName,
    //     last_Name: userProfileRes.lastName,
    //     email: userDataRes.emailId,
    //     percent_scrolled: data.scrollPercentage,
    //     on_screen_duration: data.sessionTime,
    //     Page_Referrer: data.pageReferrer,
    //     Current_Page: data.currentPage,
    //     Page_path: data.pagePath,
    //     method: "email",
    //     isLoggedIn: true,
    //   }
    // );

    Router.replace(`/verify-mail`);
    sendEmailVerification();
    setUser(userDataRes.userId);
  } catch (err: any) {
    resetUser();
    dispatch(authSlice.actions.signUpFail(err.response?.data.message));
  }
};

// social login a user
export const socialSignUpAction = (data: any, type: any) => async (dispatch: any) => {
  try {
    const res = await socialSignUp(type, data.credential);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      })
    );

    // tracksignup(
    //   userDataRes.userId,
    //   {
    //     name: userProfileRes.firstName,
    //     email: userDataRes.emailId,
    //     mobile: userDataRes.phone,
    //   },
    //   "Sign Up Completed",
    //   {
    //     name: userProfileRes.firstName,
    //     email: userDataRes.emailId,
    //     mobile: userDataRes.phone,
    //     percent_scrolled: data.scrollPercentage,
    //     on_screen_duration: data.sessionTime,
    //     Page_Referrer: data.pageReferrer,
    //     Current_Page: data.currentPage,
    //     Page_path: data.pagePath,
    //     method: "Google",
    //     isLoggedIn: true,
    //   }
    // );
    setUser(userDataRes.userId);
  } catch (err: any) {
    resetUser();
    dispatch(authSlice.actions.signUpFail(err.response.data.message));
  }
};

// social login a user
export const social = (data: any, type: any) => async (dispatch: any) => {
  try {
    const res = await socialLogin(type, data.credential);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      })
    );

    // tracklogin(
    //   userDataRes.userId,
    //   {
    //     name: userProfileRes.firstName,
    //     email: userDataRes.emailId,
    //     mobile: userDataRes.phone,
    //   },
    //   "Login Completed",
    //   {
    //     user_id: userDataRes.userId,
    //     "First Name": userProfileRes.firstName,
    //     "Last Name": userProfileRes.lastName,
    //     Email: userDataRes.emailId,
    //     "Login Method": "GOOGLE",
    //     Page_Referrer: data.pageReferrer,
    //     percent_scrolled: data.scrollPercentage,
    //     on_screen_duration: data.screenDuration,
    //     Current_Page: data.currentPage,
    //     Page_path: data.pagePath,
    //     isLoggedin: true,
    //   }
    // );
    setUser(userDataRes.userId);

    redirectUrlAfterLogin(userDataRes, userProfileRes);
  } catch (err: any) {
    resetUser();
    dispatch(authSlice.actions.loginFailed(err?.response?.data?.message));
  }
};

// login a user start
export const loginStart = () => (dispatch: any) => {
  dispatch(authSlice.actions.loginStart());
};

// login failed
export const loginFail = () => async (dispatch: any) => {
  resetUser();
  dispatch(authSlice.actions.logout(null));
};

// login a user
export const login = (data: any) => async (dispatch: any) => {
  try {
    const res = await emailLogin(data.email, data.password);

    dispatch(authSlice.actions.loginSuccess(res.data));

    const resUDUP = (await getUserDataWithUserProfile()).data;
    const userDataRes = resUDUP.userData;
    const userProfileRes = resUDUP.userProfile;

    dispatch(
      authSlice.actions.authenticate({
        userData: userDataRes,
        userProfile: userProfileRes,
      })
    );

    // tracklogin(
    //   userDataRes.userId,
    //   {
    //     name: userProfileRes.firstName,
    //     email: userDataRes.emailId,
    //     mobile: userDataRes.phone,
    //   },
    //   "Login Completed",
    //   {
    //     user_id: userDataRes.userId,
    //     "First Name": userProfileRes.firstName,
    //     "Last Name": userProfileRes.lastName,
    //     Email: userDataRes.emailId,
    //     "Login Method": "EMAIL",
    //     Page_Referrer: data.pageReferrer,
    //     percent_scrolled: data.scrollPercentage,
    //     on_screen_duration: data.screenDuration,
    //     Current_Page: data.currentPage,
    //     Page_path: data.pagePath,
    //     isLoggedin: true,
    //   }
    // );
    setUser(userDataRes.userId);
    redirectUrlAfterLogin(userDataRes, userProfileRes);
  } catch (err: any) {
    resetUser();
    dispatch(authSlice.actions.loginFailed(err?.response?.data?.message));
  }
};

// auth initialization
export const authCheckStatus =
  (doNotTrack = false) =>
    async (dispatch: any) => {
      const token = Cookies.get("token");
      if (!token) {
      // dispatch(
      //   authSlice.actions.logout({
      //     redirect: true,
      //     redirectPath: "/login?callbackUrl=" + Router.asPath,
      //   })
      // );
      } else {
        try {
          const resUDUP = (await getUserDataWithUserProfile()).data;
          const userDataRes = resUDUP.userData;
          const userProfileRes = resUDUP.userProfile;

          dispatch(
            authSlice.actions.authenticate({
              userData: userDataRes,
              userProfile: userProfileRes,
            })
          );

          // if (!doNotTrack) {
          //   trackauth(
          //     userDataRes.userId,
          //     {
          //       name: userProfileRes.firstName,
          //       email: userDataRes.emailId,
          //       mobile: userDataRes.phone,
          //     },
          //     "Auth",
          //     {
          //       name: userProfileRes.firstName,
          //       email: userDataRes.emailId,
          //       mobile: userDataRes.phone,
          //       company: "",
          //       company_address: "",
          //       education_status: "",
          //       method: { name: "SITE" },
          //       type: "revalidated",
          //       integrations: {
          //         "Google Analytics": false,
          //       },
          //     }
          //   );
          // }
          setUser(userDataRes.userId);
        // Router.push("/dashboard/courses");
        } catch (err) {
          resetUser();
          dispatch(authSlice.actions.logout({ redirect: true }));
        }
      }
    };
