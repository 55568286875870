import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getLearnerQuery } from "@/client/lmsLearnerQuery";

import { InternalQueryStateFilter } from "@/utils/types.enums";

import { QueryWithNamespace } from "@/client/types/queryApi.type";

type SortOrder = "ASC" | "DESC";
interface QueryInitialState {
  isUserMentor: boolean;
  queryState: InternalQueryStateFilter;
  list: QueryWithNamespace[];
  data: QueryWithNamespace[];
  scrollDirection: "up" | "down";
  sorting: SortOrder;
  scrolling: boolean;
}

const initialState: QueryInitialState = {
  queryState: InternalQueryStateFilter.ALL,
  list: [],
  data: [],
  isUserMentor: false,
  scrollDirection: "up",
  sorting: "DESC",
  scrolling: false,
};

const transformList = (state: QueryInitialState): QueryWithNamespace[] => {
  if (state.queryState === InternalQueryStateFilter.RESOLVED) {
    state.list = state.data.filter((i) => i.state === state.queryState);
  } else if (state.queryState === InternalQueryStateFilter.OPEN) {
    state.list = state.data.filter((i) => i.state !== InternalQueryStateFilter.RESOLVED);
  } else {
    state.list = state.data;
  }

  state.list.sort((a, b) => {
    if (state.sorting === "ASC") {
      return new Date(a.queryCreatedAt).getTime() - new Date(b.queryCreatedAt).getTime();
    }
    return new Date(b.queryCreatedAt).getTime() - new Date(a.queryCreatedAt).getTime();
  });

  return state.list;
};

const querySlice = createSlice({
  name: "querySlice",
  initialState,
  reducers: {
    changeSorting: (state, action: PayloadAction<SortOrder>) => {
      state.sorting = action.payload;

      state.list = transformList(state);
    },
    changeScrollPosition: (state, action: PayloadAction<"up" | "down">) => {
      state.scrollDirection = action.payload;
    },
    setScrolling: (state, action: PayloadAction<boolean>) => {
      state.scrolling = action.payload;
    },
    changeQueryState(
      state,
      action: PayloadAction<{ isUserMentor: boolean; filter: InternalQueryStateFilter }>
    ) {
      const { isUserMentor, filter } = action.payload;
      state.queryState = filter;
      state.isUserMentor = isUserMentor;

      state.list = transformList(state);
    },
    resetState(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getLearnerQuery.endpoints.getQueryList.matchFulfilled, (state, action) => {
      state.data = action.payload;

      state.list = transformList(state);
    });
  },
});

export const { changeQueryState, resetState, changeScrollPosition, changeSorting, setScrolling } =
  querySlice.actions;
export default querySlice.reducer;
