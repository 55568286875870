import Router, { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import { useCallback, useEffect, useRef, useState } from "react";

import { logout } from "@/actions/auth";

import { trackleadform } from "@/analytics/analytics";

import { useGetUserCohortDataQuery } from "@/client/userDataClient";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";

import Typography from "@/design-system-v2/Typography";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { usePreviousRoute } from "@/hooks/usePrevious";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { theme } from "@/theme";

import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  DropdownWrapper,
  ImageNameWrapper,
} from "./UserDetailsDropDownStyle";

const Dropdown = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { pathname } = useRouter();
  const previousRoute = usePreviousRoute();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);

  const { data } = useGetUserCohortDataQuery({
    source: "learner-hub",
  });

  const showRefer = data?.length ? true : false;

  const handleDropdownToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const isUserIg = loginDetails?.userData?.roles?.includes("INDUSTRIAL_GUIDE");
  const isUserMentor = loginDetails?.userData?.roles?.includes("LEAD_MENTOR");

  const profilePicObj = loginDetails?.userProfile?.profileMedia?.find(
    (item) => item.key === "WEBSITE_PROFILE"
  );
  let picUrl;
  if (profilePicObj) {
    picUrl = `https://${profilePicObj.s3Location?.bucket}.s3.ap-south-1.amazonaws.com/${profilePicObj?.s3Location?.path}`;
  }

  const trackObjectTempData = {
    user_id: loginDetails.userId,
    name: loginDetails.userProfile.firstName,
    email: loginDetails.userData.emailId,
    Page_path: pathname,
    Page_Referrer: previousRoute || "",
    percent_scrolled: getScrollPositionByPercentage(),
    loginDetails: loginDetails.userData,
    isLoggedIn: loginDetails.isAuthenticated,
  };

  const handleClick = useCallback(() => {
    Router.push("/referral-program");
    const linkClickTrackObj = {
      ...trackObjectTempData,
      link_text: "refer and earn",
    };
    trackleadform("Link_Click", linkClickTrackObj);
    handleDropdownToggle();
  }, [trackObjectTempData, trackleadform, handleDropdownToggle]);

  return (
    <DropdownWrapper ref={ref}>
      <DropdownButton id="dropdownButton-maskId" onClick={handleDropdownToggle}>
        <ImageNameWrapper>
          {picUrl ? (
            <Box border={"2px solid lightgray"} borderRadius={"50%"}>
              <ExportedImage
                src={picUrl}
                alt="profile-pic"
                height={24}
                width={24}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : (
            <ExportedImage
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/LMSV3/profileDummy.png`}
              style={{ borderRadius: "100%" }}
              alt="Mentor"
              height={24}
              width={24}
            />
          )}

          <span id="ta_loggedin_user_firstname">{loginDetails?.userProfile?.firstName}</span>
          <img src="/icons/line/chevron-down.svg" alt="Chevron Down" />
          {/* <span id="ta_loggedin_user_firstname">
            Help
          </span>
          <img src="/icons/line/chevron-down.svg" alt="Chevron Down" /> */}
        </ImageNameWrapper>
      </DropdownButton>
      <DropdownMenu open={open}>
        <Box>
          <Typography
            varient="textmd"
            color={theme.colors.grayCool[500]}
            px={theme.spacing(4)}
            pt={theme.spacing(3)}
            pb={theme.spacing(2)}
          >
            Account Menu
          </Typography>
        </Box>

        <DropdownMenuItem
          addHorizontalLine={true}
          isActive={pathname.includes("/learner-hub")}
          onClick={() => {
            const linkClickTrackObj = {
              ...trackObjectTempData,
              link_text: "learner-hub",
            };
            trackleadform("Link_Click", linkClickTrackObj);
            Router.push("/learner-hub");
            handleDropdownToggle();
          }}
        >
          <Icon type={"atom-02"} varient="line" size={16} color={theme.colors.grayCool[800]} />
          <Typography varient="displayxs" weightVarient="medium" color={theme.colors.grayCool[800]}>
            Learner Hub
          </Typography>
        </DropdownMenuItem>

        {isUserIg && (
          <>
            <Box
              mx={theme.spacing(4)}
              my={theme.spacing(2)}
              bg={theme.colors.gray[300]}
              height={"1px"}
            />
            <DropdownMenuItem
              addHorizontalLine={true}
              isActive={pathname.includes("/ig-hub")}
              onClick={() => {
                Router.push("/ig-hub");
                const linkClickTrackObj = {
                  ...trackObjectTempData,
                  link_text: "ig-hub",
                };
                trackleadform("Link_Click", linkClickTrackObj);
                handleDropdownToggle();
              }}
            >
              <Icon
                type={"rocket-02"}
                varient="line"
                size={16}
                color={theme.colors.grayCool[800]}
              />
              <Typography
                varient="displayxs"
                weightVarient="medium"
                color={theme.colors.grayCool[800]}
              >
                IG Hub
              </Typography>
            </DropdownMenuItem>

            <DropdownMenuItem
              addHorizontalLine={true}
              isActive={pathname.includes("/mentor/earnings")}
              onClick={() => {
                Router.push("/mentor/earnings");
                const linkClickTrackObj = {
                  ...trackObjectTempData,
                  link_text: "IG Earnings",
                };
                trackleadform("Link_Click", linkClickTrackObj);
                handleDropdownToggle();
              }}
            >
              <Icon
                type={"wallet-02"}
                varient="line"
                size={16}
                color={theme.colors.grayCool[800]}
              />
              <Typography
                varient="displayxs"
                weightVarient="medium"
                color={theme.colors.grayCool[800]}
              >
                IG Earnings
              </Typography>
            </DropdownMenuItem>
          </>
        )}

        {isUserMentor && (
          <>
            <Box
              mx={theme.spacing(4)}
              my={theme.spacing(2)}
              bg={theme.colors.gray[300]}
              height={"1px"}
            />
            <DropdownMenuItem
              addHorizontalLine={true}
              isActive={pathname.includes("/mentor-hub")}
              onClick={() => {
                Router.push("/mentor-hub");
                const linkClickTrackObj = {
                  ...trackObjectTempData,
                  link_text: "mentor-hub",
                };
                trackleadform("Link_Click", linkClickTrackObj);
                handleDropdownToggle();
              }}
            >
              <Icon type={"stand"} varient="line" size={16} color={theme.colors.grayCool[800]} />
              <Typography
                varient="displayxs"
                weightVarient="medium"
                color={theme.colors.grayCool[800]}
              >
                Mentor Hub
              </Typography>
            </DropdownMenuItem>

            <DropdownMenuItem
              addHorizontalLine={true}
              isActive={pathname.includes("/lm/impact")}
              onClick={() => {
                Router.push("/lm/impact");
                const linkClickTrackObj = {
                  ...trackObjectTempData,
                  link_text: "My Impact",
                };
                trackleadform("Link_Click", linkClickTrackObj);
                handleDropdownToggle();
              }}
            >
              <Icon
                type={"bar-chart-01"}
                varient="line"
                size={16}
                color={theme.colors.grayCool[800]}
              />
              <Typography
                varient="displayxs"
                weightVarient="medium"
                color={theme.colors.grayCool[800]}
              >
                My Impact
              </Typography>
            </DropdownMenuItem>

            <DropdownMenuItem
              addHorizontalLine={true}
              isActive={pathname.includes("/lm/earnings")}
              onClick={() => {
                Router.push("/lm/earnings");
                const linkClickTrackObj = {
                  ...trackObjectTempData,
                  link_text: "Mentor Earnings",
                };
                trackleadform("Link_Click", linkClickTrackObj);
                handleDropdownToggle();
              }}
            >
              <Icon
                type={"wallet-02"}
                varient="line"
                size={16}
                color={theme.colors.grayCool[800]}
              />
              <Typography
                varient="displayxs"
                weightVarient="medium"
                color={theme.colors.grayCool[800]}
              >
                Mentor Earnings
              </Typography>
            </DropdownMenuItem>
          </>
        )}

        <Box
          mx={theme.spacing(4)}
          my={theme.spacing(2)}
          bg={theme.colors.gray[300]}
          height={"1px"}
        />

        <DropdownMenuItem
          isActive={pathname.includes("user-profile")}
          addHorizontalLine={true}
          onClick={() => {
            Router.push(`/user-profile?userId=${loginDetails.userId}`);
            const linkClickTrackObj = {
              ...trackObjectTempData,
              link_text: "edit-profile",
            };
            trackleadform("Link_Click", linkClickTrackObj);
            handleDropdownToggle();
          }}
        >
          <Icon type={"user-03"} varient="line" size={16} color={theme.colors.grayCool[800]} />

          <Typography varient="displayxs" weightVarient="medium" color={theme.colors.grayCool[800]}>
            Profile
          </Typography>
        </DropdownMenuItem>

        <DropdownMenuItem
          isActive={pathname.includes("billing")}
          addHorizontalLine={true}
          onClick={() => {
            Router.push("/billing");
            const linkClickTrackObj = {
              ...trackObjectTempData,
              link_text: "billing",
            };
            trackleadform("Link_Click", linkClickTrackObj);
            handleDropdownToggle();
          }}
        >
          <Icon
            type={"shopping-bag-03"}
            varient="line"
            size={16}
            color={theme.colors.grayCool[800]}
          />
          <Typography varient="displayxs" weightVarient="medium" color={theme.colors.grayCool[800]}>
            My Orders
          </Typography>
        </DropdownMenuItem>

        {showRefer && (
          <DropdownMenuItem
            isActive={pathname.includes("referral-program")}
            addHorizontalLine={true}
            onClick={handleClick}
          >
            <ExportedImage
              src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/dropdown/refer-icon.svg`}
              width={16}
              height={16}
              sizes=""
              alt="star"
            />
            <Typography
              varient="displayxs"
              weightVarient="medium"
              color={theme.colors.grayCool[800]}
            >
              Referral Program
            </Typography>
          </DropdownMenuItem>
        )}

        <DropdownMenuItem
          addHorizontalLine={true}
          onClick={() => {
            const linkClickTrackObj = {
              ...trackObjectTempData,
              link_text: "logout",
            };
            trackleadform("Link_Click", linkClickTrackObj);
            dispatch(logout());
          }}
        >
          <Icon type={"log-out-01"} varient="line" size={16} color={theme.colors.error[500]} />

          <Typography varient="displayxs" weightVarient="medium" color={theme.colors.error[500]}>
            Log Out
          </Typography>
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default Dropdown;
