import styled from "styled-components";
import { display, flexbox, grid, space } from "styled-system";

import { GridContainerProps } from "./types";

export const StyledGridContainer = styled.div<GridContainerProps>`
  ${space};
  ${grid};
  ${flexbox}
  ${display}
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 12}, 1fr);
  grid-column-gap: ${({ spacing }) => spacing || "1rem"};
  grid-row-gap: ${({ spacing }) => spacing || "1rem"};

  /* ${({ alignItems }) => alignItems && { alignItems: "center" }} */
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column-gap: ${({ spacing }) => spacing || "1rem"};
    grid-template-columns: repeat(${({ columns }) => columns || 12}, 1fr);
  }
`;
