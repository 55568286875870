import { FC } from "react";

import { StyledButton } from "./button.styles";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({ color, loading, size, icon, suffixIcon, label, ...rest }) => (
  <StyledButton color={color} size={size} {...rest}>
    {icon && <div className="icon">{icon}</div>}
    {label}
    {loading && <span className="loader suffix" />}
    {!loading && suffixIcon && <div className="suffix">{suffixIcon}</div>}
  </StyledButton>
);

export default Button;

Button.defaultProps = {
  color: "primary",
  size: "md",
  icon: "",
  suffixIcon: "",
  label: "",
};
