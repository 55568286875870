import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Router from "next/router";

import { getUserData } from "@/client/userDataClient";

type P = {
  onboardingPopUpViewed: boolean;
  coachMarksViewed: boolean;
};

const initialState: P = {
  onboardingPopUpViewed: true,
  coachMarksViewed: true,
};

export const flagTrackerSlice = createSlice({
  name: "flagTracker",
  initialState,
  reducers: {
    updateOnboardingPopUpViewed: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.onboardingPopUpViewed = payload;
    },
    updateCoachMarksViewed: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.coachMarksViewed = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getUserData.endpoints.getUserCohortData.matchFulfilled, (state, action) => {
      const coUserData = action.payload.find(
        (ele) => ele.cohortUserData.cohortId === Router?.query?.cohortId
      );
      if (coUserData) {
        state.onboardingPopUpViewed =
          !!coUserData?.cohortUserData?.queriesOnboardingDetails?.onboardingDone;
        state.coachMarksViewed =
          !!coUserData?.cohortUserData?.queriesOnboardingDetails?.coachMarksViewed;
      }
    });
  },
});
