import React from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";

import Typography from "@/design-system-v2/Typography";

import { theme } from "@/theme";

const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.grayCool[200]};
  border-radius: 8px;
  padding: 10px 16px;
  width: 100%;
  height: 36px;
  background-color: ${theme.colors.base.white};
`;

const InputLabel = styled(Box)`
  font-size: 10px;
  line-height: 12px;
  font-size: 10px;
  line-height: 12px;
  z-index: 2;
  position: absolute;
  left: 8px;
  top: 0px;
  transform: translateY(-50%);
  background: ${theme.colors.base.white};
`;

const InputIcon = styled.span`
  margin-right: 8px;
`;

const InputElement = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray[900]};

  &::placeholder {
    color: ${theme.colors.gray[400]};
  }
  &:disabled {
    background: transparent;
  }
`;

const VerticalLine = styled(Box)`
  height: 24px;
  width: 1px;
  background-color: #ccc;
  margin-right: 8px;
`;

const ErrorText = styled.div`
  font-size: 10px;
  color: red;
  margin-top: 4px;
`;

interface InputFieldProps {
  leftIcon?: JSX.Element;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  verticalLine?: boolean;
  rightIcon?: JSX.Element;
  style?: React.CSSProperties;
  name?: string;
  value?: string | number;
  error?: string;
  label?: string;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: () => void;
}

const InputField: React.FC<InputFieldProps> = ({
  leftIcon,
  verticalLine,
  rightIcon,
  label,
  onBlur,
  error,
  ...restProps
}) => {
  // const [error, setError] = useState<string | null>(null);

  const handleBlur = () => {
    //   const value = event.target.value;

    //   // Perform your validation logic here
    //   // if (restProps.name === "email" && !value.includes("@")) {
    //   //   setError("Invalid email format");
    //   // } else if (restProps.name === "password" && value.length < 6) {
    //   //   setError("Password must be at least 6 characters");
    //   // } else {
    //   //   setError(null);
    //   // }

    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <InputContainer>
        {label && (
          <>
            <InputLabel
              px={theme.spacing(1)}
              color={theme.colors.gray[500]}
              display="flex"
              gap={theme.spacing(1)}
            >
              {label}{" "}
              {restProps.required ? (
                <Typography color={theme.colors.error[500]}>*</Typography>
              ) : null}
            </InputLabel>
          </>
        )}
        {leftIcon && <InputIcon>{leftIcon}</InputIcon>}
        {verticalLine && <VerticalLine />}
        <InputElement {...restProps} onBlur={handleBlur} />
        {rightIcon && <InputIcon>{rightIcon}</InputIcon>}
        {error && <ErrorText>{error}</ErrorText>}
      </InputContainer>
    </>
  );
};

export default InputField;
