import { FC } from "react";

import { StyledGridContainer } from "./gridcontainer.styles";
import { GridContainerProps } from "./types";

const GridContainer: FC<GridContainerProps> = ({
  children,
  className,
  spacing,
  columns,
  ...rest
}) => {
  return (
    <StyledGridContainer {...rest} spacing={spacing} columns={columns} className={className}>
      {children}
    </StyledGridContainer>
  );
};
export default GridContainer;
