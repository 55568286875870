import React, { useMemo, useState } from "react";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";
import useMediaQuery from "@/design-system/useMediaQuery";

import Typography from "@/design-system-v3/Typography";

import { DotStyle } from "@/components/Layout/Nav/MegaMenu/Resources/DotStyle";

import { theme } from "@/theme";

import { getDivideObj } from "../constant";
import { ScaleBox } from "../StyleComponent";
import { OpinionScalePropTypes } from "../type";

export default function OpinionScale({
  min,
  max,
  bottomValueText,
  topValueText,
  setSelectedQuestionIndex,
  handleChange,
  questionObj,
  options,
}: OpinionScalePropTypes) {
  const [rating, setRating] = useState(-1);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const handleScaleClick = (clickedIndex: number) => {
    handleChange(questionObj, clickedIndex);
    setTimeout(() => setSelectedQuestionIndex((prevValue: number) => prevValue + 1), 500);
  };
  const divideLengthObj = useMemo(() => {
    return getDivideObj(options, isMobile);
  }, [options, isMobile]);

  const bottomText = useMemo(() => {
    if (!isMobile || options.length < 10) {
      return {
        startText: options[0].text || bottomValueText,
        endText: options[options.length - 1].text || topValueText,
      };
    }
    return {
      startText: `${options[0].optionId} - ${options[0].text}`,
      endText: `${options[options.length - 1].optionId}  - ${options[options.length - 1].text}`,
    };
  }, [options, isMobile]);

  return (
    <Box
      borderRadius={theme.radius(3)}
      background={theme.colors.base.white}
      display={"flex"}
      flexDirection={"column"}
      gap={theme.spacing(2)}
    >
      <GridContainer columns={divideLengthObj.columnLength} spacing={"0px"}>
        {options?.map((option, index: number) => {
          return (
            <React.Fragment key={index}>
              <GridColumn
                xs={`span ${divideLengthObj.columnLength / divideLengthObj.divideLength}`}
                md={`span ${divideLengthObj.columnLength / divideLengthObj.divideLength}`}
              >
                <UnstyledButton
                  key={`opinion-${index}`}
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => handleScaleClick(option.optionId)}
                  marginLeft={
                    options.length > 10 && index > Math.floor(options.length / 2) && isMobile
                      ? "calc(50% + 4px)"
                      : 0
                  }
                >
                  <ScaleBox
                    isMobile={isMobile}
                    onClick={() => setRating(index)}
                    active={rating === index}
                    index={index}
                    length={max - min}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {option.optionId}
                  </ScaleBox>
                </UnstyledButton>
              </GridColumn>
            </React.Fragment>
          );
        })}
      </GridContainer>
      <Box
        display={"flex"}
        justifyContent={!isMobile || options.length < 10 ? "space-between" : "center"}
        alignItems={"center"}
        gap={theme.spacing(4)}
      >
        <Typography varient="bodyxs" weightVarient="semibold" color={theme.colors.gray[600]}>
          {bottomText.startText}
        </Typography>
        {isMobile && options.length >= 10 && <DotStyle color={theme.colors.grayCool[200]} />}
        <Typography varient="bodyxs" weightVarient="semibold" color={theme.colors.gray[600]}>
          {bottomText.endText}
        </Typography>
      </Box>
    </Box>
  );
}
