import React, { useState } from "react";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import GridColumn from "@/design-system/GridColumn";
import GridContainer from "@/design-system/GridContainer";

import { theme } from "@/theme";

import OptionComponent from "./OptionComponent";
import { MultipleChoicePropsTypes, OptionType, choiceEnum } from "./type";

export default function MultipleChoice({
  options,
  handleChange,
  choiceType,
  setSelectedQuestionIndex,
}: MultipleChoicePropsTypes) {
  const [configOptions, setConfigOptions] = useState<OptionType[]>(options);
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const handleClickChange = (index: number) => {
    const selectedIndex = index;
    const newConfigOption = JSON.parse(JSON.stringify(configOptions));
    if (choiceType === choiceEnum.SINGLE_CHOICE) {
      newConfigOption.forEach((element: OptionType, index: number) => {
        if (selectedIndex === index) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    } else {
      const selectedActiveStatus = newConfigOption[selectedIndex]?.isActive;
      newConfigOption[selectedIndex].isActive = !selectedActiveStatus;
    }
    setConfigOptions(newConfigOption);
    const selectedOptions = newConfigOption.filter((element) => element.isActive);
    handleChange(selectedOptions);
  };

  return (
    <Box px={{ __: 0, md: theme.spacing(2) }}>
      <GridContainer columns={12} spacing={"16px"}>
        {configOptions.map((configOption, index: number) => {
          return (
            <React.Fragment key={`option-${index}`}>
              <GridColumn xs={"span 12"} md={"span 6"}>
                <UnstyledButton
                  onClick={() => {
                    handleClickChange(index);
                    if (choiceType === choiceEnum.SINGLE_CHOICE) {
                      setTimeout(
                        () => setSelectedQuestionIndex((prevValue: number) => prevValue + 1),
                        1000
                      );
                    }
                  }}
                  onMouseEnter={() => setHoverIndex(index + 1)}
                  onMouseLeave={() => setHoverIndex(0)}
                >
                  <OptionComponent
                    option={configOption}
                    isActive={configOption.isActive}
                    isHover={index + 1 === hoverIndex}
                    index={index}
                  />
                </UnstyledButton>
              </GridColumn>
            </React.Fragment>
          );
        })}
      </GridContainer>
    </Box>
  );
}
