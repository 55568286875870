import { appBaseCreateApi } from "./BaseQueryApiClient";

export const myApplicationClient = appBaseCreateApi({
  reducerPath: "myApplication",
  tagTypes: ["ACCEPT-OFFER", "REQUEXT-EXTENTION", "FORM_SUBMISSION"],
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: (emailId) => `/v1/client/my-applications/applications?emailId=${emailId}`,
    }),
    getApplicationByDealId: builder.query({
      query: (dealId) => `/v1/client/my-applications/application?dealId=${dealId}`,
      providesTags: ["ACCEPT-OFFER", "REQUEXT-EXTENTION", "FORM_SUBMISSION"],
    }),
    getPaymentData: builder.query({
      query: (dealId) => `/v1/client/my-applications/payment-token?dealId=${dealId}`,
      providesTags: ["ACCEPT-OFFER"],
    }),
    submitApplication: builder.mutation({
      query: (payload) => {
        return {
          url: `/v1/client/my-applications/submit-application?dealId=${payload.dealId}`,
          method: "POST",
          body: payload.data,
        };
      },
      invalidatesTags: ["FORM_SUBMISSION"],
    }),
    acceptOffer: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/client/my-applications/offer-accepted?dealId=${data.dealId}`,
          method: "POST",
        };
      },
      invalidatesTags: ["ACCEPT-OFFER"],
    }),
    extendDeadline: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/client/my-applications/extend-deadline?dealId=${data.dealId}&deadlineType=${data.dealType}`,
          method: "PUT",
          dealType: data.dealType,
        };
      },
      invalidatesTags: ["REQUEXT-EXTENTION"],
    }),
    uploadDocument: builder.mutation({
      query: (payload) => {
        return {
          url: `/v1/client/my-applications/upload-resume?dealId=${payload.dealId}`,
          method: "PUT",
          body: payload.file,
          // formData:true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationByDealIdQuery,
  useGetPaymentDataQuery,
  useSubmitApplicationMutation,
  useAcceptOfferMutation,
  useExtendDeadlineMutation,
  useUploadDocumentMutation,
} = myApplicationClient;
