import { ReactNode } from "react";

export enum FEEDBACK_QUESTION_TYPE {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTI_CHOICE = "MULTI_CHOICE",
  SUBJECTIVE = "SUBJECTIVE",

  BINARY_TRUE_FALSE = "BINARY_TRUE_FALSE",
  BINARY_LIKE_DISLIKE = "BINARY_LIKE_DISLIKE",
  BINARY_YES_NO = "BINARY_YES_NO",

  SCALE_NUMBER = "SCALE_NUMBER",
  SCALE_STAR = "SCALE_STAR",
  SCALE_EMOJI = "SCALE_EMOJI",
}

export enum FORM_TYPE {
  QUIZ = "QUIZ",
  FEEDBACK = "FEEDBACK",
  ONBOARDING = "ONBOARDING",
}

export enum QUESTION_CATEGORY {
  CES = "CES",
  CSAT = "CSAT",
  NPS = "NPS",
  CSU = "CSU",
  STAKEHOLDER = "STAKEHOLDER",
  OTHER = "OTHER",
}

export enum QUESTION_SUB_CATEGORY {
  SALES_BDR = "SALES_BDR",
  LEAD_MENTOR = "LEAD_MENTOR",
  INDUSTRY_GUIDE = "INDUSTRY_GUIDE",
  COURSE_COORDINATOR = "COURSE_COORDINATOR",
  LEARNER = "LEARNER",
  PLACEMENT_TEAM = "PLACEMENT_TEAM",
  NOVATR_TEAM = "NOVATR_TEAM",
  NOVATR_SUPPORT = "NOVATR_SUPPORT",
}
export class FeedbackOptions {
  optionId: number;
  text?: string;
  icon?: Record<string, string>;
  isActive?: boolean;
}

export class FeedbackQuestions {
  questionId: string;
  question: string;
  questionType: FEEDBACK_QUESTION_TYPE;
  options?: FeedbackOptions[];

  meta?: {
    questionTag?: string;
    category?: QUESTION_CATEGORY;
    subCategory?: QUESTION_SUB_CATEGORY;
  };
}

export type QuestionObjPropType = {
  questionType: FEEDBACK_QUESTION_TYPE;
  questionId: string;
  question: string;
  meta: {
    questionTag?: string;
    category?: QUESTION_CATEGORY;
    subCategory?: QUESTION_SUB_CATEGORY;
  };
  options?: FeedbackOptions[];
};

interface CommonProp {
  setSelectedQuestionIndex: (index: any) => void;
  handleChange: (
    questionObj: QuestionObjPropType,
    dataToSubmit: FeedbackOptions[] | string | number
  ) => void;
}

export interface BinaryScalePropTypes extends CommonProp {
  options: FeedbackOptions[];
  questionObj: QuestionObjPropType;
}

export interface EmojiScalePropTypes extends CommonProp {
  questionObj: QuestionObjPropType;
  options: any;
}

export interface QuestionFormHocPropTypes {
  children: ReactNode;
  questionSubtext: string;
  question: string;
  isBottomContainer: boolean;
}

export interface OpinionScalePropTypes extends CommonProp {
  min: number;
  max: number;
  bottomValueText: string;
  topValueText: string;
  questionObj: QuestionObjPropType;
  options: FeedbackOptions[];
}

export interface RatingScalePropTypes extends CommonProp {
  min: number;
  max: number;
  questionObj: QuestionObjPropType;
}

export interface FormMetaPropType {
  dir: string;
  components: { [key: string]: number | string }[];
}

export class FeedbackForm {
  formId: string;
  formType?: FORM_TYPE;
  title: string;
  description?: string;
  questions: FeedbackQuestions[];
  skippable?: boolean;
  header?: FormMetaPropType;
  footer?: FormMetaPropType;
  submitContent?: {
    [key: string]: string;
  };
  freezeAtLastAttempt?: boolean;
}

export interface loginDetailsPropType {
  userId: string;
  userProfile: any;
  userData: any;
  isAuthenticated: boolean;
}

type UserEventMeta = {
  customerStage: string;
  touchpoint: string;
};
export interface UserEventApiPropType {
  form: FeedbackForm;
  meta: UserEventMeta;
}

export type mixpanelObjType = {
  [key: string]: string | number | boolean | null;
};

export interface FeedbackFormPropType {
  handleClose: (btnText?: string) => void;
  isBottomContainer: boolean;
  formJson: FeedbackForm;
  saveForm: (data: { [key: string]: string }) => void;
  notificationId: number;
  isOpen: boolean;
  mixpanelObj: mixpanelObjType;
  selectQuestionIndex: number;
  setSelectedQuestionIndex: (val: number) => void;
  cohortId: string;
}

export interface FormfooterPropsTypes {
  components: { [key: string]: number | string }[];
  direction: string;
  handleClose: (btnText?: string) => void;
  selectQuestionIndex: number;
  skipForm: (data: { [key: string]: string }) => void;
  payload: any;
  setSelectedQuestionIndex: (val: number) => void;
  notificationId: number;
  mixpanelObj: mixpanelObjType;
}

export interface ThankYouPagePropType {
  handleClose: (btnText?: string) => void;
  submitContent: {
    [key: string]: string;
  };
  isBottomContainer: boolean;
  mixpanelObj: mixpanelObjType;
  saveForm: (data: { [key: string]: string }) => void;
  payload: any;
  notificationId: number;
  cohortId: string;
  isAutoSubmitTrigger: boolean;
}

export interface FormComponentPropTypes {
  components: { [key: string]: number | string }[];
  direction: string;
  handleClose?: (btnText?: string) => void;
  selectQuestionIndex?: number;
  skipForm?: (data: any) => void;
  payload?: any;
  setSelectedQuestionIndex?: (val: any) => void;
  notificationId?: number;
  mixpanelObj?: mixpanelObjType;
}
