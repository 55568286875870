import React from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";

import { theme } from "@/theme";

const DotStyle = styled(UnstyledButton)<{ active: boolean; color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ active, color }) => (active ? color : "#FFFFFF")};
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  margin-right: ${theme.spacing(2)};
  border-radius: 50%;
  transition: 0.3s;
`;

interface ProgressBarPropType {
  accentColor: string;
  activeCount: number;
  totalCount: number;
}

export default function Progressbar({ accentColor, activeCount, totalCount }: ProgressBarPropType) {
  return (
    <Box>
      {new Array(totalCount).fill(0).map((_, index: number) => {
        const isActive = index < activeCount;
        return <DotStyle key={`progress-${index}`} active={isActive} color={accentColor} />;
      })}
    </Box>
  );
}
