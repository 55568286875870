import React from "react";

import Box from "@/design-system/Box";

import { theme } from "@/theme";

import FormComponent from "./FormComponent";
interface FormheaderPropTypes {
  components: any[];
  direction: string;
}
export default function FormHeader({ components, direction }: FormheaderPropTypes) {
  return (
    <Box
      px={{ __: theme.spacing(6), md: theme.spacing(12) }}
      py={{ __: theme.spacing(5), md: theme.spacing(10) }}
    >
      <FormComponent components={components} direction={direction} />
    </Box>
  );
}
