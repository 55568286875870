import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { authCheckStatus } from "@/actions/auth";

import { uploadProfilePhoto } from "@/client/OneistoxApiClient";
import {
  useGetProfileDataQuery,
  useUpdateUserDataMutation,
  useUpdateUserDataV2Mutation,
} from "@/client/userDataClient";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";

import PrimaryButton from "@/design-system-v2/Button/PrimaryButton";
import Typography from "@/design-system-v2/Typography";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

import InputField from "./InputField";
import Popup from "./Popup";
import TextArea from "./TextArea";

const UploadWrapper = styled(FlexContainer)<any>`
  cursor: pointer;
`;
const IgProfilePopup = ({ showPopup, setShowPopup }) => {
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);
  const [isLoading, setIsloading] = useState(true);
  const {
    data: userData,
    isFetching,
    isLoading: loadingData,
  } = useGetProfileDataQuery(loginDetails.userData);
  const [userUpdateDataApi] = useUpdateUserDataV2Mutation();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    bio: "",
    workExperienceInMonths: "",
    currentCompany: "",
    occupation: "",
  });
  useEffect(() => {
    if (!loadingData) setIsloading(false);
  }, [loadingData]);

  useEffect(() => {
    if (userData) {
      if (userData.profileMedia[0]) {
        const picUrl = `https://${userData.profileMedia[0]?.s3Location?.bucket}.s3.ap-south-1.amazonaws.com/${userData.profileMedia[0]?.s3Location?.path}`;
        setFileUrl(picUrl);
      }
      const obj = {
        firstName: userData?.firstName || "",
        lastName: userData?.lastName || "",
        bio: userData?.bio || "",
        workExperienceInMonths: userData?.workExperienceInMonths || "",
        currentCompany: userData?.currentCompany || "",
        occupation: userData?.occupation || "",
      };
      setFormData(obj);
    }
  }, [userData]);

  const handleFileDragUpload = (e: any) => {
    const selectedFile = e.dataTransfer.files[0];
    setFile(selectedFile);
    setFileUrl(URL.createObjectURL(selectedFile));
    setFileUrl;
  };
  const handleFileInputUpload = (e: any) => {
    if (e.target?.files[0]) {
      setFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    }
    (e.target as HTMLInputElement).value = "";
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // setIsDisabled(true);
    // setAlertState("loading");
    setIsloading(true);

    const updatedUserProfile: any = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      occupation: formData.occupation,
      bio: formData?.bio || "",
      workExperienceInMonths: formData?.workExperienceInMonths,
      currentCompany: formData?.currentCompany,
    };

    if (file) {
      const photoData: any = new FormData();
      photoData.append("profilePhoto", file);

      // could be improved using rtk-query
      uploadProfilePhoto(photoData)
        .then(async (res) => {
          const uploadedData = res.data;
          const profileData = {
            ...updatedUserProfile,
            ...uploadedData,
          };
          await userUpdateDataApi(profileData);
          dispatch(authCheckStatus());
          setShowPopup(false);
        })
        .catch(() => {
          //   setAlertState("error");
          //   setIsDisabled(false);
        });
    } else {
      const profileData = updatedUserProfile;
      if (fileUrl === "") {
        profileData.profileMedia = [];
      }
      await userUpdateDataApi(profileData);
      dispatch(authCheckStatus());
      setShowPopup(false);
    }
    setIsloading(false);
  };

  const headingComponent = (
    <Box display={"flex"} mr="16px" width={"100%"} alignItems="center">
      <Box mr="16px" alignContent={"center"} display={"flex"} height="48px" width="48px">
        <img src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/lms/spam_icon.png`} />
      </Box>
      <Box>
        <Typography varient="bodylg" weightVarient="semibold">
          Critical Information Needed
        </Typography>
        <Typography varient="bodysm" color={theme.colors.gray[500]}>
          Please provide the mandatory details for the Capstone phase. Learners will access this
          information during Capstone onboarding.
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box>
      {showPopup && (
        <Popup
          onClose={() => {
            setShowPopup(false);
          }}
          ProfileHeading={headingComponent}
        >
          {!isLoading && (
            <Box>
              <form onSubmit={handleFormSubmit}>
                <FlexContainer gap={theme.spacing(6)} flexDirection={"column"}>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileInputUpload}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <label htmlFor="fileInput" style={{ width: "100%" }}>
                    <UploadWrapper
                      py={theme.spacing(8)}
                      px={theme.spacing(2)}
                      flexDirection={"column"}
                      alignItems={"center"}
                      background={theme.colors.grayCool[25]}
                      borderRadius={theme.radius(3)}
                      border={`1px solid ${theme.colors.grayCool[100]}`}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileDragUpload(e);
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {file || fileUrl ? (
                        <>
                          <Box borderRadius={"50%"} height={"80px"} width={"80px"}>
                            <img
                              src={fileUrl}
                              alt="profile-pic"
                              style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <FlexContainer
                              borderRadius={"50%"}
                              height={"80px"}
                              width={"80px"}
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                              }}
                              background={
                                "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)"
                              }
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Icon
                                type="camera-plus"
                                varient="line"
                                size={16}
                                color={theme.colors.base.white}
                              />
                            </FlexContainer>
                          </Box>
                          <UnstyledButton
                            onClick={(e: any) => {
                              e.preventDefault();
                              setFile(null);
                              setFileUrl("");
                            }}
                          >
                            <FlexContainer
                              alignItems={"center"}
                              gap={theme.spacing(1)}
                              mt={theme.spacing(2)}
                            >
                              <Typography
                                varient="textxs"
                                weightVarient="semibold"
                                color={theme.colors.error[600]}
                              >
                                Remove
                              </Typography>
                              <Icon
                                varient="line"
                                type="trash-04"
                                size={12}
                                color={theme.colors.error[600]}
                              />
                            </FlexContainer>
                          </UnstyledButton>
                        </>
                      ) : (
                        <>
                          <Icon
                            type="camera-plus"
                            varient="line"
                            size={20}
                            color={theme.colors.gray[500]}
                          />
                          <Typography
                            varient="textmd"
                            weightVarient="medium"
                            color={theme.colors.gray[900]}
                            mt={theme.spacing(4)}
                          >
                            Upload a Profile picture
                          </Typography>
                          <Typography
                            mt={theme.spacing(1)}
                            varient="textxs"
                            weightVarient="regular"
                            color={theme.colors.gray[500]}
                          >
                            or Drag and Drop a File
                          </Typography>
                        </>
                      )}
                    </UploadWrapper>
                  </label>

                  <FlexContainer
                    flexDirection={{ _: "column", md: "row" }}
                    gap={theme.spacing(6)}
                    justifyContent={"center"}
                    margin={"auto"}
                    width={"100%"}
                    mt={theme.spacing(6)}
                  >
                    <InputField
                      onChange={handleChange}
                      value={formData?.firstName}
                      label={"First Name"}
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      required
                    />
                    <InputField
                      onChange={handleChange}
                      value={formData?.lastName}
                      label={"Last Name"}
                      type="text"
                      name="lastName"
                      placeholder="Enter your last name"
                      required
                    />
                  </FlexContainer>
                  <FlexContainer
                    flexDirection={{ _: "column", md: "row" }}
                    gap={theme.spacing(6)}
                    justifyContent={"center"}
                    margin={"auto"}
                    width={"100%"}
                  >
                    <InputField
                      onChange={handleChange}
                      value={formData?.workExperienceInMonths}
                      label={"Total Years of Work Experience"}
                      type="text"
                      placeholder="Enter total years of work experience"
                      name="workExperienceInMonths"
                      required
                    />
                    <InputField
                      onChange={handleChange}
                      value={formData?.currentCompany}
                      label={"Current Organisation"}
                      type="text"
                      name="currentCompany"
                      placeholder="Enter your current organisation"
                      required
                    />
                  </FlexContainer>
                  <FlexContainer
                    flexDirection={{ _: "column", md: "row" }}
                    gap={theme.spacing(6)}
                    // justifyContent={"center"}
                    // margin={"auto"}
                    width={{ _: "100%", md: "48%" }}
                  >
                    <InputField
                      onChange={handleChange}
                      value={formData?.occupation}
                      label={"Current Designation"}
                      type="text"
                      placeholder="Enter your current designation"
                      name="occupation"
                      required
                    />
                  </FlexContainer>
                  <FlexContainer
                    flexDirection={{ _: "column", md: "row" }}
                    gap={theme.spacing(6)}
                    justifyContent={"center"}
                    margin={"auto"}
                    width={"100%"}
                  >
                    <TextArea
                      onChange={handleChange}
                      value={formData?.bio}
                      label={"Bio"}
                      type="text"
                      placeholder="Say something about yourself..."
                      fullWidth
                      name="bio"
                      required
                      maxLength={500}
                    />
                  </FlexContainer>
                  <FlexContainer
                    flexDirection={{ _: "column", md: "row" }}
                    gap={theme.spacing(6)}
                    justifyContent={"center"}
                    margin={"auto"}
                    width={"100%"}
                  >
                    <PrimaryButton
                      label={"Submit"}
                      color="neutral"
                      suffixIcon={<Icon type="arrow-right" color={"white"} />}
                      disabled={
                        fileUrl === "" ||
                        formData?.firstName === "" ||
                        formData?.lastName === "" ||
                        formData?.bio === "" ||
                        formData?.workExperienceInMonths === "" ||
                        formData?.currentCompany === "" ||
                        formData?.occupation === ""
                      }
                    />
                  </FlexContainer>
                </FlexContainer>
              </form>
            </Box>
          )}
          {isLoading && <Skeleton width={200} height={24} borderRadius={theme.radius(6)} />}
        </Popup>
      )}
    </Box>
  );
};

export default IgProfilePopup;
