import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  state: "",
  country: "India",
  college: "",
  highestQualification: "",
  bachelorCollegeName: "",
  otherBachelorCollegeName: "",
  bachelortokenValue: "",
  mastersCollegeName: "",
  otherMastersCollegeName: "",
  masterstokenValue: "",
  collegeType: "",
  percentageAchived: "",
  linkedinUrl: "",
  // specialization: "",
  resume: "",
  // collegeUniversity: "",
  // latestOrganization: "",
  // jobTitle: "",
  jobRole: "",
  workExperience: "",
  softwareInstalled: [],
  extraSoftwareKnowledge: "",
  languageProficiency: "",
  salaryIncome: "",
  salaryIncomeOutOfIndia: "",
  whyToLearn: "",
  currentKnowledge: "",
  mobile: "",
  howKnowAboutNovatr: "",
  // industryExperience: "",
  // description: "",
  // selectCountry: "",
  // interior design new fileds
  softwareInstalledInterior: [],
  currentKnowledgeInteriorDesign: "",
  currentKnowledgeBim: "",
  currentKnowledge3DVisualization: "",
  currentKnowledgeInteriorProductDesign: "",
  specializationExcits: "",
  whyChooseAboveSpecialization: "",
};
export const applicationFormSlice = createSlice({
  name: "applicationForm",
  initialState: initialState,
  reducers: {
    changeField: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },
    submitApplication: () => {
      return initialState;
    },
  },
});
