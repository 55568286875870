import { createSlice } from "@reduxjs/toolkit";

export const popupSlice = createSlice({
  name: "popup",
  initialState: {
    activePopup: null,
  },
  reducers: {
    openPopup: (state, action) => {
      const { payload } = action;
      state.activePopup = payload.name;
    },
    closePopup: (state) => {
      state.activePopup = null;
    },
  },
});
