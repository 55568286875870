import React from "react";

import Box from "@/design-system/Box";

import { theme } from "@/theme";

import { FormfooterPropsTypes } from "../type";

import FormComponent from "./FormComponent";

export default function FormFooter({
  components,
  direction,
  handleClose,
  selectQuestionIndex,
  skipForm,
  payload,
  setSelectedQuestionIndex,
  notificationId,
  mixpanelObj,
}: FormfooterPropsTypes) {
  return (
    <Box
      px={{ __: theme.spacing(6), md: theme.spacing(12) }}
      py={{ __: theme.spacing(6), md: theme.spacing(5) }}
    >
      <FormComponent
        notificationId={notificationId}
        setSelectedQuestionIndex={setSelectedQuestionIndex}
        payload={payload}
        skipForm={skipForm}
        components={components}
        direction={direction}
        handleClose={handleClose}
        selectQuestionIndex={selectQuestionIndex}
        mixpanelObj={mixpanelObj}
      />
    </Box>
  );
}
