import { ButtonHTMLAttributes, ReactNode } from "react";
import { SpaceProps } from "styled-system";

import { theme } from "@/theme";

import { DataAttributes, GenericIconType, Size } from "../types";

export type ButtonType =
  | "primary"
  | "primaryNeutral"
  | "primaryDark"
  | "primaryNeutralDark"
  | "transparent"
  | "transparentDark"
  | "transparentDark2"
  | "tertiary"
  | "tertiaryNeutral"
  | "tertiaryNeutralDark";

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    SpaceProps,
    DataAttributes {
  size?: Size;
  buttonType?: ButtonType;
  isLoading?: boolean;
  suffixIcon?: GenericIconType;
  icon?: GenericIconType;
  label?: string | ReactNode;
  disableGutter?: boolean;
  fullWidth?: boolean;
  shape?: "default" | "rounded";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

type GetSizeType = Record<
  Size,
  {
    height: number;
    fontSize: number;
    padding: [number, number];
    radius: string;
    iconSpace: number;
    iconSize: number;
  }
>;

export const getSize: GetSizeType = {
  xs: {
    height: 46,
    fontSize: 14,
    padding: [8, 16],
    radius: theme.radius(2),
    iconSpace: 8,
    iconSize: 14,
  },
  sm: {
    height: 44,
    fontSize: 14,
    radius: theme.radius(2),
    padding: [12, 20],
    iconSpace: 8,
    iconSize: 16,
  },
  md: {
    height: 48,
    fontSize: 16,
    radius: theme.radius(2),
    padding: [12, 24],
    iconSpace: 8,
    iconSize: 16,
  },
  lg: {
    height: 52,
    fontSize: 18,
    radius: theme.radius(2),
    padding: [12, 32],
    iconSpace: 12,
    iconSize: 20,
  },
  xl: {
    height: 60,
    fontSize: 20,
    radius: theme.radius(3),
    padding: [16, 40],
    iconSpace: 16,
    iconSize: 20,
  },
};
