import styled from "styled-components";

import { theme } from "@/theme";
const UserDetailWrapper = styled.div``;

const DropdownWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  position: relative;
`;

const DropdownButton = styled.button`
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
`;

const DropdownMenu = styled.ul<{ open: any }>`
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 0;
  display: ${({ open }) => (open ? "block" : "none")};
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0;
  color: black;
  width: 240px;
  border-radius: var(--radius-radius-xs, 8px);
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  padding: 8px;
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.03),
    0px 2px 4px 0px rgba(0, 0, 0, 0.03),
    0px 7px 7px 0px rgba(0, 0, 0, 0.03),
    0px 16px 10px 0px rgba(0, 0, 0, 0.02);
`;

const DropdownMenuItem = styled.li<{
  addHorizontalLine: any;
  isActive?: boolean;
}>`
  padding: 16px 12px 16px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 12px 16px;
  margin-top: 2px;
  /* border-top: ${(props) => (props.addHorizontalLine ? "1px solid #ccc" : "none")}; */
  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
    background-color: ${(props) => (props.isActive ? "#f0f0f0" : "transparent")};
    border-radius: ${(props) => (props.isActive ? "8px" : "0px")};
  }
`;

const ImageNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(2)};

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #111322;
  }
`;

const DropdownItemImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
export {
  DropdownButton,
  DropdownItemImage,
  DropdownMenu,
  DropdownMenuItem,
  DropdownWrapper,
  ImageNameWrapper,
  UserDetailWrapper,
};
