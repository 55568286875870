import styled, { css } from "styled-components";
import { layout, space } from "styled-system";

import Button from "@/design-system-v2/Button";

import { SecondaryButtonProps } from "./types";

export const SecondaryStyledButton = styled(Button)<SecondaryButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "default" &&
    css`
      background-color: ${({ theme }) => theme.colors.base.white};
      color: ${({ theme }) => theme.colors.primary[600]};
      border: ${({ theme }) => `1px solid ${theme.colors.purple[600]}`};
      &:hover {
        background-color: ${({ theme }) => theme.colors.purple[50]};
        /* box-shadow: 0px 16px 10px rgba(0, 0, 0, 0.02),
          0px 7px 7px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03); */
        box-shadow: ${({ theme }) => theme.shadows.light.xs};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.purple[100]};
      }
      &:disabled {
        /* background-color: ${({ theme }) => theme.colors.base.white};
        color: ${({ theme }) => theme.colors.purple[300]};
        border: ${({ theme }) => `1px solid ${theme.colors.purple[300]}`}; */
        opacity: 0.3;
      }
    `}

  ${({ color }) =>
    color === "neutral" &&
    css`
      background-color: ${({ theme }) => theme.colors.base.white};
      color: ${({ theme }) => theme.colors.gray[800]};
      border: ${({ theme }) => `1px solid ${theme.colors.gray[300]}`};
      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        box-shadow: ${({ theme }) => theme.shadows.primary.xs};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
      &:disabled {
        /* background-color: ${({ theme }) => theme.colors.gray[300]}; */
        /* color: ${({ theme }) => theme.colors.gray[300]}; */
        opacity: 0.3;
      }
    `}

    ${({ color }) =>
    color === "destructive" &&
    css`
      color: ${({ theme }) => theme.colors.error[600]};
      background-color: ${({ theme }) => theme.colors.base.white};
      border: ${({ theme }) => `1px solid ${theme.colors.error[500]}`};
      &:hover {
        background-color: ${({ theme }) => theme.colors.error[50]};
        box-shadow: ${({ theme }) => theme.shadows.primary.xs};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.error[100]};
      }
      &:disabled {
        /* background-color: ${({ theme }) => theme.colors.base.white};
        border: ${({ theme }) => `1px solid ${theme.colors.error[300]}`};
        color: ${({ theme }) => theme.colors.error[300]}; */
        opacity: 0.3;
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
