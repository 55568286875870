import { ComponentType, FC } from "react";
import SVG from "react-inlinesvg";
import styled, { css } from "styled-components";

import { IconProps, IconVariantTypes } from "./types";

interface StyleProps {
  color?: string;
  stokeColor?: string;
  isActive?: boolean;
}

interface VarientType {
  varient: IconVariantTypes;
  stokeColor?: string;
}

type SCVStyleProps = VarientType & ComponentType<typeof SVG>;

const SCVStyle = styled(SVG)<SCVStyleProps>`
  ${({ varient }) =>
    varient === "duocolors" &&
    css`
      + path {
        fill: ${(props: StyleProps) => props.color || "none"};
        stroke: ${(props: StyleProps) => props.stokeColor || props.color};
      }
    `}
  ${({ varient }) =>
    (varient === "line" || varient === "thin" || varient === "duotone") &&
    css`
      path {
        stroke: ${(props: StyleProps) => props.stokeColor || props.color};
      }
      svg {
        transform: ${(props: StyleProps) => (props.isActive ? "scale(1)" : "scale(none)")};
      }
    `}
    ${({ varient }) =>
    (varient === "fill" || varient === "solid" || varient === "misc") &&
    css`
      path {
        fill: ${(props: StyleProps) => props.color || "none"};
        stroke: ${(props: StyleProps) => props.stokeColor || props.color};
      }
    `}
    ${({ varient }) =>
    varient === "scaled" &&
    css`
      path {
        fill: ${(props: StyleProps) => props.color || "none"};
      }
    `}
`;

const Icon: FC<IconProps> = ({
  type,
  stokeColor,
  varient = "thin",
  color = "black",
  size = 24,
  onClick,
}: IconProps) => {
  return (
    <SCVStyle
      color={color}
      stokeColor={stokeColor}
      varient={varient}
      loader={<div style={{ height: size, width: size }} />}
      height={size}
      width={size}
      src={`/icons/${varient}/${type}.svg`}
      onClick={onClick}
      name="svg"
    />
  );
};

export default Icon;
