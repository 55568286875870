import { useRouter } from "next/router";
import { FC } from "react";

import { generateCypressDataIdForButton } from "@/utils/utils";

import { PrimaryStyledButton } from "./primaryButton.styles";
import { PrimaryButtonProps } from "./types";

const PrimaryButton: FC<PrimaryButtonProps> = ({ color, loading, ...rest }) => {
  const { asPath } = useRouter();

  return (
    <PrimaryStyledButton
      color={color}
      loading={loading}
      data-cy={generateCypressDataIdForButton(asPath, rest)}
      {...rest}
    />
  );
};

export default PrimaryButton;

PrimaryButton.defaultProps = {
  color: "purple",
  loading: false,
};
