import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type StreamStatus = "STREAMING" | "FINISHED";

type P = {
  streamStatus: StreamStatus;
};

const initialState: P = {
  streamStatus: "FINISHED",
};

export const streamTextSlice = createSlice({
  name: "streamText",
  initialState,
  reducers: {
    changeStreamStatus: (state, action: PayloadAction<StreamStatus>) => {
      const { payload } = action;
      state.streamStatus = payload;
    },
  },
});
