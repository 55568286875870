import { theme } from "@/theme";

export const badgeStyleVarients = {
  dark: {
    green: {
      dotColor: theme.colors.success[100],
      borderColor: null,
      bgColor: theme.colors.success[500],
      textColor: theme.colors.success[100],
    },
    moss: {
      dotColor: theme.colors.moss[100],
      borderColor: null,
      bgColor: theme.colors.moss[500],
      textColor: theme.colors.moss[100],
    },
    red: {
      dotColor: theme.colors.error[100],
      borderColor: null,
      bgColor: theme.colors.error[500],
      textColor: theme.colors.error[100],
    },
    orange: {
      dotColor: theme.colors.orange[100],
      borderColor: null,
      bgColor: theme.colors.orange[500],
      textColor: theme.colors.orange[100],
    },
    yellow: {
      dotColor: theme.colors.warning[100],
      borderColor: null,
      bgColor: theme.colors.warning[500],
      textColor: theme.colors.warning[100],
    },
    gray: {
      dotColor: theme.colors.grayCool[100],
      borderColor: null,
      bgColor: theme.colors.grayCool[400],
      textColor: theme.colors.grayCool[100],
    },
    black: {
      dotColor: theme.colors.gray[100],
      borderColor: null,
      bgColor: theme.colors.gray[800],
      textColor: theme.colors.gray[100],
    },
    blue: {
      dotColor: theme.colors.blueDark[100],
      borderColor: null,
      bgColor: theme.colors.blueDark[500],
      textColor: theme.colors.blueDark[100],
    },
    indigo: {
      dotColor: theme.colors.indigo[100],
      borderColor: null,
      bgColor: theme.colors.indigo[500],
      textColor: theme.colors.indigo[100],
    },
    primary: {
      dotColor: theme.colors.primary[100],
      borderColor: null,
      bgColor: theme.colors.primary[500],
      textColor: theme.colors.primary[100],
    },
    purple: {
      dotColor: theme.colors.purple[100],
      borderColor: null,
      bgColor: theme.colors.purple[500],
      textColor: theme.colors.purple[100],
    },
    transparent: {
      dotColor: theme.colors.gray[100],
      borderColor: null,
      bgColor: "rgba(0, 0, 0, 0.2)",
      textColor: theme.colors.gray[100],
    },
    success: {
      dotColor: theme.colors.success[100],
      borderColor: null,
      bgColor: theme.colors.success[600],
      textColor: theme.colors.gray[100],
    },
    warning: {
      dotColor: theme.colors.warning[200],
      bgColor: theme.colors.warning[500],
      textColor: theme.colors.warning[100],
    },
  },
  light: {
    green: {
      dotColor: theme.colors.success[700],
      borderColor: null,
      bgColor: theme.colors.success[100],
      textColor: theme.colors.success[700],
    },
    moss: {
      dotColor: theme.colors.moss[700],
      borderColor: null,
      bgColor: theme.colors.moss[100],
      textColor: theme.colors.moss[700],
    },
    red: {
      dotColor: theme.colors.error[700],
      borderColor: null,
      bgColor: theme.colors.error[100],
      textColor: theme.colors.error[700],
    },
    orange: {
      dotColor: theme.colors.orange[700],
      borderColor: null,
      bgColor: theme.colors.orange[100],
      textColor: theme.colors.orange[700],
    },
    yellow: {
      dotColor: theme.colors.warning[700],
      borderColor: null,
      bgColor: theme.colors.warning[100],
      textColor: theme.colors.warning[700],
    },
    gray: {
      dotColor: theme.colors.grayCool[800],
      borderColor: null,
      bgColor: theme.colors.grayCool[200],
      textColor: theme.colors.grayCool[800],
    },
    black: {
      dotColor: theme.colors.gray[700],
      borderColor: null,
      bgColor: theme.colors.gray[50],
      textColor: theme.colors.gray[700],
    },
    blue: {
      dotColor: theme.colors.blueDark[700],
      borderColor: null,
      bgColor: theme.colors.blueDark[100],
      textColor: theme.colors.blueDark[700],
    },
    darkBlue: {
      dotColor: theme.colors.blue[400],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.base.white,
      textColor: theme.colors.blue[800],
    },
    indigo: {
      dotColor: theme.colors.indigo[700],
      borderColor: null,
      bgColor: theme.colors.indigo[100],
      textColor: theme.colors.indigo[700],
    },
    primary: {
      dotColor: theme.colors.primary[100],
      borderColor: null,
      bgColor: theme.colors.primary[500],
      textColor: theme.colors.primary[100],
    },
    purple: {
      dotColor: theme.colors.purple[700],
      borderColor: null,
      bgColor: theme.colors.purple[100],
      textColor: theme.colors.purple[700],
    },
    transparent: {
      dotColor: theme.colors.gray[800],
      borderColor: null,
      bgColor: "rgba(255, 255, 255, 0.20)",
      textColor: theme.colors.gray[800],
    },
    grayCool: {
      dotColor: theme.colors.grayCool[100],
      borderColor: theme.colors.gray[500],
      bgColor: theme.colors.grayCool[500],
      textColor: theme.colors.grayCool[100],
    },
    warning: {
      dotColor: theme.colors.warning[500],
      bgColor: theme.colors.warning[50],
      textColor: theme.colors.warning[700],
    },
  },
  outline: {
    green: {
      dotColor: theme.colors.success[700],
      borderColor: theme.colors.success[500],
      bgColor: "unset",
      textColor: theme.colors.success[700],
    },
    moss: {
      dotColor: theme.colors.moss[700],
      borderColor: theme.colors.moss[500],
      bgColor: "unset",
      textColor: theme.colors.moss[700],
    },
    red: {
      dotColor: theme.colors.error[700],
      borderColor: theme.colors.error[500],
      bgColor: "unset",
      textColor: theme.colors.error[700],
    },
    orange: {
      dotColor: theme.colors.orange[700],
      borderColor: theme.colors.orange[500],
      bgColor: "unset",
      textColor: theme.colors.orange[700],
    },
    yellow: {
      dotColor: theme.colors.warning[700],
      borderColor: theme.colors.warning[500],
      bgColor: "unset",
      textColor: theme.colors.warning[700],
    },
    gray: {
      dotColor: theme.colors.grayCool[800],
      borderColor: theme.colors.grayCool[500],
      bgColor: "unset",
      textColor: theme.colors.grayCool[800],
    },
    grayCool: {
      dotColor: theme.colors.grayCool[800],
      borderColor: theme.colors.grayCool[200],
      bgColor: "unset",
      textColor: theme.colors.gray[400],
    },
    black: {
      dotColor: theme.colors.gray[700],
      borderColor: theme.colors.base.white,
      bgColor: "unset",
      textColor: theme.colors.gray[700],
    },
    blue: {
      dotColor: theme.colors.blueDark[700],
      borderColor: theme.colors.blueDark[500],
      bgColor: "unset",
      textColor: theme.colors.blueDark[700],
    },
    indigo: {
      dotColor: theme.colors.indigo[700],
      borderColor: theme.colors.indigo[500],
      bgColor: "unset",
      textColor: theme.colors.indigo[700],
    },
    primary: {
      dotColor: theme.colors.primary[100],
      borderColor: theme.colors.primary[500],
      bgColor: "unset",
      textColor: theme.colors.primary[100],
    },
    purple: {
      dotColor: theme.colors.purple[700],
      borderColor: theme.colors.purple[500],
      bgColor: "unset",
      textColor: theme.colors.purple[700],
    },
    transparent: {
      dotColor: theme.colors.gray[800],
      borderColor: "rgba(255, 255, 255, 0.20)",
      bgColor: "unset",
      textColor: theme.colors.gray[800],
    },
    warning: {
      dotColor: theme.colors.warning[500],
      bgColor: "none",
      textColor: theme.colors.warning[700],
    },
  },
  whiteBg: {
    green: {
      dotColor: theme.colors.success[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.success[500],
    },
    moss: {
      dotColor: theme.colors.moss[700],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.moss[700],
    },
    red: {
      dotColor: theme.colors.error[600],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.error[600],
    },
    orange: {
      dotColor: theme.colors.orange[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.orange[500],
    },
    yellow: {
      dotColor: theme.colors.warning[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.warning[500],
    },
    gray: {
      dotColor: theme.colors.grayCool[800],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.grayCool[800],
    },
    black: {
      dotColor: theme.colors.gray[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.gray[500],
    },
    blue: {
      dotColor: theme.colors.blueDark[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.blueDark[500],
    },
    darkBlue: {
      dotColor: theme.colors.blue[400],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.base.white,
      textColor: theme.colors.blue[800],
    },
    indigo: {
      dotColor: theme.colors.indigo[600],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.indigo[600],
    },
    primary: {
      dotColor: theme.colors.primary[500],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.primary[500],
    },
    purple: {
      dotColor: theme.colors.purple[100],
      borderColor: null,
      bgColor: theme.colors.base.white,
      textColor: theme.colors.purple[100],
    },
    transparent: {
      dotColor: theme.colors.gray[500],
      borderColor: null,
      bgColor: "rgba(0, 0, 0, 0.2)",
      textColor: theme.colors.gray[500],
    },
  },
  bold: {
    primary: {
      dotColor: theme.colors.primary[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.primary[50],
      textColor: theme.colors.primary[700],
    },
    gray: {
      dotColor: theme.colors.gray[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.gray[200],
      textColor: theme.colors.gray[700],
    },
    warning: {
      dotColor: theme.colors.warning[400],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.base.white,
      textColor: theme.colors.gray[900],
    },
    success: {
      dotColor: theme.colors.success[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.success[50],
      textColor: theme.colors.success[700],
    },
    error: {
      dotColor: theme.colors.error[500],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.error[100],
      textColor: theme.colors.error[500],
    },
    purple: {
      dotColor: theme.colors.purple[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.purple[50],
      textColor: theme.colors.purple[700],
    },
    blue: {
      dotColor: theme.colors.blue[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.blue[100],
      textColor: theme.colors.blue[700],
    },
    darkBlue: {
      dotColor: theme.colors.blue[800],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.blue[200],
      textColor: theme.colors.blue[800],
    },
    blueGray: {
      dotColor: theme.colors.grayBlue[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.grayBlue[50],
      textColor: theme.colors.grayBlue[700],
    },
    green: {
      dotColor: theme.colors.green[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.green[50],
      textColor: theme.colors.green[700],
    },
    indigo: {
      dotColor: theme.colors.indigo[700],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.indigo[50],
      textColor: theme.colors.indigo[700],
    },
    yellow: {
      dotColor: theme.colors.yellow[500],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.base.white,
      textColor: theme.colors.yellow[500],
    },
    orange: {
      dotColor: theme.colors.orange[200],
      bgColor: theme.colors.orange[600],
      textColor: theme.colors.orange[100],
      borderColor: theme.colors.gray[200],
    },
  },
  invert: {
    primary: {
      dotColor: theme.colors.primary[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.primary[700],
      textColor: theme.colors.primary[50],
    },
    gray: {
      dotColor: theme.colors.gray[100],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.gray[700],
      textColor: theme.colors.gray[100],
    },
    warning: {
      dotColor: theme.colors.warning[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.warning[700],
      textColor: theme.colors.warning[50],
    },
    success: {
      dotColor: theme.colors.success[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.success[700],
      textColor: theme.colors.success[50],
    },
    error: {
      dotColor: theme.colors.error[100],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.error[500],
      textColor: theme.colors.error[100],
    },
    purple: {
      dotColor: theme.colors.purple[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.purple[700],
      textColor: theme.colors.purple[50],
    },
    blue: {
      dotColor: theme.colors.blue[100],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.blue[700],
      textColor: theme.colors.blue[100],
    },
    darkBlue: {
      dotColor: theme.colors.blue[200],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.blue[800],
      textColor: theme.colors.blue[200],
    },
    blueGray: {
      dotColor: theme.colors.grayBlue[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.grayBlue[700],
      textColor: theme.colors.grayBlue[50],
    },
    green: {
      dotColor: theme.colors.green[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.green[700],
      textColor: theme.colors.green[50],
    },
    indigo: {
      dotColor: theme.colors.indigo[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.indigo[700],
      textColor: theme.colors.indigo[50],
    },
    yellow: {
      dotColor: theme.colors.yellow[50],
      borderColor: theme.colors.gray[200],
      bgColor: theme.colors.yellow[700],
      textColor: theme.colors.yellow[50],
    },
    orange: {
      dotColor: theme.colors.orange[600],
      bgColor: theme.colors.orange[200],
      textColor: theme.colors.orange[600],
      borderColor: theme.colors.gray[200],
    },
  },
};

export const sizeVarients = {
  sm: {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    padding: "4px 8px 4px 8px",
    borderRadius: theme.radius(4),
  },
  md: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    padding: "4px 12px 4px 12px",
    borderRadius: theme.radius(4),
  },
  lg: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    padding: "4px 12px 4px 12px",
    borderRadius: theme.radius(5),
  },
};

export const imageBadgeSizeVarients = {
  sm: {
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    padding: "2px 6px 2px 2px",
    imageSize: 16,
  },
  md: {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    padding: "4px 8px 4px 4px",
    imageSize: 16,
  },
  lg: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    padding: "2px 12px 2px 2px",
    imageSize: 24,
  },
};
