// import * as Sentry from "@sentry/nextjs";

import { sendAnalytics } from "../client/OneistoxApiClient";

export const pageUnitView = async (cohortId: any, page: any, meta: any, payload: any) => {
  if (cohortId) {
    await sendAnalytics(cohortId, "PAGE_VIEW", page, meta, payload);
  }
};

export const tracksignup = (userId: any, userProp: any, eventName: any, eventProp: any) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, userProp);
    window.analytics.track(eventName, eventProp);
  }
  const mixPanelUserProp = {
    $name: userProp.name,
    $email: userProp.email,
    userId,
  };
  if (typeof window?.mixpanel?.identify === "function") {
    window.mixpanel.identify(userProp.email);
  }
  if (typeof window?.mixpanel?.people?.set === "function") {
    window.mixpanel.people.set({ ...mixPanelUserProp });
  }
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};
// export const tracksignupStart = (data: any) => {
//   window?.mixpanel?.track("Sign Up Started", data);
// };

export const tracklogin = (userId: any, userProp: any, eventName: any, eventProp: any) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, userProp);
    window.analytics.track(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
  const mixPanelUserProp = {
    $name: userProp.name,
    $email: userProp.email,
    userId,
  };
  if (typeof window?.mixpanel?.identify === "function") {
    window.mixpanel.identify(userProp.email);
  }
  if (typeof window?.mixpanel?.people?.set === "function") {
    window.mixpanel.people.set({ ...mixPanelUserProp });
  }
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};

export const onRouteChange = (url: any, path: any, referrer: any, search: any) => {
  if (window?.analytics) {
    window.analytics?.page(url, {
      url: window.location.href,
      path: path,
      referrer: referrer,
      search: search,
    });
  }
};

export const trackauth = (userId: any, userProp: any, eventName: any, eventProp: any) => {
  if (window?.posthog) {
    window.posthog.identify(userId);
    window.posthog.people.set(userProp);
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.analytics) {
    window.analytics.identify(userId, { ...userProp, type: "revalidated" });
    window.analytics.track(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.identify(userId);
    window.heap.addUserProperties(userProp);
    window.heap.track(eventName, eventProp);
  }
  const mixPanelUserProp = {
    $name: userProp.name,
    $email: userProp.email,
  };
  if (typeof window?.mixpanel?.identify === "function") {
    window.mixpanel.identify(userId);
  }
  if (typeof window?.mixpanel?.people?.set === "function") {
    window.mixpanel.people.set({ ...mixPanelUserProp });
  }
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};

export const trackproductview = (eventName: any, eventProp: any) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.track(eventName, eventProp);
  }
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};

export const trackebooklead = (eventName: any, eventProp: any) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};

export const trackcontactus = (eventName: any, eventProp: any = {}) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (eventProp && window?.mixpanel) {
    const { loginDetails, ...eventTrackdata } = eventProp;
    const identifier = eventTrackdata.isLoggedIn ? loginDetails?.emailId : eventProp.email;

    if (typeof window?.mixpanel?.identify === "function") {
      window.mixpanel.identify(identifier);
    }

    const mixPanelUserProp = {
      $name: eventProp.name,
      $email: eventProp.email,
      $phone: eventProp.phone,
      userId: loginDetails?.userId || "",
    };
    if (typeof window?.mixpanel?.people?.set === "function") {
      window.mixpanel.people.set({ ...mixPanelUserProp });
    }
    if (typeof window?.mixpanel?.track === "function") {
      window.mixpanel.track(eventName, eventProp);
    }
  }
};

export const formEvent = (eventName, eventProp) => {
  if (typeof window?.mixpanel?.track === "function") {
    window.mixpanel.track(eventName, eventProp);
  }
};

export const trackleadform = (eventName: any, eventProp: any = {}) => {
  if (window?.analytics) {
    window.analytics.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    eventProp?.product?.name === "Building Information Modelling" &&
      window.gtag("event", "conversion", {
        send_to: "AW-698995117/1pPrCIjvnLoBEK2jp80C",
      });
  }
  if (window?.lintrk) {
    eventProp?.product?.name === "Building Information Modelling" &&
      window.lintrk("track", { conversion_id: 4658178 });
  }
  if (eventProp && window?.mixpanel) {
    const { loginDetails, ...eventTrackdata } = eventProp;
    const identifier =
      loginDetails && eventTrackdata.isLoggedIn ? loginDetails?.emailId : eventProp.email;

    if (typeof window?.mixpanel?.identify === "function") {
      window.mixpanel.identify(identifier);
    }

    const mixPanelUserProp = {
      $name: eventProp.name,
      $email: eventProp.email,
      $phone: eventProp.phone,
      userId: loginDetails?.userId || "",
    };
    if (typeof window?.mixpanel?.people?.set === "function") {
      window.mixpanel.people.set({ ...mixPanelUserProp });
    }
    if (typeof window?.mixpanel?.track === "function") {
      window.mixpanel.track(eventName, eventTrackdata);
    }
  }
};

export const trackaddtocart = (eventName: any, eventProp: any) => {
  if (window?.analytics) {
    window.analytics?.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    if (eventProp?.product?.product_id?.startsWith("BIM")) {
      window?.gtag("event", "conversion", {
        send_to: "AW-698995117/GXHJCPbs4aoDEK2jp80C",
      });
    }
  }
  if (window?.mixpanel) {
    window.mixpanel?.track(eventName, eventProp);
  }
};

export const trackproductpurchased = (eventName: any, eventProp: any) => {
  if (window?.analytics) {
    window.analytics?.track(eventName, eventProp);
  }
  if (window?.posthog) {
    window.posthog.people.set({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.posthog.capture(eventName, eventProp);
  }
  if (window?.heap) {
    window.heap.addUserProperties({
      name: eventProp.name,
      email: eventProp.email,
      mobile: eventProp.mobile,
    });
    window.heap.track(eventName, eventProp);
  }
  if (window?.gtag) {
    eventProp?.products[0]?.startsWith("BIM") &&
      window.gtag("event", "conversion", {
        send_to: "AW-698995117/Y9kuCLTp9tEBEK2jp80C",
        transaction_id: eventProp.transaction_id,
      });
  }
  if (window?.lintrk) {
    eventProp?.products[0]?.startsWith("BIM") && window.lintrk("track", { conversion_id: 6383593 });
  }
  if (window?.mixpanel) {
    window.mixpanel?.track(eventName, eventProp);
  }
};

export const setUser = (userId: number) => {
  // try {
  //   Sentry.setUser({ userId: userId });
  // } catch (e) {
  //   console.log(e);
  // }
  if (typeof window?.mixpanel?.identify === "function") {
    window.mixpanel.identify(userId);
  }
};

export const resetUser = () => {
  // try {
  //   Sentry.setUser({ userId: null });
  // } catch (e) {
  //   console.log(e);
  // }
  if (window?.mixpanel?.reset) {
    window.mixpanel.reset();
  }
};

// Placement-hub mixpanel tracking

export const trackPageViewEvent = (eventName: string, eventData) => {
  window?.mixpanel?.identify(eventData.email);
  const mixPanelUserProp = {
    $email: eventData.email,
  };
  window?.mixpanel?.people.set({ ...mixPanelUserProp });
  window?.mixpanel?.track(eventName, eventData);
};
