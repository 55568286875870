import { useRouter } from "next/router";

import Icon from "@/design-system/Icon";

import {
  GlobalNavbarMobileIconsWrapper,
  SidebarItemText,
  SidebarMenuMobileItem,
} from "../sidebarStyle";

import { LinkWrapper } from "./LinkWrapper";

const GlobalNavBarMobile = ({
  items,
  // globalSideBarToggle,
  isMentorDashboard,
  isLeadMentorDashboard,
  lockedPages,
}) => {
  const router = useRouter();
  return (
    <>
      {items.map((item) => {
        const isLocked = lockedPages.includes(item.link) || item.locked;
        const isCurrentRouteActive = router.pathname.includes(item?.link);
        return (
          <LinkWrapper
            key={item?.id}
            href={`/${
              isLeadMentorDashboard ? "lm" : isMentorDashboard ? "mentor" : "learn"
            }/${router.query.cohortId}/${item.link}`}
            disabled={isLocked}
          >
            <SidebarMenuMobileItem
              locked={isLocked}
              id={item?.id}
              active={isCurrentRouteActive}
              // isDesktop={!isMobileScreen}
            >
              <GlobalNavbarMobileIconsWrapper>
                <Icon
                  type={item.image.replace("/icons/", "").replace(".svg", "")}
                  varient="line"
                  color={isLocked ? "#B9C0D4" : isCurrentRouteActive ? "#5925DC" : "#30374F"}
                  size={20}
                />
                <SidebarItemText
                  // hideText={globalSideBarToggle}
                  active={isCurrentRouteActive}
                  style={{
                    color: isLocked ? "#7D89B0" : isCurrentRouteActive ? "#5925DC" : "#30374F",
                  }}
                >
                  {item.text}
                </SidebarItemText>
                {isLocked && (
                  <div style={{ marginLeft: "10px" }}>
                    <Icon type={item.lockedIcon} varient="line" color="#B9C0D4" size={20} />
                  </div>
                )}
              </GlobalNavbarMobileIconsWrapper>
            </SidebarMenuMobileItem>
          </LinkWrapper>
        );
      })}
    </>
  );
};

export default GlobalNavBarMobile;
