import { AnimatePresence, motion } from "framer-motion";
import React, { memo } from "react";

const variants = {
  enter: () => {
    return {
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};

export const AnimateQuestionChange = ({ children, id, direction }) => {
  return (
    <AnimatePresence mode="popLayout" initial={false} custom={direction}>
      <motion.div
        key={id}
        variants={variants}
        custom={direction}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          ease: "easeIn",
          x: { type: "spring", duration: 1.5, bounce: 0.4 },
          height: { duration: 1.5 },
          opacity: { duration: 0.8 },
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default memo(AnimateQuestionChange);
