import styled from "styled-components";

import { theme } from "@/theme";

const NavbarWrapper = styled.nav`
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #fff;
  padding: 14px 20px;
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.03),
    0px 4px 8px 0px rgba(0, 0, 0, 0.03),
    0px 14px 14px 0px rgba(0, 0, 0, 0.03),
    0px 32px 19px 0px rgba(0, 0, 0, 0.02);
`;

const NavbarItem = styled.div<{ isQuizLayout: boolean }>`
  display: ${({ isQuizLayout }) => (isQuizLayout ? "flex" : "none")};
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
  /* gap: 8px; */
`;

const NavbarImage = styled.img`
  height: 24px;
  color: ${theme.colors.grayCool[600]};
`;

const NavbarText = styled.span`
  font-size: 14px;
  font-weight: 600;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

const NavbarLeftContainer = styled.div`
  display: flex;
  color: black;
  align-items: center;
  gap: 12px;
  h1 {
    background-color: blue;
  }
`;

const DropdownItemImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const NavbarRightContainer = styled.div`
  background-color: #fff;
`;
export {
  DropdownItemImage,
  NavbarImage,
  NavbarItem,
  NavbarLeftContainer,
  NavbarRightContainer,
  NavbarText,
  NavbarWrapper,
};
