import axios from "axios";
import Cookies from "js-cookie";

import { appBaseCreateApi } from "./BaseQueryApiClient";

const OneistoxAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROXY_2,
});
OneistoxAPI.defaults.headers.common["Content-Type"] = "application/json";
OneistoxAPI.defaults.headers.common["x-auth-token"] = Cookies.get("token");

if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
  OneistoxAPI.defaults.headers.common["x-auth-override"] =
    process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER;
}

export const submitDirectBulkFormResponse = async (
  formId: string,
  productId: string,
  responseEntries: any[],
  meta?: any,
  event?: string
) => {
  return OneistoxAPI.put(
    `v1/client/form/response/direct-bulk/${formId}`,
    { responseEntries, meta, event },
    {
      headers: { "x-product-id": productId },
    }
  );
};

export const OnboardingForm = appBaseCreateApi({
  reducerPath: "onboardingForm",
  endpoints: (builder) => ({
    getOnboardingForm: builder.query({
      query: () => `v1/client/user/forms?productId=PUBLIC`,
    }),
    submitOnboardingForm: builder.mutation({
      query: (requestData) => {
        return {
          url: `v3/client/user/profile`,
          body: requestData,
          method: "PUT",
        };
      },
    }),
  }),
});

export const { useGetOnboardingFormQuery, useSubmitOnboardingFormMutation } = OnboardingForm;

export const OneistoxAPIOnBoarding = OneistoxAPI;
