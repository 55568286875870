// import { RootState } from "@/store";
import type {
  BaseQueryFn as bqfn,
  CreateApiOptions,
  EndpointDefinitions,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import Router from "next/router";

import { ApiResponseError } from "./type";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_PROXY_2,
  prepareHeaders: async (
    headers
    // { getState }
  ) => {
    // const { rootReducer } = (getState() as RootState);

    headers.set("Content-Type", "application/json");
    headers.set("x-auth-token", `${Cookies.get("token")}`);

    if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
      headers.set("x-auth-override", process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER);
    }

    return headers;
  },
});

type BaseQueryFn = bqfn<string | FetchArgs, unknown, FetchBaseQueryError | ApiResponseError>;

const appFetchBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && typeof result.error === "object") {
    const { status, data } = result.error;

    if (status === 401) {
      if (!Router.query.callbackUrl) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const authActions = require("@/actions/auth");
        api.dispatch(authActions.logout("/login?callbackUrl=" + Router.asPath));
      }
      return Promise.reject(result.error);
    }

    // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions);
    // if (refreshResult.data) {
    // 	// store the new token
    // 	api.dispatch(tokenReceived(refreshResult.data));
    // 	// retry the initial query
    // 	result = await baseQuery(args, api, extraOptions);
    // } else {
    // 	api.dispatch(loggedOut());
    // }
  }
  return result;
};

export const appBaseCreateApi = <
  Definitions extends EndpointDefinitions,
  ReducerPath extends string,
  TagTypes extends string,
>(
    options: Omit<CreateApiOptions<BaseQueryFn, Definitions, ReducerPath, TagTypes>, "baseQuery">
  ) =>
    createApi({
      ...options,
      baseQuery: appFetchBaseQuery,
    });

export const baseQueryApiClient = appBaseCreateApi({
  reducerPath: "getContent",
  tagTypes: [
    "ONBOARDING-UPDATE",
    "STAGEREVIEW-UPDATE",
    "TASK-UPDATE",
    "STATUS-COMPLETE",
    "ELECTIVE-SELECTION",
    "UPLOAD-ASSIGNMENT",
    "STUDIO-SELECTION",
    "REFETCH-TIPS",
    "REFETCH-COACHMARKS",
    "UPLOAD-DOCUMENT",
    "MARK-ABSENT",
    "FEEDBACK-SUBMISSION",
    "PRIMARY-IG-SELECTION",
    "LEARNER-FEEDBACK-DETAIL",
    "FILTERED-QUESTIONS",
  ],
  endpoints: () => ({}),
});
