import { useRouter } from "next/router";

import { getScrollPositionByPercentage } from "@/utils/utils";

import {
  FEEDBACK_QUESTION_TYPE,
  FORM_TYPE,
  FeedbackForm,
  UserEventApiPropType,
  loginDetailsPropType,
  mixpanelObjType,
} from "./type";

export const formJson = {
  id: "",
  name: "",
  type: "quick | general",
  skippable: true,
  header: {
    dir: "vertical",
    components: [
      {
        type: "HEADER_TEXT",
        value: "Hey there, down here 👋 Want to help us improve the experience?",
      },
      {
        type: "SUBHEADER_TEXT",
        value:
          "Your feedback helps us improve the product experience and create better and useful solutions. Thank you again!",
      },
    ],
  },
  questions: [
    {
      type: "SCALE_NUMBER",
      question: "How do you feel today?",
      min: 1,
      max: 5,
      diff: 1,
      BottomValueText: "Not at all",
      TopValueText: "Extremely",
    },
    {
      type: "SCALE_BINARY",
      question: "How do you feel today?",
      component: [
        {
          icon: "thumbsUp",
          value: "Yes",
          id: 0,
        },
        {
          icon: "thumsDown",
          value: "No",
          id: 1,
        },
      ],
    },
    {
      type: "SCALE_RATING",
      question: "How do you feel today?",
      questionSubText: "How do you feel today?",
      min: 1,
      max: 5,
    },
    {
      type: "SCALE_EMOJI",
      ratingType: "EMOJI",
      component: [
        {
          icon: "HeartEyes",
          value: "5",
          id: 0,
        },
        {
          icon: "SlightlyFrowning",
          value: "4",
          id: 1,
        },
        {
          icon: "SlightlySmiling",
          value: "3",
          id: 2,
        },
        {
          icon: "GrinningFace",
          value: "2",
          id: 3,
        },
        {
          icon: "AngryFace",
          value: "1",
          id: 4,
        },
      ],
    },
    {
      type: "QUIZ",
      question: "How would you rate the initial navigation within the platform?",
      options: [
        {
          value: "Answer this question to get bonus credits or what so",
          title: "Answer this question to get bonus credits or what so",
          id: 0,
        },
        {
          value: "Answer this question to get bonus credits or what so",
          title: "Answer this question to get bonus credits or what so",
          id: 1,
        },
        {
          value: "Answer this question to get bonus credits or what so",
          title: "Answer this question to get bonus credits or what so",
          id: 2,
        },
        {
          value: "Answer this question to get bonus credits or what so",
          title: "Answer this question to get bonus credits or what so",
          id: 3,
        },
      ],
    },
    {
      type: "TEXT_FIELD",
      question: "How do you feel today?",
      maxLength: 100,
      placeHolderText: "",
      elementType: "text",
      required: true,
    },
  ],
  submitContent: {
    type: "THANK_YOU",
    img: "",
    thankyouText: "Your feedback has been received.",
    acknowledgeText:
      "We greatly value your insights and are committed to continuously enhancing our product.",
    updateText: "Stay tuned for updates! 🙌🏽",
  },
  footer: {
    dir: "horizontal",
    components: [
      {
        type: "LATER_BUTTON",
        buttonText: "I ll do it later",
        buttonColor: "#F2F2F2",
      },
      {
        type: "PROGRESS_BAR",
        accentColor: "#7A5AF8",
        totalCount: 5,
        activeCount: 3,
      },
    ],
  },
};

export const emojiMapping = {
  HeartEyes: 1,
  SlightlyFrowning: 2,
  SlightlySmiling: 3,
  GrinningFace: 4,
  AngryFace: 5,
};

export const numberEmojiMapping: { [key: number]: string } = {
  1: "Angry-Face",
  2: "Slightly-Frowning-Face",
  3: "Slightly-Smiling-Face",
  4: "Grinning-Face-smily",
  5: "Smiling-Face-Heart-Eyes",
};

export const binaryEmojiMapping = {
  1: "yesEmoji",
  2: "noEmoji",
};

export const formJson1: FeedbackForm = {
  formType: FORM_TYPE.FEEDBACK,
  formId: "40a5d25fea6f5a7fc9b0234d71b117e05e2df03b",
  title: "test feedback form",
  skippable: true,
  header: {
    dir: "vertical",
    components: [
      {
        type: "HEADER_TEXT",
        value: "Hey there, down here 👋 Want to help us improve the experience?",
      },
      {
        type: "SUBHEADER_TEXT",
        value:
          "Your feedback helps us improve the product experience and create better and useful solutions. Thank you again!",
      },
    ],
  },
  questions: [
    {
      questionId: "q1",
      questionType: FEEDBACK_QUESTION_TYPE.SINGLE_CHOICE,
      question: "Live session are taken by ?",
      options: [
        {
          optionId: 1,
          text: "Answer this question to get bonus credits",
          isActive: false,
        },
        {
          optionId: 2,
          text: "Answer this question to get bonus credits ",
          isActive: false,
        },
        {
          optionId: 3,
          text: "Answer this question to get bonus credits ",
          isActive: false,
        },
        {
          optionId: 4,
          text: "Answer this question to get bonus credits ",
          isActive: false,
        },
      ],
    },
    {
      questionId: "q4",
      question: "Liked the session?",
      questionType: FEEDBACK_QUESTION_TYPE.BINARY_LIKE_DISLIKE,
      options: [
        {
          text: "Like",
          optionId: 1,
        },
        {
          text: "Dislike",
          optionId: 2,
        },
      ],
    },
    {
      questionId: "Q17",
      question: "Rate backend Developer?",
      questionType: FEEDBACK_QUESTION_TYPE.SCALE_NUMBER,
      options: [
        {
          optionId: 1,
        },
        {
          optionId: 2,
        },
        {
          optionId: 3,
        },
        {
          optionId: 4,
        },
        {
          optionId: 5,
        },
        {
          optionId: 6,
        },
        {
          optionId: 7,
        },
        {
          optionId: 8,
        },
        {
          optionId: 9,
        },
        {
          optionId: 10,
        },
      ],
    },
    {
      questionId: "q11",
      question: "Liked the session?",
      questionType: FEEDBACK_QUESTION_TYPE.SCALE_EMOJI,
      options: [
        {
          optionId: 1,
          text: "Not Sattisfied",
        },
        {
          optionId: 2,
          text: "Sattisfied",
        },
        {
          optionId: 3,
          text: "Happy",
        },
        {
          optionId: 4,
          text: "very happy",
        },
        {
          optionId: 5,
          text: "recommend others",
        },
      ],
    },
    {
      questionId: "q3",
      questionType: FEEDBACK_QUESTION_TYPE.SUBJECTIVE,
      question: "Write feedback for LM under 50 words.",
      meta: {},
    },
  ],
  submitContent: {
    type: "THANK_YOU",
    img: "",
    thankyouText: "Your feedback has been received.",
    acknowledgeText:
      "We greatly value your insights and are committed to continuously enhancing our product.",
    updateText: "Stay tuned for updates! 🙌🏽",
  },
  footer: {
    dir: "horizontal",
    components: [
      {
        type: "LATER_BUTTON",
        buttonText: "I ll do it later",
        buttonColor: "#F2F2F2",
      },
    ],
  },
};

export const getRangeText = (binaryType: string) => {
  switch (binaryType) {
  case FEEDBACK_QUESTION_TYPE.BINARY_LIKE_DISLIKE: {
    return {
      positiveText: "Like",
      negativeText: "Dislike",
    };
  }
  case FEEDBACK_QUESTION_TYPE.BINARY_TRUE_FALSE: {
    return {
      positiveText: "True",
      negativeText: "False",
    };
  }
  case FEEDBACK_QUESTION_TYPE.BINARY_YES_NO: {
    return {
      positiveText: "Yes",
      negativeText: "No",
    };
  }
  }
};

export const progressBarConfig = {
  type: "PROGRESS_BAR",
  accentColor: "#7A5AF8",
  totalCount: 5,
  activeCount: 3,
};

export const submitButtonConfig = {
  type: "SUBMIT_BUTTON",
  buttonText: "Submit",
};

export const laterButtonConfig = {
  type: "LATER_BUTTON",
  buttonText: "I ll do it later",
  buttonColor: "#F2F2F2",
};

export const formHeader = {
  dir: "vertical",
  components: [
    {
      type: "HEADER_TEXT",
      value: "Hi there! Want to help us improve your experience?",
    },
    {
      type: "SUBHEADER_TEXT",
      value: "We're always learning! Let us know how we can make things better for you.",
    },
  ],
};

export const formFooter = {
  dir: "horizontal",
  components: [
    {
      type: "LATER_BUTTON",
      buttonText: "I'll do it later",
      buttonColor: "#F2F2F2",
    },
  ],
};

export const formSubmitContent = {
  type: "THANK_YOU",
  img: "",
  thankyouText: "Your feedback has been received.",
  acknowledgeText:
    "We greatly value your insights and are committed to continuously enhancing our product.",
  updateText: "Stay tuned for updates! 🙌🏽",
};

export const getDivideObj = (options, isMobile) => {
  const size = options.length;

  if (size > 10) {
    if (isMobile)
      return {
        columnLength: 6,
        divideLength: 6,
      };
    else {
      return {
        columnLength: 11,
        divideLength: 11,
      };
    }
  } else if (size == 10) {
    if (isMobile)
      return {
        columnLength: 5,
        divideLength: 5,
      };
    else {
      return {
        columnLength: 11,
        divideLength: 11,
      };
    }
  } else if (size >= 7) {
    return {
      columnLength: 14,
      divideLength: 7,
    };
  } else {
    return {
      columnLength: 10,
      divideLength: 5,
    };
  }
};

export const getMinMaxScore = (questionType, options) => {
  switch (questionType) {
  case FEEDBACK_QUESTION_TYPE.BINARY_LIKE_DISLIKE: {
    return {
      min: 1,
      max: options.length - 1,
    };
  }
  case FEEDBACK_QUESTION_TYPE.SCALE_NUMBER: {
    return {
      min: 1,
      max: options.length - 1,
    };
  }
  }
};

export const getCommonEventProperties = (
  loginDetails: loginDetailsPropType,
  data: UserEventApiPropType,
  cohortId: string,
  previousRoute: string,
  pathname: string
): mixpanelObjType => {
  return {
    formTitle: data?.form?.title || null,
    form_id: "feedback_system",
    formUniqueId: data?.form?.formId || null,
    formType: data?.form?.questions?.length > 1 ? "middle-popup" : "bottom-popup",
    cohortId: cohortId,
    user_id: Number(loginDetails.userId || null),
    name: loginDetails.userProfile.firstName || null,
    email: loginDetails.userData.emailId || null,
    Page_path: pathname || null,
    Current_Page: window?.location?.href || null,
    Page_Referrer: previousRoute || null,
    percent_scrolled: getScrollPositionByPercentage(),
    loginDetails: loginDetails.userData || null,
    isLoggedIn: loginDetails.isAuthenticated,
    customer_stage: data?.meta?.customerStage || null,
    touchpoint: data?.meta?.touchpoint || null,
  };
};
