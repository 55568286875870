import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import {
  useGetCoachmarksQuery,
  useGetCohortQuery,
  useUpdateCoachmarksMutation,
} from "@/client/lmsApiClient";
import { useGetLMCohortQuery } from "@/client/mmsApiClient";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import useMediaQuery from "@/design-system/useMediaQuery";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import { useScreenTime } from "@/hooks/useScreenTime";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { getCohortStartDate } from "@/components/LearningHub/Hub";

import { theme } from "@/theme";
import "react-loading-skeleton/dist/skeleton.css";

import NavigationBar from "../NavigationBar";
import SideBarMain from "../SideBar";

import { WhiteListedPagesForPerformance } from "./constants";
import { Tour } from "./Tour";

const LayoutContainer = styled(FlexContainer)`
  height: calc(100vh - 48px);
  overflow: hidden;
  position: relative;
`;
type cohortType = {
  tags: string[];
  timelines: {
    key: string;
    type?: string;
    value?: string;
    numberValue?: number;
    booleanValue?: boolean;
    meta?: Record<string, any>;
  }[];
};
interface LMSLayoutInterface {
  children?: ReactNode;
  cohortId?: string;
  isMentorDashboard?: boolean;
  isLeadMentorDashboard?: boolean;
  previousRoute: string;
}

const DynamicTour = dynamic(() => import("reactour"), {
  ssr: false,
});

const LMSLayout = ({
  children,
  cohortId,
  isMentorDashboard,
  isLeadMentorDashboard,
  previousRoute,
}: LMSLayoutInterface) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  const [globalSideBarToggle, setGlobalSideBarToggle] = useState(isMobile ? false : true);
  const screenTime = useScreenTime();

  const { evaluationDayInfo } = useAppSelector((state) => state.rootReducer.cohortInfo);

  const loginDetails = useAppSelector((state) => state.rootReducer.auth);

  const dispatch = useAppDispatch();
  const router = useRouter();
  const { route } = router || {};
  const [lmsPageviewTrackObj, setlmsPageviewTrackObj] = useState({});

  const { data: cohortUserData, isSuccess: isCohortSuccess } = useGetCohortQuery(cohortId, {
    skip: isLeadMentorDashboard || Boolean(!cohortId),
  });
  const { data: cohortLMData, isSuccess: isCohortLMSuccess } = useGetLMCohortQuery(cohortId, {
    skip: Boolean(!cohortId) || !isLeadMentorDashboard,
  });
  const { data: coachmarksData } = useGetCoachmarksQuery(cohortId, {
    skip: isLeadMentorDashboard || isMentorDashboard,
    refetchOnMountOrArgChange: true,
  });

  const [updateCoachmarks] = useUpdateCoachmarksMutation();

  const cohort = isLeadMentorDashboard ? cohortLMData : cohortUserData;
  const isSuccess = isLeadMentorDashboard ? isCohortLMSuccess : isCohortSuccess;

  const [lockedPages, setLockedPages] = useState([]);

  const { capstoneInfo } = useAppSelector((state) => state.rootReducer.cohortInfo);
  useEffect(() => {
    if (route) setGlobalSideBarToggle(true);
  }, [route]);

  useEffect(() => {
    if (previousRoute) {
      const trackObjectTempData = {
        user_id: loginDetails.userId,
        name: loginDetails.userProfile.firstName,
        email: loginDetails.userData.emailId,
        cohortid: cohort?.courseId,
        Page_path: window.location.pathname,
        Page_Referrer: previousRoute,
        percent_scrolled: getScrollPositionByPercentage(),
        on_screen_duration: screenTime.getFormattedScreenTime(),
        loginDetails: loginDetails.userData,
        isLoggedIn: loginDetails.isAuthenticated,
      };
      setlmsPageviewTrackObj(trackObjectTempData);

      trackleadform("Page_view", trackObjectTempData);
    }
  }, [previousRoute]);

  const handleLinkClick = (e) => {
    const linkClickTrackObj = {
      ...lmsPageviewTrackObj,
      percent_scrolled: getScrollPositionByPercentage(),
      link_text: e.currentTarget.innerText,
      on_screen_duration: screenTime.getFormattedScreenTime(),
    };
    trackleadform("Link_Click", linkClickTrackObj);
  };
  const handleButtonClick = (e) => {
    const trackBtnClickObj = {
      ...lmsPageviewTrackObj,
      Button_text: e.currentTarget.innerText,
      percent_scrolled: getScrollPositionByPercentage(),
      on_screen_duration: screenTime.getFormattedScreenTime(),
    };
    trackleadform("Button_Click", trackBtnClickObj);
  };
  const addEventListeners = () => {
    const allButtonElements = document.getElementsByTagName("button");
    const allLinkElements = document.getElementsByTagName("a");

    Array.from(allButtonElements).forEach((element) => {
      element.addEventListener("click", handleButtonClick);
    });
    Array.from(allLinkElements).forEach((element) => {
      element.addEventListener("click", handleLinkClick);
    });
  };
  const removeEventListeners = () => {
    const allButtonElements = document.getElementsByTagName("button");
    const allLinkElements = document.getElementsByTagName("a");

    Array.from(allButtonElements).forEach((element) => {
      element.removeEventListener("click", handleButtonClick);
    });
    Array.from(allLinkElements).forEach((element) => {
      element.removeEventListener("click", handleLinkClick);
    });
  };

  useEffect(() => {
    const observer = new MutationObserver(addEventListeners);
    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(document, observerOptions);

    return () => {
      removeEventListeners();
      observer.disconnect();
    };
  }, [lmsPageviewTrackObj]);

  useEffect(() => {
    if (isSuccess) {
      const currentTime = new Date().getTime();
      const cohortStartDate = getCohortStartDate(cohort?.timelines);
      const cohortStartTime = new Date(cohortStartDate).getTime();
      const isCohortStarted = currentTime > cohortStartTime;
      const lockedPages = [];
      const { isEvaluationDayStarted } = evaluationDayInfo || {};
      // TODO: acm005, BIM_C_003 and BIM_A_012, 13 needs to be added
      if (!WhiteListedPagesForPerformance.includes(cohortId) && isMentorDashboard) {
        lockedPages.push("performance");
        lockedPages.push("group-performance");
      }
      if (!isCohortStarted) {
        lockedPages.push("queries");
      }
      if (capstoneInfo?.isCapstoneTabDisabled) {
        lockedPages.push("capstone");
      }
      if (!isEvaluationDayStarted) {
        lockedPages.push("evaluation-day");
      }
      setLockedPages(lockedPages);
    }
  }, [isSuccess, capstoneInfo, evaluationDayInfo, cohort?.timelines, cohortId, isMentorDashboard]);

  return (
    <>
      <NavigationBar
        setGlobalSideBarToggle={setGlobalSideBarToggle}
        globalSideBarToggle={globalSideBarToggle}
        toggle
        // text={`${isMentorDashboard ? "Mentor" : "LMS"} / ${cohort?.title}`}
        mainText={`${isLeadMentorDashboard ? "LM" : isMentorDashboard ? "Mentor" : "LMS"}`}
        trailingText={`${cohort?.title || ""}`}
      />
      <Tour
        updateCoachmarks={updateCoachmarks}
        previousRoute={previousRoute}
        showCoachmarks={coachmarksData?.showCoachmarks}
        cohortId={cohortId}
        allowedRoutes={["dashboard"]}
      />
      <LayoutContainer>
        <Box display={{ _: "block", md: "flex" }}>
          {lockedPages && (
            <SideBarMain
              globalSideBarToggle={globalSideBarToggle}
              isMentorDashboard={isMentorDashboard}
              isLeadMentorDashboard={isLeadMentorDashboard}
              lockedPages={lockedPages}
            />
          )}
        </Box>
        {children}
      </LayoutContainer>
    </>
  );
};

export default LMSLayout;
