import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";

import Box from "../Box";

const overlayVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: 0.3,
      delayChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      duration: 0.3,
      delay: 0.2,
    },
  },
};

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  overflow: ${({ isOverFlow }: any) => (isOverFlow ? "auto" : "unset")};
  height: 100%;
  background: ${({ bgColor }: { bgColor?: string }) => bgColor || "rgba(0, 0, 0, 0.2)"};
  z-index: 1301;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled(Box)<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${({ bgColor }: { bgColor?: string }) => bgColor || "rgba(0, 0, 0, 0.2)"};
  z-index: ${({ isOpen }) => (isOpen ? 1301 : -1)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: 0.3s;
`;

const Modal = ({
  children,
  modalIsOpen,
  isOverFlow = false,
  bgColor,
  usedInSidedrawer,
}: {
  children: ReactNode;
  modalIsOpen: boolean;
  isOverFlow?: boolean;
  bgColor?: string;
  usedInSidedrawer?: boolean;
}) => {
  useEffect(() => {
    if (modalIsOpen) {
      const body: any = document?.getElementsByTagName("body")[0];
      body.style = "overflow: hidden";
    } else {
      const body: any = document?.getElementsByTagName("body")[0];
      body.style = "overflow: unset";
    }
  }, [modalIsOpen]);

  if (usedInSidedrawer) {
    return <ModalBox isOpen={modalIsOpen}>{children}</ModalBox>;
  }

  // useEffect(() => {
  //   if (modalIsOpen && document) {
  //     const body: any = document && document?.getElementsByTagName('body')[0]
  //     body.style = "overflow: hidden";
  //   }
  //   else {
  //     const body: any = document && document?.getElementsByTagName('body')[0]
  //     body.style = "overflow: unset";
  //   }

  // },[])

  return (
    <AnimatePresence>
      {modalIsOpen && (
        <ModalContainer
          initial="hidden"
          isOverFlow={isOverFlow}
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
          className="modal-overlay"
          bgColor={bgColor}
        >
          {children}
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

export default Modal;
