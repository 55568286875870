import { createSlice } from "@reduxjs/toolkit";

import { FORM_BUTTON_TYPE, QUESTION_CATEGORY } from "@/utils/types.enums";
import { getDigitsFromString } from "@/utils/utils";

import { QUESTION_TYPE, QuestionSchema } from "@/components/LMSV3/common/MCQForm/type";

interface ButtonType {
  text: string;
  type: FORM_BUTTON_TYPE;
  enabled: boolean;
}

enum BUTTON_TEXT {
  "Next" = "Next",
  "Previous" = "Previous",
  "Submit" = "Submit",
}

interface InitialStateTypes {
  questions: {
    [key: string]: QuestionSchema;
  };
  currentQuestionId: string;
  currentQuestionCategory: QUESTION_CATEGORY;
  primaryBtn: ButtonType;
  secondaryBtn: ButtonType;
  answers: {
    [key: string]: string[];
  };
  direction: -1 | 1;
  isSubmitted: boolean;
}

const initialState: InitialStateTypes = {
  questions: {},
  currentQuestionId: null,
  currentQuestionCategory: QUESTION_CATEGORY.TEXT_QUESTION,
  primaryBtn: {
    text: BUTTON_TEXT.Next,
    type: FORM_BUTTON_TYPE.NEXT,
    enabled: true,
  },
  secondaryBtn: {
    text: BUTTON_TEXT.Previous,
    type: FORM_BUTTON_TYPE.PREV,
    enabled: false,
  },
  answers: {},
  direction: 1,
  isSubmitted: false,
};

const handlePageChange = (state, currentQuestionId, updatedQuestionId) => {
  const { prevQuestionId, nextQuestionId } = state.questions[updatedQuestionId];
  if (prevQuestionId) {
    state.secondaryBtn.enabled = true;
  }
  if (nextQuestionId) {
    state.primaryBtn = initialState.primaryBtn;
  } else {
    state.primaryBtn.enabled = false;
  }
  if (getDigitsFromString(currentQuestionId) > getDigitsFromString(updatedQuestionId)) {
    state.secondaryBtn.enabled = Boolean(prevQuestionId);
    state.direction = -1;
  } else {
    if (!nextQuestionId && !state.isSubmitted) {
      const isAllQuestionAnswered =
        Object.keys(state.answers).length == Object.keys(state.questions).length;
      state.primaryBtn = {
        text: BUTTON_TEXT.Submit,
        type: FORM_BUTTON_TYPE.SUBMIT,
        enabled: isAllQuestionAnswered,
      };
    }
    state.direction = 1;
  }
  return state;
};

const formSlice = createSlice({
  name: "formSlice",
  initialState,
  reducers: {
    initializeQuestions(state, action) {
      const newQuestions = action.payload;
      state.questions = newQuestions;
      state.currentQuestionId = Object.keys(newQuestions)?.[0];
      state.answers = initialState.answers;
      state.isSubmitted = initialState.isSubmitted;
      state.currentQuestionCategory = initialState.currentQuestionCategory;
      state.primaryBtn = initialState.primaryBtn;
      state.direction = initialState.direction;
      state.secondaryBtn = initialState.secondaryBtn;
    },
    handleNextClick(state) {
      const questionId = state.currentQuestionId;
      const { nextQuestionId: newQuestionId } = state.questions[questionId];
      state.currentQuestionId = newQuestionId;
      state = handlePageChange(state, questionId, newQuestionId);
    },
    handlePageNumberClick(state, action) {
      const { index } = action.payload;
      const newQuestionId = Object.keys(state.questions)[index - 1];
      state = handlePageChange(state, state.currentQuestionId, newQuestionId);
      state.currentQuestionId = newQuestionId;
    },
    handlePrevClick(state) {
      const questionId = state.currentQuestionId;
      const { prevQuestionId: newQuestionId } = state.questions[questionId];
      state.currentQuestionId = newQuestionId;
      state = handlePageChange(state, questionId, newQuestionId);
    },
    handleAnswerClick(state, action) {
      const answerId = action.payload;
      const questionId = state.currentQuestionId;
      const { questionType, nextQuestionId } = state.questions[questionId];
      const newAnswers = JSON.parse(JSON.stringify(state.answers));
      const isSelected = newAnswers?.[questionId] ? answerId in newAnswers[questionId] : false;
      if (isSelected) {
        delete newAnswers?.[questionId]?.[answerId];
        if (!Object.keys(newAnswers?.[questionId] || {}).length) {
          delete newAnswers?.[questionId];
        }
      } else {
        if (questionType === QUESTION_TYPE.SINGLE_CHOICE) {
          newAnswers[questionId] = {
            [answerId]: true,
          };
        } else if (questionType === QUESTION_TYPE.MULTI_CHOICE) {
          newAnswers[questionId] = {
            ...newAnswers?.[questionId],
            [answerId]: true,
          };
        }
      }
      state.answers = newAnswers;
      const isAllQuestionAnswered =
        Object.keys(newAnswers).length == Object.keys(state.questions).length;
      state.primaryBtn.enabled = Boolean(nextQuestionId || isAllQuestionAnswered);
    },
    changeSubmitStatus(state, action) {
      state.isSubmitted = action.payload;
    },
    setCurrentQuestionCategory(state, action) {
      state.currentQuestionCategory = action.payload;
    },
    handlePrimaryBtnDisabled(state, action) {
      state.primaryBtn = { ...state.primaryBtn, ...action.payload };
    },
  },
});

export const {
  handleAnswerClick,
  handleNextClick,
  handlePrevClick,
  initializeQuestions,
  handlePageNumberClick,
  changeSubmitStatus,
  setCurrentQuestionCategory,
  handlePrimaryBtnDisabled,
} = formSlice.actions;
export default formSlice.reducer;
