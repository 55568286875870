import { ElementType, MouseEventHandler } from "react";
import styled, { CSSProperties } from "styled-components";
import {
  color,
  flex,
  FlexProps,
  LayoutProps,
  space,
  typography,
  TypographyProps,
} from "styled-system";
import { ColorProps, SpaceProps } from "styled-system";

import { ThemeBreakpoints } from "@/types";

export interface CustomTypographyProps
  extends TypographyProps,
    SpaceProps,
    ColorProps,
    FlexProps,
    LayoutProps {
  as?: ElementType;
  style?: CSSProperties;
  children?: React.ReactNode;
  mdVarient?: TypographyVarient;
  mdWeightVarient?: TypographyWeightVarient;
  className?: string;
  varient?: TypographyVarient;
  weightVarient?: TypographyWeightVarient;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  navText?: string;
}

export type TypographyWeightVarient = "regular" | "medium" | "semibold" | "bold";

export type TypographyVarient =
  | "displayxl"
  | "displaylg"
  | "displaymd"
  | "displaysm"
  | "displayxs"
  | "textmd"
  | "textsm"
  | "textxs"
  | "labellg"
  | "labelmd"
  | "labelsm"
  | "bodylg"
  | "bodymd"
  | "bodysm";

const typographyObj = {
  variant: {
    displayxl: "2.25rem",
    displaylg: "1.875rem",
    displaymd: "1.5rem",
    displaysm: "1.25rem",
    displayxs: "1rem",
    textmd: "0.875rem",
    textsm: "0.75rem",
    textxs: "0.625rem",
    labellg: "1rem",
    labelmd: "0.875rem",
    labelsm: "0.75rem",
    bodylg: "1rem",
    bodymd: "0.875rem",
    bodysm: "0.75rem",
  },
  lineHeight: {
    displayxl: "2.75rem",
    displaylg: "2.5rem",
    displaymd: "2rem",
    displaysm: "1.5rem",
    displayxs: "1.5rem",
    textmd: "1.25rem",
    textsm: "1rem",
    textxs: "0.75rem",
    labellg: "1.25rem",
    labelmd: "1.25rem",
    labelsm: "1rem",
    bodylg: "1.5rem",
    bodymd: "1.25rem",
    bodysm: "1rem",
  },
  font: {
    displayxl: "Figtree",
    displaylg: "Figtree",
    displaymd: "Figtree",
    displaysm: "Figtree",
    displayxs: "Figtree",
    textmd: "Figtree",
    textsm: "Figtree",
    textxs: "Figtree",
    labellg: "Figtree",
    labelmd: "Figtree",
    labelsm: "Figtree",
    bodylg: "Inter",
    bodymd: "Inter",
    bodysm: "Inter",
  },
  weightVariant: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  letterSpacing: {
    displayxl: "-0.02em",
    displaylg: "-0.02em",
    displaymd: "-0.02em",
    displaysm: "0em",
    displayxs: "0em",
    textmd: "0em",
    textsm: "0em",
    textxs: "0em",
    labellg: "0.08em",
    labelmd: "0.08em",
    labelsm: "0.08em",
    bodylg: "0em",
    bodymd: "0em",
    bodysm: "0em",
  },
};

const typographySMObj = {
  variant: {
    displayxl: "2rem",
    displaylg: "1.5rem",
    displaymd: "1.375rem",
    displaysm: "1.25rem",
    displayxs: "1rem",
    textmd: "0.875rem",
    textsm: "0.75rem",
    textxs: "0.625rem",
    labellg: "1rem",
    labelmd: "0.875rem",
    labelsm: "0.75rem",
    bodylg: "1rem",
    bodymd: "0.875rem",
    bodysm: "0.75rem",
  },
  lineHeight: {
    displayxl: "2.5rem",
    displaylg: "2rem",
    displaymd: "1.75rem",
    displaysm: "1.5rem",
    displayxs: "1.5rem",
    textmd: "1.25rem",
    textsm: "1rem",
    textxs: "0.75rem",
    labellg: "1.25rem",
    labelmd: "1.25rem",
    labelsm: "1rem",
    bodylg: "1.5rem",
    bodymd: "1.25rem",
    bodysm: "1rem",
  },
  font: {
    displayxl: "Figtree",
    displaylg: "Figtree",
    displaymd: "Figtree",
    displaysm: "Figtree",
    displayxs: "Figtree",
    textmd: "Figtree",
    textsm: "Figtree",
    textxs: "Figtree",
    labellg: "Figtree",
    labelmd: "Figtree",
    labelsm: "Figtree",
    bodylg: "Inter",
    bodymd: "Inter",
    bodysm: "Inter",
  },
  weightVariant: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  letterSpacing: {
    displayxl: "-0.02em",
    displaylg: "-0.02em",
    displaymd: "-0.02em",
    displaysm: "0em",
    displayxs: "0em",
    textmd: "0em",
    textsm: "0em",
    textxs: "0em",
    labellg: "0.08em",
    labelmd: "0.08em",
    labelsm: "0.08em",
    bodylg: "0em",
    bodymd: "0em",
    bodysm: "0em",
  },
};

const typeVariant = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyObj.variant[variant] || "1rem";
  }
  return typographySMObj.variant[variant] || "1rem";
};

const typeLineHeight = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyObj.lineHeight[variant] || "1rem";
  }
  return typographySMObj.lineHeight[variant] || "1rem";
};

const typeFont = (variant: TypographyVarient) => {
  return typographyObj.font[variant] || "Figtree";
};

const typeWeightVariant = (weightVariant: TypographyWeightVarient) => {
  return typographyObj.weightVariant[weightVariant] || 400;
};

const typeLetterSpacing = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyObj.letterSpacing[variant] || "0em";
  }
  return typographySMObj.letterSpacing[variant] || "0em";
};

export const StyledDynamicTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient as TypographyVarient)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient as TypographyWeightVarient)};
  font-family: ${(props) => typeFont(props.varient as TypographyVarient)};
  line-height: ${(props) => typeLineHeight(props.varient as TypographyVarient)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient as TypographyVarient)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.varient as TypographyVarient, ThemeBreakpoints.md)};
    line-height: ${(props) =>
    typeLineHeight(props.varient as TypographyVarient, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
    typeLetterSpacing(props.varient as TypographyVarient, ThemeBreakpoints.md)};
  }
`;

export const StyledDynamicBreakpointTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient as TypographyVarient)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient as TypographyWeightVarient)};
  font-family: ${(props) => typeFont(props.varient as TypographyVarient)};
  line-height: ${(props) => typeLineHeight(props.varient as TypographyVarient)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient as TypographyVarient)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.mdVarient as TypographyVarient)};
    font-weight: ${(props) => typeWeightVariant(props.mdWeightVarient as TypographyWeightVarient)};
    line-height: ${(props) => typeLineHeight(props.mdVarient as TypographyVarient)};
    letter-spacing: ${(props) => typeLetterSpacing(props.mdVarient as TypographyVarient)};

    font-size: ${(props) => typeVariant(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
    line-height: ${(props) =>
    typeLineHeight(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
    typeLetterSpacing(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
  }
`;
