import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { getCourseContent } from "@/client/lmsApiClient";
import { CohortUserDataInfo, InternalCohortType } from "@/client/type";
import { getUserData } from "@/client/userDataClient";

import { DateTypes } from "@/utils/types.enums";
import { getDateByType } from "@/utils/utils";

import { COHORT_USER_STATE } from "@/components/LMSV3/Profile/types";

interface T {
  cohortData: InternalCohortType | null;
  capstoneInfo: {
    capstoneStartDate: string;
    isCapstoneStarted: boolean;
    isCapstonePresent: boolean;
    isCapstoneTabDisabled: boolean;
    isCapstoneOnboardingStarted: boolean;
  } | null;
  careerInfo: {
    isCareerStarted: boolean;
    isCareerPresent: boolean;
  } | null;
  aiBotEnabled: boolean | null;
  evaluationDayInfo: {
    // evaluationDayStartDate: string;
    // evaluationDayEndDate: string;
    isEvaluationDayStarted: boolean;
    isEvaluationDayCommingSoon: boolean;
  };
  cohortUserDataInfo: CohortUserDataInfo | null;
}

const initialState: T = {
  cohortData: null,
  capstoneInfo: null,
  careerInfo: null,
  aiBotEnabled: null,
  evaluationDayInfo: null,
  cohortUserDataInfo: null,
};

const cohortReducer = createSlice({
  name: "cohortReducer",
  initialState,
  reducers: {
    setCohort(state, action) {
      state.cohortData = action.payload;
    },
    setCapstoneInfo(state, action) {
      state.capstoneInfo = action.payload;
    },
    setCareerInfo(state, action) {
      state.careerInfo = action.payload;
    },
    setCohortUserDataInfo: (state, action) => {
      const { payload } = action;
      state.cohortUserDataInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getCourseContent.endpoints.getCohort.matchFulfilled, (state, action) => {
      const { tags, timelines, courseType } = action.payload;

      const today = new Date();

      // set ai-enabled info
      state.aiBotEnabled = action.payload.tags.includes("ai-enabled");

      // set career info
      const careerStartDate = getDateByType(timelines, DateTypes.CAREER_START_DATE);
      const isNewLms = tags.includes("new-lms4");
      const isCivilLms4Cohort = tags.includes("civil-lms4");
      state.careerInfo = {
        isCareerPresent: isNewLms || isCivilLms4Cohort,
        isCareerStarted: new Date(careerStartDate) < today,
      };
      // set cohort data
      state.cohortData = { ...action.payload, isNewLms, isCivilLms4Cohort };

      // set capstone info
      const capstoneStartDate = getDateByType(timelines, DateTypes.CAPSTONE_START_DATE);
      const capstoneOnboard = getDateByType(timelines, DateTypes.CAPSTONE_ONBOARDING_DATE);
      const isCapstoneTabDisabled = capstoneOnboard
        ? dayjs().isBefore(dayjs(capstoneOnboard).subtract(15, "day"))
        : true;
      const isCapstoneOnboardingStarted = dayjs().isAfter(dayjs(capstoneOnboard));
      const isCapstoneStarted = new Date(capstoneStartDate) < today;
      const isCapstonePresent = courseType !== "MCD";

      state.capstoneInfo = {
        capstoneStartDate,
        isCapstoneStarted,
        isCapstonePresent,
        isCapstoneTabDisabled,
        isCapstoneOnboardingStarted,
      };

      //  Evaluation day info
      const evaluationDayStartDate = getDateByType(timelines, DateTypes.REVIEW_DAY_START_DATE);
      const isEvaluationDayStarted = new Date(evaluationDayStartDate) < today;

      const isEvaluationDayCommingSoon = new Date(evaluationDayStartDate) >= today;
      state.evaluationDayInfo = {
        // evaluationDayStartDate,
        // evaluationDayEndDate,
        isEvaluationDayStarted,
        isEvaluationDayCommingSoon,
      };
    });
    builder.addMatcher(getUserData.endpoints.getUserCohortData.matchFulfilled, (state, action) => {
      const userCohortData = action.payload;
      const cohorts = userCohortData?.filter((cohort) => {
        const state = cohort?.cohortUserData?.state;
        return state !== COHORT_USER_STATE.TRANSFERRED && state !== COHORT_USER_STATE.CANCELLED;
      });
      state.cohortUserDataInfo = cohorts;
    });
  },
});

export const { setCohort, setCapstoneInfo, setCareerInfo, setCohortUserDataInfo } =
  cohortReducer.actions;
export default cohortReducer.reducer;
