import { motion } from "framer-motion";
import { ReactNode } from "react";

const SizeShiftAnimation = ({
  children,
  style,
  duration = 0.2,
}: {
  children: ReactNode;
  style?: React.CSSProperties;
  duration?: number;
}) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      style={{ ...style }}
      transition={{ ease: "easeIn", duration: duration }}
      variants={{
        visible: { opacity: 1, scale: 1, x: "0px" },
        hidden: { opacity: 0, scale: 1, x: "-12px" },
      }}
      viewport={{ amount: 0.4 }}
    >
      {children}
    </motion.div>
  );
};

export default SizeShiftAnimation;
