import { FC } from "react";

import { theme } from "@/theme";

import {
  CustomTypographyProps,
  StyledDynamicBreakpointTypography,
  StyledDynamicTypography,
} from "./typography.styles";

// default is bodys
const Typography: FC<CustomTypographyProps> = ({
  children,
  mdVarient,
  mdWeightVarient,
  varient,
  weightVarient,
  className,
  color,
  ...rest
}) => {
  if (mdVarient) {
    return (
      <StyledDynamicBreakpointTypography
        className={className}
        varient={varient}
        weightVarient={weightVarient}
        mdVarient={mdVarient}
        mdWeightVarient={mdWeightVarient}
        color={color || theme.colors.gray[900]}
        {...rest}
      >
        {children}
      </StyledDynamicBreakpointTypography>
    );
  }
  return (
    <StyledDynamicTypography
      className={className}
      varient={varient}
      weightVarient={weightVarient}
      color={color || theme.colors.gray[900]}
      {...rest}
    >
      {children}
    </StyledDynamicTypography>
  );
};

export default Typography;
