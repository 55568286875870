import Typography from "@/design-system-v2/Typography";

import { theme } from "@/theme";

export const globalNavBar = (aiEnabled) => [
  {
    id: "1",
    image: "pie-chart-04",
    text: "Dashboard",
    link: "dashboard",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Dashboard
      </Typography>
    ),
  },
  {
    id: "2",
    image: "book-open-01",
    text: "Curriculum",
    link: "curriculum",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Curriculum
      </Typography>
    ),
  },
  {
    id: "3",
    image: "building-07",
    text: "Capstone",
    link: "capstone",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
          Capstone
        </Typography>
      </>
    ),
    toolTipLockedOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold">
          Capstone
        </Typography>
        <Typography varient="textsm" weightVarient="regular">
          Coming soon!
        </Typography>
      </>
    ),
    // toolTipOnHover: (
    //   <Typography
    //     varient="textsm"
    //     weightVarient="semibold"
    //     color={theme.colors.base.white}
    //   >
    //     Capstone
    //   </Typography>
    // ),
  },
  {
    id: "4",
    image: "message-text-square-02",
    text: "Queries",
    locked: false,
    link: aiEnabled ? "queries-v2" : "queries",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Queries
      </Typography>
    ),
  },
  {
    id: "5",
    image: "trend-up-01",
    text: "Skill Progress",
    locked: false,
    link: "skill-progress",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Skill Progress
      </Typography>
    ),
  },
  {
    id: "6",
    image: "chart-breakout-circle",
    text: "My Performance",
    locked: false,
    link: "performance",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Performance
      </Typography>
    ),
  },
  {
    id: "7",
    image: "file-check-03",
    text: "Task Submissions",
    locked: false,
    link: "tasks",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
          Task Submissions
        </Typography>
      </>
    ),
  },
  {
    id: "8",
    image: "trophy-01",
    text: "Evaluation Day",
    locked: false,
    link: "evaluation-day",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
          Evaluation Day
        </Typography>
      </>
    ),
    toolTipLockedOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold">
          Evaluation Day
        </Typography>
        <Typography varient="textsm" weightVarient="regular">
          Coming soon!
        </Typography>
      </>
    ),
  },
  // {
  //   id: "8",
  //   image: "folder-lock",
  //   // if this is changed, fix this in GlobalNavBar.tsx too
  //   text: "Capstone",
  //   locked: true,
  //   lockedIcon: "lock-02",
  //   toolTipOnHover: (
  //     <>
  //       <Typography varient="textsm" weightVarient="semibold">
  //         Capstone
  //       </Typography>
  //       {/* <Typography varient="textsm" weightVarient="regular">
  //         Unlocks on: 7 Jan 2024
  //       </Typography> */}
  //       <Typography varient="textsm" weightVarient="regular">
  //         Coming soon!
  //       </Typography>
  //     </>
  //   ),
  // },
  // {
  //   id: "7",
  //   image: "wallet-02",
  //   text: "Earnings",
  //   locked: true,
  //   lockedIcon: "",
  // },
  // {
  //   id: "9",
  //   image: "users-02",
  //   text: "My Group",
  //   locked: true,
  //   lockedIcon: "",
  // },
];

export const mentorGlobalNavBar = [
  {
    id: "1",
    image: "pie-chart-04",
    text: "Dashboard",
    link: "dashboard",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Dashboard
      </Typography>
    ),
  },
  {
    id: "2",
    image: "book-open-01",
    text: "Curriculum",
    link: "curriculum",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Curriculum
      </Typography>
    ),
  },
  {
    id: "3",
    image: "building-07",
    text: "Capstone",
    link: "capstone",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Capstone
      </Typography>
    ),
  },
  {
    id: "5",
    image: "message-text-square-02",
    text: "Queries",
    locked: false,
    link: "queries",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Queries
      </Typography>
    ),
  },
  //enable once evaluation day live for mentor
  {
    id: "8",
    image: "trophy-01",
    text: "Evaluation Day",
    locked: false,
    link: "evaluation-day",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
          Evaluation Day
        </Typography>
      </>
    ),
    toolTipLockedOnHover: (
      <>
        <Typography varient="textsm" weightVarient="semibold">
          Evaluation Day
        </Typography>
        <Typography varient="textsm" weightVarient="regular">
          Coming soon!
        </Typography>
      </>
    ),
  },
  {
    id: "4",
    image: "file-check-03",
    link: "task-assessment",
    text: "Task Assessment",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Task Assessment
      </Typography>
    ),
  },
  {
    id: "8",
    image: "chart-breakout-circle",
    text: "My Performance",
    locked: false,
    link: "my-performance",
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        My Performance
      </Typography>
    ),
  },
  {
    id: "9",
    image: "users-02",
    text: "Group Performance",
    lockedIcon: "lock-02",
    link: "group-performance",
    locked: false,
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Group Performance
      </Typography>
    ),
  },
  // {
  //   id: "3",
  //   image: "folder-lock",
  //   // if this is changed, fix this in GlobalNavBar.tsx too
  //   text: "Capstone",
  //   locked: true,
  //   lockedIcon: "lock-02",
  //   toolTipOnHover: (
  //     <>
  //       <Typography varient="textsm" weightVarient="semibold">
  //         Capstone
  //       </Typography>
  //       {/* <Typography varient="textsm" weightVarient="regular">
  //         Unlocks on: 7 Jan 2024
  //       </Typography> */}
  //       <Typography varient="textsm" weightVarient="regular">
  //         Coming soon!
  //       </Typography>
  //     </>
  //   ),
  // },
  // {
  //   id: "7",
  //   image: "wallet-02",
  //   text: "Earnings",
  //   locked: true,
  //   lockedIcon: "",
  // },
];

export const leadMentorGlobalNavBar = [
  {
    id: "1",
    image: "pie-chart-04",
    text: "Dashboard",
    link: "dashboard",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Dashboard
      </Typography>
    ),
  },
  {
    id: "2",
    image: "book-open-01",
    text: "Curriculum",
    link: "curriculum",
    locked: false,
    lockedIcon: "lock-02",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold" color={theme.colors.base.white}>
        Curriculum
      </Typography>
    ),
  },
];

export const channelsData = [
  {
    id: "1",
    image: "announcement-02",
    text: "Announcements",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold">
        Announcements
      </Typography>
    ),
  },
  {
    id: "2",
    image: "message-smile-circle",
    text: "BIM_C9_CHT07",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold">
        BIM_C9_CHT07
      </Typography>
    ),
  },
];

export const supportAndSettingData = [
  {
    id: "1",
    image: "life-buoy-02",
    text: "Support",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold">
        Support
      </Typography>
    ),
  },
  {
    id: "2",
    image: "settings-01",
    text: "Settings",
    toolTipOnHover: (
      <Typography varient="textsm" weightVarient="semibold">
        Settings
      </Typography>
    ),
  },
];
