import React from "react";

import { StyledTextArea } from "../StyleComponent";

export default function TextField({ handleChange, questionObj }) {
  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(questionObj, event.target.value);
  };
  return (
    <StyledTextArea
      onChange={handleTextAreaChange}
      placeholder={"How can we improve"}
      rows={10}
      cols={40}
    />
  );
}
