import { ReactNode, useState } from "react";
import styled from "styled-components";

import FlexContainer from "@/design-system/FlexContainer";

import Sidebar from "@/components/LearningHub/SideBar";

import "react-loading-skeleton/dist/skeleton.css";
import NavigationBar from "../NavigationBar";

const LayoutContainer = styled(FlexContainer)`
  background-color: #f9f9fb;
  min-height: 100vh;
`;

interface LayoutProps {
  children?: ReactNode;
  text: string;
  sideBar?: boolean;
  usedInMentorhub?: boolean;
  usedInLearnerHub?: boolean;
  showReferLink?: boolean;
  placementHubCohortId?: string;
}

const LearnerHubLayout = ({
  children,
  sideBar,
  text,
  usedInMentorhub = false,
  usedInLearnerHub = false,
  showReferLink,
  placementHubCohortId,
}: LayoutProps) => {
  const [globalSideBarToggle, setGlobalSideBarToggle] = useState(true);

  return (
    <>
      <NavigationBar
        setGlobalSideBarToggle={setGlobalSideBarToggle}
        globalSideBarToggle={globalSideBarToggle}
        text={text}
      />
      <LayoutContainer>
        {children}
        {sideBar && (
          <Sidebar
            usedInLearnerHub={usedInLearnerHub}
            usedInMentorhub={usedInMentorhub}
            placementHubCohortId={placementHubCohortId}
            showReferBanner={showReferLink}
          />
        )}
      </LayoutContainer>
    </>
  );
};

export default LearnerHubLayout;
