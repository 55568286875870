const CoursesDynamicData = [
  {
    cohortId: "BIM_A_017",
    courseId: "BIM",
    upcomingCohortTitle: "3rd August, 2024",
    upcomingCohortDuration: "9 Months, online (10-12 hours/week)",
    periodicity: 7,
  },
  {
    cohortId: "BIM_A_017",
    courseId: "BIM_US",
    upcomingCohortTitle: "3rd August, 2024",
    upcomingCohortDuration: "7 Months - online",
    periodicity: 7,
  },
  {
    cohortId: "acm007",
    courseId: "acm",
    upcomingCohortTitle: "20th July, 2024",
    upcomingCohortDuration: "6 Months, online (10-12 hours/week)",
  },
  {
    cohortId: "acm_US_001",
    courseId: "acm_us",
    upcomingCohortTitle: "20th July, 2024",
    upcomingCohortDuration: "6 Months - online",
  },
  {
    cohortId: "BIM_C_007",
    courseId: "BIM_C",
    upcomingCohortTitle: "27th July, 2024",
    upcomingCohortDuration: "7 Months, online (10-12 hours/week)",
  },
  {
    cohortId: "BIM_A_US_001",
    courseId: "BIM_C_US",
    upcomingCohortDuration: "6 Months - online",
  },
  {
    cohortId: "INT_DES_001",
    courseId: "INT_DES",
    upcomingCohortDuration: "6 Months - Online",
  },
];

export const freeCourseData = [
  {
    title: "BIM Professional Course for Architects 2.0",
    nextCohort: "BIM_A",
    timing: "9 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/horizontal-BIMA.svg`,
    link: "/courses/building-information-modelling",
  },
  {
    title: "Master Computational Design Course",
    nextCohort: "MCD",
    timing: "10 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-MCDC.svg`,
    link: "/courses/computational-design",
  },
  {
    title: "BIM Professional Course for Civil Engineers",
    nextCohort: "BIM_C",
    timing: "8 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_C.svg`,
    link: "/courses/bim-for-civil-engineers",
  },
];

export const freeCourseRowData = [
  {
    title: "BIM Professional Course for Architects 2.0",
    nextCohort: "BIM_A_US",
    timing: "9 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_A.svg`,
    link: "/courses/building-information-modelling",
  },
  {
    title: "Master Computational Design Course",
    nextCohort: "MCD_US",
    timing: "6 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-MCDC.svg`,
    link: "/courses/parametric-design",
  },
  {
    title: "BIM Professional Course for Civil Engineers",
    nextCohort: "BIM_C_US",
    timing: "6 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_C.svg`,
    link: "/courses/bim-for-civil-engineers",
  },
];

export const freeCourseMenaData = [
  {
    title: "BIM Professional Course for Architects 2.0",
    nextCohort: "BIM_A_ME",
    timing: "9 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_A.svg`,
    link: "/courses/building-information-modelling",
  },
  {
    title: "Master Computational Design Course",
    nextCohort: "MCD_ME",
    timing: "6 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-MCDC.svg`,
    link: "/courses/parametric-design",
  },
  {
    title: "BIM Professional Course for Civil Engineers",
    nextCohort: "BIM_C_ME",
    timing: "6 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_C.svg`,
    link: "/courses/bim-for-civil-engineers",
  },
];

export default CoursesDynamicData;
