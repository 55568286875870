import axios from "axios";
import Cookies from "js-cookie";

const OneistoxAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROXY_2,
});
OneistoxAPI.defaults.headers.common["Content-Type"] = "application/json";

OneistoxAPI.defaults.headers.common["x-auth-token"] = Cookies.get("token");

if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
  OneistoxAPI.defaults.headers.common["x-auth-override"] =
    process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER;
}

const handleFormSubmitData = async (formData: any) => {
  try {
    const response = await OneistoxAPI.put("v1/client/user/profile", formData, {
      headers: {
        Authorization: `${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};

const getUserProfileData = async (token: any) => {
  try {
    const response = await OneistoxAPI.get(`v1/client/public/user/profile`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile data:", error);
    return null;
  }
};

const sendEmailVerification = async (token: any) => {
  try {
    const response = await OneistoxAPI.post("v1/client/user/sendEmailVerification", {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending email verification:", error);
  }
};

export { getUserProfileData, handleFormSubmitData, sendEmailVerification };

export const OneistoxAPIProfile = OneistoxAPI;
