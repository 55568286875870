import styled, { css } from "styled-components";
import { border, color, flexbox, grid, layout, shadow, space } from "styled-system";

import { GridColumnProps } from "./types";

export const StyledGridColumn = styled.div<GridColumnProps>`
  ${layout};
  ${space}
  ${flexbox}
  ${grid};
  ${color};
  ${border}
  ${shadow}
  grid-column: ${({ xs }) => xs || "span 12"};
  ${({ sm }) =>
    sm &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        grid-column: ${sm};
      }
    `}

  ${({ md }) =>
    md &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-column: ${md};
      }
    `}

    ${({ lg }) =>
    lg &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-column: ${lg};
      }
    `}

    ${({ xxl }) =>
    xxl &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
        grid-column: ${xxl};
      }
    `}
`;
