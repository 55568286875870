import { useState } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";

import Typography from "@/design-system-v2/Typography";

import { theme } from "@/theme";

const StyledTextArea = styled.textarea`
  display: flex;
  min-height: 64px;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: ${theme.radius(2)};
  border: 1px solid var(--gray-cool-200, #dcdfea);
  background: var(--white, #fff);
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-family: inherit;

  &::placeholder {
    color: ${theme.colors.gray[400]};
  }
`;

const CharacterCount = styled(Box)<{ remaining: number }>`
  margin-top: 4px;
  text-align: right;
  color: ${({ remaining }) => (remaining < 0 ? "red" : "inherit")};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  padding: 0px 8px;
  color: ${theme.colors.gray[500]};
`;

const TextareaLabel = styled(Box)`
  font-size: 10px;
  line-height: 12px;
  font-size: 10px;
  line-height: 12px;
  z-index: 2;
  position: absolute;
  left: 8px;
  top: 0px;
  transform: translateY(-50%);
  background: ${theme.colors.base.white};
`;

const TextArea = ({
  label,
  name,
  onChange,
  value,
  placeholder,
  fullWidth = false,
  maxLength = 140,
  required = false,
  rows = 4,
  cols = 60,
}: any) => {
  const [text, setText] = useState(value || "");
  const remainingCharacters = maxLength - text.length;

  return (
    <Box width={fullWidth ? "100%" : "unset"}>
      {label && (
        <TextareaLabel
          px={theme.spacing(1)}
          color={theme.colors.gray[500]}
          display="flex"
          gap={theme.spacing(1)}
        >
          {label} {required ? <Typography color={theme.colors.error[500]}>*</Typography> : null}
        </TextareaLabel>
      )}
      <StyledTextArea
        name={name}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        value={value}
        onChange={(e) => {
          setText(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        required={required}
        placeholder={placeholder}
      />
      <CharacterCount remaining={remainingCharacters}>
        {maxLength - remainingCharacters}/{maxLength}
      </CharacterCount>
    </Box>
  );
};

export default TextArea;
