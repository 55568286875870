import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";

import FlexContainer from "@/design-system-v2/FlexContainer";

import Typography from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { PreviousRouteProvider } from "@/pages/_app";

import PrimaryButton from "@/design-system-supernova/Button/PrimaryButton";

import { theme } from "@/theme";

const ReferBanner: React.FC = () => {
  const router = useRouter();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const placementData = useAppSelector((state) => state.rootReducer.placementhub);
  const trackObj: any = placementData?.trackObj;

  const handleClick = useCallback(() => {
    if (trackObj?.user_id) {
      const trackBtnClickObj = {
        Page_path: window.location.pathname,
        Page_Referrer: previousRoute,
        Button_text: "Learn More -> Refer_&_Earn",
        user_id: trackObj?.user_id,
        email_id: trackObj?.email,
        cohort_id: trackObj?.cohortid,
      };
      trackleadform("Button_Click", trackBtnClickObj);
    }

    router.push("/referral-program");
  }, [trackObj, trackleadform, previousRoute, router]);

  return (
    <OuterContainer>
      <Container>
        <Star>
          <ExportedImage
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/refer-banner/rewards-star.svg`}
            width={38}
            height={38}
            sizes=""
            alt="star"
          />
        </Star>
        <Gift>
          <ExportedImage
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/refer-banner/rewards-gift.png`}
            width={87}
            height={87}
            alt={"gift"}
          />
        </Gift>
        <Text style={{ color: "white" }}>
          Refer <br />
          Friends.
        </Text>
        <Text style={{ color: theme.colors.warning[400] }}>
          Earn <br />
          Rewards
        </Text>
        <Typography
          varient="bodys"
          weightVarient="medium"
          color={theme.colors.purple[200]}
          marginTop={theme.spacing(1)}
        >
          Earn when your friends enroll in their first course
        </Typography>

        <PrimaryButton
          size="sm"
          mt={{ xs: "20px", md: "36px" }}
          shape="rounded"
          suffixIcon={{ type: "arrow-narrow-right", color: "#000", variant: "line" }}
          buttonType="primaryDark"
          label="Learn More"
          onClick={handleClick}
        />
      </Container>
    </OuterContainer>
  );
};

export default ReferBanner;

const Container = styled(FlexContainer)`
  flex-direction: column;
  border-radius: ${theme.radius(3)};
  background: linear-gradient(45deg, #4300b1 0%, #a531dc 100%);
  width: 100%;
  padding-inline: ${theme.spacing(4)};
  padding-block: ${theme.spacing(5)};
  background-image: url(${process.env
    .NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/refer-banner/banner-background-mobile.png);
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 300px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 32px;
    min-width: 246px;
    max-width: 246px;
    background-image: url(${process.env
    .NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/refer-banner/banner-background.png);
  }
`;

const OuterContainer = styled(Box)`
  padding: 3px;
  background: linear-gradient(0deg, #480b9d 0%, #9b23ea 100%);
  border-radius: ${theme.radius(3)};
  width: 100%;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: max-content;
    max-width: max-content;
  }
`;

const Text = styled.span`
  text-transform: uppercase;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.56px;
`;

const Star = styled(Box)`
  position: absolute;
  z-index: 2;
  animation: 0.5s rotating infinite;
  right: -22px;
  top: 120px;

  @keyframes rotating {
    from {
      transform: rotate(-90deg);
    }
    66%,
    100% {
      transform: rotate(0deg);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    right: -22px;
    top: 92px;
  }
`;

const Gift = styled(Box)`
  position: absolute;
  z-index: 2;
  right: 4px;
  top: -30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    right: 4px;
    top: -40px;
  }
`;
