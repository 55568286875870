import styled, { css } from "styled-components";
import { layout, space } from "styled-system";

import Button from "@/design-system-v2/Button";

import { PrimaryButtonProps } from "./types";

export const PrimaryStyledButton = styled(Button)<PrimaryButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "default" &&
    css`
      background-color: ${({ theme }) => theme.colors.purple[700]};
      color: ${({ theme }) => theme.colors.base.white};
      &:hover {
        background-color: ${({ theme }) => theme.colors.purple[800]};
        box-shadow:
          0px 16px 10px rgba(0, 0, 0, 0.02),
          0px 7px 7px rgba(0, 0, 0, 0.03),
          0px 2px 4px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03);
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.purple[900]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.purple[300]};
      }
    `}

  ${({ color }) =>
    color === "neutral" &&
    css`
      background-color: ${({ theme }) => theme.colors.gray[800]};
      color: ${({ theme }) => theme.colors.base.white};

      &:hover {
        background-color: ${({ theme }) => theme.colors.base.black};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.gray[900]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[300]};
        opacity: 0.4;
      }
    `}

    ${({ color }) =>
    color === "destructive" &&
    css`
      color: ${({ theme }) => theme.colors.base.white};
      background-color: ${({ theme }) => theme.colors.error[600]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.error[700]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.error[800]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.error[300]};
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
