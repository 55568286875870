import { ReactNode } from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";

import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v2/Typography";

import { theme } from "@/theme";

const IconWrapper = styled(UnstyledButton)`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.grayCool[200]};
`;

interface ProfileHeaderProps {
  text: string;
  icon?: ReactNode;
  iconOnClick?: () => void;
  bgColor?: string;
  hasBorder?: boolean;
  hasBorderRadius?: boolean;
  ProfileHeading?: ReactNode;
}

const ProfileHeader = ({
  text,
  icon,
  ProfileHeading,
  iconOnClick,
  hasBorderRadius = true,
  bgColor = theme.colors.purple[25],
  hasBorder = false,
}: ProfileHeaderProps) => {
  const handleIconClick = () => {
    if (iconOnClick) {
      iconOnClick();
    }
  };

  return (
    <Box id={"header_element"}>
      <FlexContainer
        py={theme.spacing(4)}
        px={{
          _: theme.spacing(3),
          md: theme.spacing(10),
        }}
        background={bgColor}
        justifyContent={"space-between"}
        borderRadius={hasBorderRadius ? `${theme.radius(4)} ${theme.radius(4)} 0 0` : "unset"}
        alignItems={"center"}
        border={hasBorder ? `1px solid ${theme.colors.purple[200]}` : `none`}
        borderBottom={"none"}
      >
        {ProfileHeading}
        <Typography varient="displayxs" weightVarient="medium" color={theme.colors.gray[900]}>
          {text}
        </Typography>
        {icon && <IconWrapper onClick={handleIconClick}>{icon}</IconWrapper>}
      </FlexContainer>
    </Box>
  );
};

export default ProfileHeader;
