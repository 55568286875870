import { appBaseCreateApi } from "./BaseQueryApiClient";

export const getMMSCourseContent = appBaseCreateApi({
  reducerPath: "getMMSCourseContent",
  endpoints: (builder) => ({
    getLMCohort: builder.query({
      query: (cohortId) => `/v1/client/mentor/${cohortId}/cohort-data`,
      providesTags: ["NODE_DATA"],
    }),
    markCheckList: builder.mutation({
      query(payload) {
        return {
          url: `v1/client/mentor/lm/${payload.courseId}/pre-session-checklist/complete`,
          method: "PUT",
          body: payload.body,
        };
      },
      invalidatesTags: ["NODE_DATA"],
    }),
    getLMStudioList: builder.query({
      query: ({ cohortId, namespace }) =>
        `/v1/client/mentor/lm/${cohortId}/cohort-node-data/index?namespace=${namespace}`,
    }),
    getLMTopicList: builder.query({
      query: ({ cohortId, nodeId, namespace }) =>
        `/v1/client/mentor/lm/${cohortId}/cohort-node-data?namespace=${namespace}&nodeId=${nodeId}&depth=4`,
      providesTags: ["NODE_DATA"],
    }),
  }),
});

export const {
  useGetLMStudioListQuery,
  useGetLMTopicListQuery,
  useGetLMCohortQuery,
  useMarkCheckListMutation,
} = getMMSCourseContent;
