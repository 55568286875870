import { appBaseCreateApi } from "./BaseQueryApiClient";

export const getIGEarning = appBaseCreateApi({
  reducerPath: "getIGEarning",
  endpoints: (builder) => ({
    getMonthlyTrends: builder.query({
      query: (params) => {
        return `/v2/client/mentor/earning/monthly-trends?startDate=${params.startDate}`;
      },
    }),
    getEarningStats: builder.query({
      query: (userType) => {
        const Url = userType
          ? `v1/client/mentor/lm/earning/stats`
          : `v2/client/mentor/earning/stats`;
        return Url;
      },
    }),
    getMonthlyBreakup: builder.query({
      query: (params) => {
        const Url = params.userType
          ? `v1/client/mentor/lm/earning/monthly-breakup?currentMonth=${params.currentMonth}`
          : `v2/client/mentor/earning/monthly-breakup?currentMonth=${params.currentMonth}`;
        return Url;
      },
    }),
    // NeedClarity sortDir   sort By -> Date Amount
    getMentorInvoices: builder.query({
      query: (params) => {
        const Url = params.userType
          ? `v1/client/mentor/lm/earning/transactions?sortDirection=DESC&pageNo=${params.pageNo}&sortBy=${params.sortBy}`
          : `v2/client/mentor/earning/transactions?sortDirection=DESC&pageNo=${params.pageNo}&sortBy=${params.sortBy}`;
        return Url;
      },
    }),
    getMentorEarningLogs: builder.mutation({
      query(payload) {
        return {
          url: payload.userType
            ? `/v1/client/mentor/lm/earning/logs`
            : `/v2/client/mentor/earning/logs`,
          method: "POST",
          body: payload.body,
        };
      },
    }),
  }),
});

export const {
  useGetMonthlyTrendsQuery,
  useGetEarningStatsQuery,
  useGetMonthlyBreakupQuery,
  useGetMentorInvoicesQuery,
  useGetMentorEarningLogsMutation,
} = getIGEarning;
