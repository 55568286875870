import { FC } from "react";

import { LinkStyledButton } from "./linkButton.styles";
import { LinkButtonProps } from "./types";

const LinkButton: FC<LinkButtonProps> = ({ color, ...rest }) => {
  return <LinkStyledButton color={color} {...rest} />;
};

export default LinkButton;

LinkButton.defaultProps = {
  color: "default",
};
