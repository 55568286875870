import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { sendEmailVerification } from "@/client/OneistoxApiClient";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import PrimaryButton from "@/design-system-v2/Button/PrimaryButton";
import FlexContainer from "@/design-system-v2/FlexContainer";
import Tabs from "@/design-system-v2/Tabs/Tabs";
import Typography from "@/design-system-v2/Typography";

import TypographyV3 from "@/design-system-v3/Typography";

import { useAppSelector } from "@/hooks/hooks";

import IgProfilePopup from "@/components/LMSV3/Profile/IgProfilePopup";
import LearnerHubBanner from "@/components/PlacementHub/MainBanner/LearnerHubBanner";
import {
  freeCourseData,
  freeCourseRowData,
  freeCourseMenaData,
} from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

import Banner from "../Banner";
import FreeCard from "../CourseCards/FreeCard/FreeCard";
import PaidCard from "../CourseCards/PaidCard/PaidCard";
import ReferBanner from "../ReferBanner";

import { LearnerHubLoader } from "./Loader";

const QuizBannerWrapper = styled(Box)`
  min-width: 328px;
  background: linear-gradient(268deg, #491c96 0%, #2a1267 100%);
`;

// startTime is used to check card-type for Mentors(iG)
export const getCardType = (
  state: string,
  cohortStartTime,
  isUserMentorOrIg,
  cohortEndTime = null
) => {
  if (isUserMentorOrIg) {
    if (cohortEndTime && new Date().getTime() > new Date(cohortEndTime).getTime()) {
      return "archived";
    }
    return new Date() < new Date(cohortStartTime) ? "upcoming" : "ongoing";
  } else {
    if (state === "TRANSFERRED") {
      return "transferred";
    } else if (state === "CANCELLED") {
      return "cancelled";
    }
    return "enrolled";
  }
};

export const getCardStatus = (state: string, cohortStartTime, isUserMentorOrIg) => {
  const courseType = getCardType(state, cohortStartTime, isUserMentorOrIg);
  if (courseType !== "enrolled" || isUserMentorOrIg) {
    return "";
  }
  const status = {
    UPCOMING: "upcoming",
    WAITING: "upcoming",
    ONGOING: "ongoing",
    PAYMENT_PENDING: "ongoing",
    COMPLETED: "completed",
  };
  return status[state];
};

export const getCohortStartDate = (arr) => {
  const startDateAvailable = arr?.find(
    (elem) => elem.key === "START_DATE" || elem.key === "KICK_OFF" || elem.key === "COURSE_UNLOCKED"
  );
  return startDateAvailable?.meta?.time;
};

export const getCohortCompletionDate = (arr) => {
  const endDateAvailable = arr?.find((t) => t.key === "COURSE_COMPLETION_DATE");
  return endDateAvailable?.meta?.time;
};

const Hub = ({
  isUserMentor = false,
  isUserIg = false,
  userCourseList,
  chipsData,
  sidebarVisible = false,
  isLoading = false,
  showReferBanner = false,
  placementHubCohortId = "",
}) => {
  const [courseState, setCourseState] = useState("enrolled");
  const [filterId, setFilterId] = useState("1");
  const [userName, setUserName] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const router = useRouter();
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const isFreeUser = userCourseList?.length === 0;
  const isMobileScreen = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  const isRow = Boolean(loginDetails?.userLocation === "ROW");
  const isMena = Boolean(loginDetails?.userLocation === "MENA");
  const courseList = isRow ? freeCourseRowData : isMena ? freeCourseMenaData : freeCourseData;

  useEffect(() => {
    if (loginDetails?.userProfile) {
      const userProfile = loginDetails?.userProfile;
      setShowPopup(
        !(
          userProfile?.firstName &&
          userProfile?.lastName &&
          userProfile?.profileMedia &&
          userProfile?.bio &&
          userProfile?.occupation &&
          userProfile?.currentCompany &&
          userProfile?.workExperienceInMonths &&
          userProfile?.firstName != "" &&
          userProfile?.lastName != "" &&
          userProfile?.profileMedia.length &&
          userProfile?.bio != "" &&
          userProfile?.occupation != "" &&
          userProfile?.currentCompany != "" &&
          userProfile?.workExperienceInMonths != ""
        )
      );
    }
  }, [loginDetails]);

  useEffect(() => {
    setUserName(loginDetails?.userProfile?.firstName);
    setCourseState(
      loginDetails?.userData?.roles?.includes(isUserIg ? "INDUSTRIAL_GUIDE" : "LEAD_MENTOR")
        ? "ongoing"
        : "enrolled"
    );

    // For checking user's email-verification status
    setIsEmailVerified(!!loginDetails?.userData.emailVerifiedAt);
  }, [isUserIg, loginDetails]);

  const EmptyScreen = useCallback(() => {
    const emptyImg = {
      enrolled: "ongoing",
      ongoing: "ongoing",
      upcoming: "upcoming",

      // Todo -> map to correct creative-img
      transferred: "upcoming",
      cancelled: "archived",

      archived: "archived",
    };
    const emptyText = {
      enrolled: "Courses that you are currently enrolled in appear here",
      ongoing: "Courses that are currently running for you appear here",
      upcoming: "Courses that are yet to start will appear here",
      transferred: "Courses that you have transferred from will appear here",
      cancelled: "Courses that you have cancelled will appear here",
      archived: "Courses that you have completed will appear here",
    };

    return (
      <FlexContainer
        mt={theme.spacing(6)}
        flexDirection={"column"}
        alignItems={"center"}
        px={theme.spacing(5)}
        py={theme.spacing(10)}
        width={"100%"}
      >
        <ExportedImage
          src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/empty-${emptyImg[courseState]}.svg`}
          height={148}
          width={148}
          alt="no-courses"
        />
        <Typography
          mt={theme.spacing(2)}
          color={theme.colors.gray[600]}
          weightVarient="semibold"
          style={{
            fontSize: "16px",
          }}
        >
          {emptyText[courseState]}
        </Typography>
      </FlexContainer>
    );
  }, [courseState]);

  if (isLoading) {
    return <LearnerHubLoader sidebarVisible={sidebarVisible} />;
  }

  return (
    <Box
      pl={{ md: theme.spacing(30) }}
      pr={{ md: sidebarVisible ? theme.spacing(4) : theme.spacing(30) }}
      bg={theme.colors.grayCool[50]}
      flexGrow={"1"}
    >
      <Box py={theme.spacing(6)} pb={theme.spacing(20)} px={theme.spacing(4)}>
        {/* warning-banners come here */}
        {!isEmailVerified && (
          <Banner
            title={"Your email verification is pending! Please verify your email."}
            bannerIcon={
              <Icon
                varient="solid"
                type="alert-triangle"
                size={16}
                color={theme.colors.base.black}
              />
            }
            ctaLabel={"Verify now"}
            ctaCallback={() => {
              sendEmailVerification().then(() => {
                router.push(`/verify-mail`);
              });
            }}
            ctaIcon={
              <Icon
                color={theme.colors.base.white}
                type={"arrow-narrow-up-right"}
                varient={"line"}
                size={14}
              />
            }
          />
        )}
        <Typography
          varient="displaymd"
          weightVarient="semibold"
          color={theme.colors.grayCool[900]}
          px={{
            _: theme.spacing(2),
            md: theme.spacing(4),
          }}
        >
          {`Hi ${userName}! ${isFreeUser ? "" : "Here are your courses."}`}
        </Typography>
        {isFreeUser ? (
          <>
            {false && (
              <QuizBannerWrapper
                px={{
                  _: theme.spacing(4),
                  md: theme.spacing(16),
                }}
                py={theme.spacing(6)}
                borderRadius={theme.radius(4)}
                mt={theme.spacing(2)}
                maxWidth={"1000px"}
              >
                <FlexContainer
                  alignItems={isMobileScreen ? "flex-start" : "center"}
                  flexDirection={isMobileScreen ? "column-reverse" : "row"}
                  alignContent={isMobileScreen ? "flex-start" : "auto"}
                >
                  <Box
                    py={{
                      md: theme.spacing(4),
                    }}
                    pr={{
                      md: theme.spacing(12),
                    }}
                    width={{
                      _: "100%",
                      md: "auto",
                    }}
                  >
                    <Typography
                      varient={"displaysm"}
                      weightVarient={"bold"}
                      color={theme.colors.base.white}
                    >
                      Discover your career paths with a quick quiz!
                    </Typography>
                    {/* <Typography
                    varient={"displaysm"}
                    weightVarient={"bold"}
                    color={theme.colors.base.white}
                  >
                    Try our career path builder!
                  </Typography> */}
                    <Typography
                      mt={theme.spacing(2)}
                      varient={"textsm"}
                      weightVarient={"regular"}
                      color={theme.colors.purple[300]}
                    >
                      Your skills and interests matter!
                    </Typography>
                    <PrimaryButton
                      label={"Take Quiz"}
                      fullWidth={isMobileScreen}
                      size="md"
                      mt={theme.spacing(6)}
                      color={"neutral"}
                      onClick={() => router.push("/career-navigator/quiz")}
                      suffixIcon={
                        <Icon
                          type="arrow-right"
                          varient="line"
                          size={16}
                          color={theme.colors.base.white}
                        />
                      }
                    />
                  </Box>
                  <ExportedImage
                    src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/banner-creative.svg`}
                    width={isMobileScreen ? 120 : 200}
                    height={isMobileScreen ? 120 : 180}
                    alt="creative"
                  />
                </FlexContainer>
              </QuizBannerWrapper>
            )}
            <FlexContainer
              mt={theme.spacing(12)}
              py={"7px"}
              px={theme.spacing(4)}
              gap={theme.spacing(2)}
              alignItems={"center"}
            >
              <Icon varient="line" type="star-04" color={theme.colors.warning[500]} size={18} />
              <Typography
                varient="displayxs"
                weightVarient="semibold"
                color={theme.colors.grayCool[500]}
              >
                Featured courses for you
              </Typography>
            </FlexContainer>
            {/* Free-cards-container */}
            <FlexContainer
              flexWrap={"wrap"}
              gap={theme.spacing(3)}
              mt={theme.spacing(3)}
              flexDirection={{
                _: "column",
                md: "row",
              }}
              alignItems={{
                _: "center",
                md: "stretch",
              }}
              justifyContent={{
                md: "flex-start",
              }}
            >
              {courseList.map((item, index) => (
                <FreeCard data={item} key={index} />
              ))}
            </FlexContainer>
          </>
        ) : (
          <>
            {/* Filters */}
            <FlexContainer
              mt={theme.spacing(3)}
              px={{
                _: theme.spacing(2),
                md: theme.spacing(4),
              }}
              gap={theme.spacing(2)}
            >
              <Tabs
                elementList={chipsData}
                activeTab={chipsData[0].tabKeyName}
                setTabname={(tabName: string) => {
                  const filterKey =
                    chipsData.find((item) => item.tabKeyName === tabName).key || "1";
                  setFilterId(filterKey);
                  setCourseState(tabName);
                }}
                tabType={"chips"}
              />
            </FlexContainer>
            {/* Paid-cards-container */}
            {showReferBanner && isMobileScreen && <ReferBanner />}
            <FlexContainer
              flexWrap={"wrap"}
              gap={theme.spacing(4)}
              mt={theme.spacing(6)}
              flexDirection={{
                _: "column",
                md: "row",
              }}
              alignItems={{
                _: "center",
                md: "stretch",
              }}
              justifyContent={{
                md: "flex-start",
              }}
            >
              {userCourseList
                ?.filter((item) => item.filterId === filterId)
                .map((item, index) => (
                  <PaidCard
                    status={getCardStatus(
                      isUserMentor || isUserIg ? item?.cohort?.status : item?.cohortUserData?.state,
                      getCohortStartDate(item?.cohort?.timelines),
                      isUserMentor || isUserIg
                    )}
                    type={getCardType(
                      isUserMentor || isUserIg ? item?.cohort?.status : item?.cohortUserData?.state,
                      getCohortStartDate(item?.cohort?.timelines),
                      isUserMentor || isUserIg
                    )}
                    data={item}
                    key={index}
                    index={index}
                    isUserMentor={isUserMentor}
                    isUserIg={isUserIg}
                  />
                ))}
              {userCourseList?.filter((item) => item.filterId === filterId).length === 0 && (
                <EmptyScreen />
              )}
            </FlexContainer>
          </>
        )}

        {/* {
          !isFreeUser && !isUserIg && !isUserMentor &&
          <Careers />
        } */}
      </Box>

      {/* Placement-portal banner */}
      {!isFreeUser &&
        !isUserIg &&
        !isUserMentor &&
        !loginDetails?.userData?.roles?.length &&
        placementHubCohortId && (
        <FlexContainer flexDirection={"column"} gap={theme.spacing(1)}>
          <TypographyV3 px={theme.spacing(4)} varient="heading5" weightVarient="semibold">
              Novatr Placement Hub
          </TypographyV3>
          <LearnerHubBanner placementHubCohortId={placementHubCohortId} />
          {/* {getBannerTypeResponse?.data?.bannerType ===
                "ELIGIBLE_BUT_FORM_NOT_FILLED" && <FinalChanceBanner />} */}
        </FlexContainer>
      )}

      {showPopup && isUserIg && (
        <IgProfilePopup showPopup={showPopup} setShowPopup={setShowPopup} />
      )}
    </Box>
  );
};

export default Hub;
