export interface experienceDataTypes {
  id?: string;
  description?: string;
  title: string;
  companyName: string;
  employmentType: string;
  fromDate: string;
  toDate: string;
  iCurrentlyWorkHere: boolean;
  skillsGained?: string[];
}

export interface educationDataTypes {
  id?: string;
  description?: string;
  college: string;
  degree: string;
  iCurrentlyStudyHere: boolean;
  qualification: string;
  fromDate: string;
  toDate?: string;
  university: string;
  skillsGained?: string[];
}

export enum COHORT_USER_STATE {
  WAITING = "WAITING",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  TRANSFERRED = "TRANSFERRED",
  CANCELLED = "CANCELLED",
}
