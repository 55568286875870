import Skeleton from "react-loading-skeleton";

import Box from "@/design-system/Box";

import FlexContainer from "@/design-system-v2/FlexContainer";

import { theme } from "@/theme";

export const LearnerHubLoader = ({ sidebarVisible }) => {
  return (
    <Box
      pl={{ md: theme.spacing(30) }}
      pr={{ md: sidebarVisible ? theme.spacing(4) : theme.spacing(30) }}
      bg={theme.colors.grayCool[50]}
      flexGrow={"1"}
    >
      <Box py={theme.spacing(6)} pb={theme.spacing(20)} px={theme.spacing(4)}>
        <Box
          px={{
            _: theme.spacing(2),
            md: theme.spacing(4),
          }}
        >
          <Skeleton width="45%" style={{ lineHeight: "2rem" }} />
        </Box>
        <FlexContainer
          mt={theme.spacing(3)}
          px={{
            _: theme.spacing(2),
            md: theme.spacing(4),
          }}
          gap={theme.spacing(2)}
        >
          <Skeleton width="100px" borderRadius={theme.radius(16)} style={{ lineHeight: "2rem" }} />
          <Skeleton width="100px" borderRadius={theme.radius(16)} style={{ lineHeight: "2rem" }} />
          <Skeleton width="100px" borderRadius={theme.radius(16)} style={{ lineHeight: "2rem" }} />
        </FlexContainer>

        <FlexContainer
          flexWrap={"wrap"}
          gap={theme.spacing(4)}
          mt={theme.spacing(6)}
          flexDirection={{
            _: "column",
            md: "row",
          }}
          alignItems={{
            _: "center",
            md: "stretch",
          }}
          justifyContent={{
            md: "flex-start",
          }}
          px={{
            _: theme.spacing(2),
            md: theme.spacing(4),
          }}
        >
          {[1, 1, 1, 1].map((item, index) => (
            <Skeleton
              key={index}
              height="224px"
              width="390px"
              // containerClassName="flex-1"
              borderRadius={theme.radius(4)}
            />
          ))}
        </FlexContainer>
      </Box>
    </Box>
  );
};
