import React from "react";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";

import Typography from "@/design-system-v3/Typography";

import { intToChar } from "@/utils/utils";

import { theme } from "@/theme";

import { OptionType } from "./type";

const StyledBox = styled(Box)`
  transition: 0.2s ease-in-out;
  cursor: pointer;
`;
export default function OptionComponent({
  option,
  isActive,
  isHover,
  index,
}: {
  option: OptionType;
  isActive: boolean;
  isHover: boolean;
  index: number;
}) {
  return (
    <StyledBox
      width={"100%"}
      padding={theme.spacing(4)}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      background={
        isActive
          ? theme.colors.purple[100]
          : isHover
            ? theme.colors.grayCool[50]
            : theme.colors.base.white
      }
      borderRadius={theme.radius(4)}
      border={`1px solid ${isActive ? theme.colors.purple[500] : theme.colors.gray[200]}`}
      gap={theme.spacing(2)}
    >
      <Box
        borderRadius={"50%"}
        height={theme.spacing(5)}
        width={theme.spacing(5)}
        background={false ? theme.colors.purple[700] : theme.colors.grayCool[100]}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {isActive ? (
          <Icon
            varient={"solid"}
            type={"check-circle"}
            size={20}
            stokeColor={theme.colors.base.white}
            color={theme.colors.purple[700]}
          />
        ) : (
          <Typography color={theme.colors.gray[900]} varient={"bodyxxs"} weightVarient="medium">
            {intToChar(index)}
          </Typography>
        )}
      </Box>
      <Typography
        varient="bodym"
        weightVarient="medium"
        color={isActive ? theme.colors.purple[700] : theme.colors.gray[900]}
      >
        {option.text}
      </Typography>
    </StyledBox>
  );
}
