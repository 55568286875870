import axios from "axios";
import Cookies from "js-cookie";

const OneistoxAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROXY_2,
});

OneistoxAPI.defaults.headers.common["Content-Type"] = "application/json";
OneistoxAPI.defaults.headers.common["x-auth-token"] = Cookies.get("token");

if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
  OneistoxAPI.defaults.headers.common["x-auth-override"] =
    process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER;
}

export const submitUnitIdReportUpload = async (
  cohortId: string,
  unitId: string,
  data: any,
  fileName: string
) => {
  const res = await OneistoxAPI.post(
    `v1/client/lms/product/${cohortId}/report/submit/upload/url?unit-id=${unitId}`,
    { fileName }
  );
  const { url, path, bucket } = res?.data || {};
  if (url) {
    await axios.put(url, data, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  }
  return await OneistoxAPI.post(`v2/client/lms/${cohortId}/report/submit?unit-id=${unitId}`, {
    path,
    bucket,
  });
};
export const uploadEmiDocument = async (orderId, data) => {
  return await OneistoxAPI.put(`v1/client/oms/${orderId}/order/emi/document`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadResumeMyApplication = async (data, dealId) => {
  return await OneistoxAPI.put(`/v1/client/my-applications/upload-resume?dealId=${dealId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCollegeList = async (courseType) => {
  return await OneistoxAPI.get(`/v1/client/my-applications/college-list?courseType=${courseType}`);
};

export const OneistoxAPILMS = OneistoxAPI;
