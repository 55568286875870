import styled from "styled-components";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";

import { theme } from "@/theme";

export const StyledButton = styled(UnstyledButton)<{
  active: boolean;
  isHovered: boolean;
  activeColor: string;
  unselectedColor: string;
  hoverColor: string;
}>`
  height: 48px;
  width: 48px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${({ active, isHovered, activeColor, unselectedColor, hoverColor }: any) =>
    active ? activeColor : isHovered ? hoverColor : unselectedColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const EmojiStyledButton = styled(Box)<{
  active: boolean;
  isHovered: boolean;
  activeColor: string;
}>`
  width: 48px;
  height: 48px;
  padding: 0px 8px;
  background-color: ${({ active, isHovered, activeColor }: any) =>
    active ? activeColor : isHovered ? "#EFF1F5" : ""};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > img {
    transform: ${({ active }) => (active ? "scale(1.3)" : "scale(1)")};
    transition: transform 0.3s ease-in-out;
  }
`;
const getBorderWidth = (index, isMobile, length) => {
  if (length > 10) {
    if (isMobile) {
      if (index < Math.floor(length / 2)) {
        return "2px 0px 2px 2px";
      }
      if (index == Math.floor(length / 2)) {
        return "2px 2px 2px 2px";
      }
      if (index == length - 1) return "0px 2px 2px 2px";
      return "0px 0px 2px 2px";
    } else {
      if (index == length - 1) {
        return "2px 2px 2px 2px";
      }
      return "2px 0px 2px 2px";
    }
  } else if (length === 10) {
    if (isMobile) {
      if (index < Math.floor(length / 2) - 1) {
        return "2px 0px 2px 2px";
      }
      if (index == Math.floor(length / 2) - 1) {
        return "2px 2px 2px 2px";
      }
      if (index == length - 1) return "0px 2px 2px 2px";
      return "0px 0px 2px 2px";
    } else {
      if (index == length - 1) {
        return "2px 2px 2px 2px";
      }
      return "2px 0px 2px 2px";
    }
  } else {
    if (index == length - 1) {
      return "2px 2px 2px 2px";
    }
    return "2px 0px 2px 2px";
  }
};

const getBorderRadius = (index: number, length: number, isMobile: boolean) => {
  if (isMobile) {
    if (length >= 10) {
      return "0px";
    }
  }
  if (index == 0) {
    return "8px 0px 0px 8px";
  }
  if (index == length - 1) return "0px 8px 8px 0px";
  return "0px";
};

export const ScaleBox = styled(Box)<{
  active: boolean;
  index: number;
  length: number;
  isMobile: boolean;
}>`
  border-width: ${({ index, isMobile, length }: any) => getBorderWidth(index, isMobile, length)};
  border-style: solid;
  border-color: #eaecf0;
  height: 48px;
  padding: 12px 8px;
  width: 100%;
  border-radius: ${({ index, isMobile, length }: any) => getBorderRadius(index, length, isMobile)};
  background-color: ${({ active }: any) => {
    return active ? "#4A1FB8" : "#FFFF";
  }};
  cursor: pointer;
  color: ${({ active }: any) => (active ? "#FFFF" : "#475467")};
`;

export const StyledTextArea = styled.textarea`
  border-radius: 16px;
  border: none !important;
  align-self: stretch;
  padding: 8px;
  background-color: ${theme.colors.grayCool[50]};
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-family: inherit;
  color: ${theme.colors.gray[900]};
  &:focus {
    border: 1px solid red;
  }
  &::placeholder {
    color: ${theme.colors.gray[400]};
  }
`;
