import { appBaseCreateApi } from "./BaseQueryApiClient";

export const getTaskAssessmentApi = appBaseCreateApi({
  reducerPath: "getTaskAssessmentApi",
  tagTypes: ["ASSIGNMENT_FEEDBACK", "GET-QUERY-TAT"],
  endpoints: (builder) => ({
    getTaskList: builder.query({
      query: (cohortId) => `v2/client/mentor/reports/${cohortId}`,
      providesTags: ["ASSIGNMENT_FEEDBACK"],
    }),
    getTatData: builder.query({
      query: (query) => {
        return {
          url: `/v1/client/mentor/tat/${query.cohortId}?isTaskTat=true`,
          method: "GET",
        };
      },
      providesTags: ["GET-QUERY-TAT"],
    }),
    assignmentFeedback: builder.mutation({
      query(payload) {
        return {
          url: `/v2/client/mentor/report/assessment?user-id=${payload.userId}&namespace=${payload?.namespace}`,
          method: "PUT",
          body: payload,
          query: {
            namespace: "LEARNING",
          },
        };
      },
      invalidatesTags: ["ASSIGNMENT_FEEDBACK", "GET-QUERY-TAT"],
    }),
  }),
});

export const { useGetTaskListQuery, useGetTatDataQuery, useAssignmentFeedbackMutation } =
  getTaskAssessmentApi;
