import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { trackleadform } from "@/analytics/analytics";

import SecondaryButton from "@/design-system-v2/Button/SecondaryButton";

import { useAppSelector } from "@/hooks/hooks";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { steps } from "./TourConfig";

const DynamicTour = dynamic(() => import("reactour"), {
  ssr: false,
});
interface TourPropTypes {
  updateCoachmarks: ({
    cohortId,
    payload,
  }: {
    cohortId: string;
    payload: { showCoachmarks: boolean };
  }) => void;
  allowedRoutes: string[];
  previousRoute: string;
  showCoachmarks: boolean;
  cohortId: string;
}

export const Tour = ({
  updateCoachmarks,
  allowedRoutes,
  previousRoute,
  showCoachmarks,
  cohortId,
}: TourPropTypes) => {
  const router = useRouter();
  const splitRouteArray = router?.asPath.split("/");

  const showTour = allowedRoutes.includes(splitRouteArray[splitRouteArray.length - 1]);
  const [coachmarkSteps, setCoachmarkSteps] = useState(0);
  const [isTourOpen, setIsOpenTour] = useState(false);
  const loginDetails: any = useAppSelector((state) => state?.rootReducer?.auth);

  useEffect(() => {
    if (coachmarkSteps) {
      const mixPanelTrackObj = {
        coachmarkSteps,
        Page_path: window.location.pathname,
        Current_Page: window.location.href,
        Page_Referrer: previousRoute,
        percent_scrolled: getScrollPositionByPercentage(),
        loginDetails: loginDetails.userData,
        isLoggedIn: loginDetails.isAuthenticated,
      };
      trackleadform("coachmarks_steps", mixPanelTrackObj);
    }
  }, [coachmarkSteps]);

  useEffect(() => {
    if (showCoachmarks) {
      setIsOpenTour(true);
      setCoachmarkSteps(1);
    }
  }, [showCoachmarks]);

  const onCloseTour = () => {
    updateCoachmarks({ cohortId, payload: { showCoachmarks: false } });
    setIsOpenTour(false);
    const mixPanelTrackObj = {
      btnText: "coachmarks_Close",
      Page_path: window.location.pathname,
      Current_Page: window.location.href,
      Page_Referrer: previousRoute,
      percent_scrolled: getScrollPositionByPercentage(),
      loginDetails: loginDetails.userData,
      isLoggedIn: loginDetails.isAuthenticated,
    };
    trackleadform("Button_Click", mixPanelTrackObj);
  };

  return showTour ? (
    <DynamicTour
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={onCloseTour}
      closeWithMask={false}
      accentColor="#5925DC"
      maskSpace={4}
      rounded={5}
      getCurrentStep={(curr) => setCoachmarkSteps(curr + 1)}
      showNumber={false}
      lastStepNextButton={<SecondaryButton label={"Close"} size="lg" color="default" />}
    />
  ) : (
    <></>
  );
};
