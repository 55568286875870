import ExportedImage from "next-image-export-optimizer";
import React, { useState } from "react";

import Box from "@/design-system/Box";

import FlexContainer from "@/design-system-v2/FlexContainer";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

import { StyledButton } from "../StyleComponent";
import { BinaryScalePropTypes } from "../type";

export default function BinaryScale({
  setSelectedQuestionIndex,
  options,
  handleChange,
  questionObj,
}: BinaryScalePropTypes) {
  const [rating, setRating] = useState<number>(0);
  const [hoverIndex, setHoverIndex] = useState<number>(0);
  const handleRatingChange = (index: number) => {
    handleChange(questionObj, index);
    setRating(index);
    setTimeout(() => setSelectedQuestionIndex((prevValue: number) => prevValue + 1), 2000);
  };
  return (
    <Box
      borderRadius={theme.radius(3)}
      background={theme.colors.base.white}
      display={"flex"}
      flexDirection={"column"}
      gap={theme.spacing(8)}
    >
      <FlexContainer
        display={"flex"}
        alignItems={"center"}
        gap={theme.spacing(8)}
        justifyContent={"center"}
      >
        {options.map((element, index) => {
          return (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              gap={theme.spacing(2)}
              justifyContent={"center"}
            >
              <StyledButton
                onClick={() => {
                  handleRatingChange(element.optionId);
                }}
                onMouseEnter={() => setHoverIndex(index + 1)}
                onMouseLeave={() => setHoverIndex(0)}
                isHovered={index + 1 === hoverIndex}
                active={index + 1 === rating}
                activeColor={theme.colors.purple[700]}
                unselectedColor={theme.colors.yellow[50]}
                hoverColor={theme.colors.yellow[200]}
              >
                <ExportedImage
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/Feedback/${element.icon}.png`}
                  width={30}
                  height={30}
                  sizes=""
                  alt="YCombinator"
                />
              </StyledButton>
              <Typography
                textAlign={"center"}
                varient="bodys"
                weightVarient="regular"
                color={theme.colors.gray[800]}
              >
                {element.text}
              </Typography>
            </Box>
          );
        })}
      </FlexContainer>
    </Box>
  );
}
