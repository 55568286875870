import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Extralight.woff2`}) format('woff2');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-ExtralightItalic.woff2`}) format('woff2');
       font-weight: 200;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Light.woff2`}) format('woff2');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-LightItalic.woff2`}) format('woff2');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Regular.woff2`}) format('woff2');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Italic.woff2`}) format('woff2');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Medium.woff2`}) format('woff2');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-MediumItalic.woff2`}) format('woff2');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Semibold.woff2`}) format('woff2');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-SemiboldItalic.woff2`}) format('woff2');
       font-weight: 600;
       font-display: swap;
       font-style: italic;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-Bold.woff2`}) format('woff2');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'GeneralSans';
  src: url(${`${process.env.NEXT_PUBLIC_ASSETS}/fonts/GeneralSans-BoldItalic.woff2`}) format('woff2');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}
// tool tip css start
.radar-tooltip{
    color: #FFF;
    font-family: 'Figtree', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: 20px;
    padding-bottom: 12px;
  }

  .radar-tooltip-text{
    color: #FFF;
    font-family: 'Figtree', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 142.857% */
    text-align: start;
  }

  .fw-600 {
    font-weight: 600;
  }

  .br-8 {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.10);
    padding: 2px 8px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .verticalLine {
    border-left: 2px solid rgba(255, 255, 255, 0.10);
    height: 41.5px;
    background-color: white;
  }

  .gap-1 {
    gap: 4px;
  }

  .gap-2 {
    gap: 8px;
  }

  .gap-3 {
    gap: 12px;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-start {
    justify-content: start;
    align-items: start;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FFC84B;
  }

  .bg-green {
    background-color: #32D583;
  }

  .bg-yellow {
    background-color: #FFC84B;
  }

  .tooltip-feedback-text {
    font-family: 'Figtree', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 120% */
  }

  .color-yellow {
    color:  #FEC84B;
  }

  .color-green {
    color:  #32D583;
  }

  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  // end tool tip css
video, img{
  vertical-align: middle;
}

.pointer{
  cursor: pointer;
}

.customCss {
  border-radius: "16px"
}

.disabled{
  cursor:not-allowed;
  opacity:0.6;
}

.underline{
 text-decoration:underline;
}

.mobile-account-info {
  position: absolute; 
  top: "64px"
}

.jwplayer{
  border-radius: 4px;
}

html {
  scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    margin:0;
    padding:0;
    position: relative;
  }
  
  body {
    font-family: 'Figtree', sans-serif;
  }
  .t-capital {
    text-transform: uppercase;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    #hubspot-messages-iframe-container iframe {
      display: none !important;
    }
    #hubspot-messages-iframe-container iframe {
      visibility: hidden;
      z-index: 0;
    }

    #hubspot-messages-iframe-container {
      z-index: 0 !important;
      max-width: 0;
     max-height: 0;
    }
  }

  .styletippy-8 > .tippy-content {
    padding: 0;
    border-radius: 0px
  }
  .styletippy-8 {
    border-radius: 8px;
  }

    .loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2.5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.loader-dark::before {
  border-color: #000; /* color for primary dark */
}

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
    
  
  .keen-slider__slide {
    flex: 0 0 33.33%; /* 3 slides per view */
    margin: 0 8px;
  }
  .flex-1{
    flex: 1 1 0;
  }
  .slide {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    user-select: none;
  .markdown {
    padding-left: 16px;
  }
  .slide {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    user-select: none;
  }

  .radar-tooltip{
    color: #FFF;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: 20px; /* 142.857% */
    padding-bottom: 12px;
  }
  
  .radar-tooltip-text{
    color: #FFF;
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 142.857% */
    text-align: start;
  }
  
  .fw-600{
    font-weight: 600;
  }

  .br-8{
    border-radius:8px;
    background:  rgba(255, 255, 255, 0.10);
    padding:2px 8px;
  }

  .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
  }

  .verticalLine{
  border-left: 2px solid rgba(255, 255, 255, 0.10);
    height:41.5px;
    background-color:"white"
  }

  .gap-1{
    gap:4px;
  }

  .gap-2{
    gap:8px;
  }

  .gap-3{
    gap:12px;
  }

  .flex-col{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
  }

  .content-start{
    justify-content:start;
    align-items: start
  }

  .dot{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #FFC84B;
    }
    
    .bg-green{
      background-color: #32D583;
    }
    
    .bg-yellow{
    background-color: #FFC84B;
  }

  .tooltip-feedback-text{
    font-family: Figtree;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 120% */
  }
  
  .color-yellow{
    color:  #FEC84B;
  }
  
  .color-green{
    color:  #32D583;
  }

  .py-2{
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export default GlobalStyle;
