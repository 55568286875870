import styled, { css } from "styled-components";
import { space } from "styled-system";

import { theme } from "@/theme";

import { ButtonProps } from "./types";

type SizeOptions = "xs" | "sm" | "md" | "lg" | "xl";

export const StyledButton = styled.button<ButtonProps>`
  ${space}
  height: ${({ size }) => getSize[size as SizeOptions]["height"] + "px"};
  font-size: ${({ size }) => getSize[size as SizeOptions]["fontSize"] + "px"};
  border-radius: ${theme.radius(2)};
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  padding: 0 ${({ size }) => getSize[size as SizeOptions]["padding"][1]}px;
  transition: all 0.4s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: inherit;
  /* background-color: unset; */

  @media (min-width: ${({ theme, size }) => theme.breakpoints[size as SizeOptions]}) {
    height: ${({ size }) => getSize[size as SizeOptions]["height"] + "px"};
    font-size: ${({ size }) => getSize[size as SizeOptions]["fontSize"] + "px"};
    padding: 0 ${({ size }) => getSize[size as SizeOptions]["height"] / 2}px;
  }
  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.label &&
    css`
      min-width: ${() => (getSize[props.size as SizeOptions]["height"] / 4) * 12}px;
    `}

  & span {
    display: inherit;

    & > :nth-of-type(1) {
      font-size: ${({ size }) => getSize[size as SizeOptions]["fontSize"] + 6 + "px"};
    }

    & svg {
      /* fill: currentColor; */
      transition: fill 0.4s ease-in-out;
      /* width: ${({ size }) => getSize[size as SizeOptions]["fontSize"] + 6 + "px"};
      height: ${({ size }) => getSize[size as SizeOptions]["fontSize"] + 6 + "px"}; */
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  & .icon {
    margin-right: 4px;
    display: flex;
    justify-content: center;
  }
  & .suffix {
    margin-left: 8px;
    display: flex;
    justify-content: center;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const getSize = {
  xs: {
    height: 28,
    fontSize: 10,
    padding: [8, 12],
  },
  sm: {
    height: 32,
    fontSize: 12,
    radius: theme.radius(2),
    padding: [8, 16],
  },
  md: {
    height: 36,
    fontSize: 14,
    radius: theme.radius(2),
    padding: [8, 16],
  },
  lg: {
    height: 40,
    fontSize: 14,
    radius: theme.radius(2),
    padding: [12, 20],
  },
  xl: {
    height: 44,
    fontSize: 16,
    radius: theme.radius(2),
    padding: [12, 24],
  },
};
