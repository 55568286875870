import {
  CreateLeadApiResponse,
  FormId,
  GetOnboardingCareerRolesApiResponse,
  GetQuestionsApiResponse,
  LeadUserData,
  LeadUserPersonalDetails,
  LevelUpResponsePayload,
  SubmitQuizReponsesApiPayload,
  UpdateLeadPersonalDetailsApiResponse,
} from "@/components/CareerQuiz/types";

import { appBaseCreateApi } from "./BaseQueryApiClient";

export const careerBuilderClient = appBaseCreateApi({
  reducerPath: "careerBuilderClient",
  tagTypes: [],

  endpoints: (builder) => ({
    getOnboardingCareerRoles: builder.query<GetOnboardingCareerRolesApiResponse, void>({
      query: () => {
        return {
          url: `v1/client/public/career-builder/onboarding-career-roles`,
        };
      },
    }),
    getQuestions: builder.query<GetQuestionsApiResponse, { formId: FormId }>({
      query: ({ formId }) => {
        return {
          url: `v1/client/public/career-builder/career-builder-form/${formId}`,
        };
      },
    }),
    createLead: builder.mutation<CreateLeadApiResponse, LeadUserData>({
      query: (payload) => {
        return {
          url: `v2/client/public/career-builder/lead-user-data`,
          method: "POST",
          body: payload,
        };
      },
    }),
    updateLeadPersonalDetails: builder.mutation<
      UpdateLeadPersonalDetailsApiResponse,
      LeadUserPersonalDetails
    >({
      query: (payload) => {
        return {
          url: `v1/client/public/career-builder/personal-details`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    submitQuizReponses: builder.mutation<string, SubmitQuizReponsesApiPayload>({
      query: ({ responses, email, formId, careerType }) => {
        return {
          url: `v2/client/public/career-builder/submit-quiz-response?emailId=${email}&careerType=${careerType}&formId=${formId}`,
          method: "PUT",
          body: responses,
        };
      },
    }),
    levelUpResponse: builder.mutation<string, LevelUpResponsePayload>({
      query: ({ levelUp, email, levelQuery }) => {
        return {
          url: `v1/client/public/career-builder/register-for-levelup?emailId=${email}${levelUp ? `&levelUp=${levelUp}` : ""}${levelQuery ? `&levelQuery=${levelQuery}` : ""}`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetOnboardingCareerRolesQuery,
  useGetQuestionsQuery,
  useCreateLeadMutation,
  useUpdateLeadPersonalDetailsMutation,
  useSubmitQuizReponsesMutation,
  useLevelUpResponseMutation,
} = careerBuilderClient;
