export enum ThemeBreakpoints {
  xs = "xs",
  md = "md",
}

export enum Color {}

export enum CAPSTONE_ONBOARDING_STATUS {
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
}
