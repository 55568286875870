import { FC } from "react";

import Icon from "@/design-system/Icon";

import { StyledButton } from "./button.styles";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({
  isLoading = false,
  size = "md",
  shape = "default",
  icon,
  suffixIcon,
  label,
  className,
  ...props
}) => {
  return (
    <StyledButton
      size={size}
      shape={shape}
      className={className}
      iconColor={icon?.color}
      suffixIconColor={suffixIcon?.color}
      isLoading={isLoading}
      {...props}
    >
      {isLoading ? (
        // Determine the loader class based on the color prop
        <span className={`loader loader-dark`} />
      ) : (
        <>
          {icon && (
            <div className="icon">
              <Icon type={icon.type} varient={icon.variant || "line"} color={icon.color} />
            </div>
          )}

          <span>{label}</span>

          {suffixIcon && (
            <div className="suffix">
              <Icon
                type={suffixIcon.type}
                varient={suffixIcon.variant || "line"}
                color={suffixIcon.color}
              />
            </div>
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
