import styled, { css } from "styled-components";
import { layout, space } from "styled-system";

import { ButtonProps, getSize } from "./types";

interface StyleButtonProps extends ButtonProps {
  iconColor: string;
  suffixIconColor: string;
}

export const StyledButton = styled.button.attrs((props: StyleButtonProps) => ({
  "data-mixpanel": JSON.stringify(props["data-mixpanel"]),
  "data-cy": props["data-cy"],
}))<StyleButtonProps>`
  ${space}
  ${layout}
  gap: ${({ size }) => getSize[size]["iconSpace"] + "px"};
  height: ${({ size }) => getSize[size]["height"] + "px"};
  font-size: ${({ size }) => getSize[size]["fontSize"] + "px"};
  border-radius: ${({ shape, size }) => (shape === "rounded" ? "9999px" : getSize[size]["radius"])};
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  padding: ${({ size }) => `${getSize[size]["padding"][0]}px ${getSize[size]["padding"][1]}px`};
  transition: all 0.4s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: inherit;
  ${({ buttonType, isLoading, iconColor, suffixIconColor }) => {
    return buttonType === "primary"
      ? primary({ isLoading, iconColor, suffixIconColor })
      : buttonType === "primaryNeutral"
        ? primaryNeutral({ isLoading, iconColor, suffixIconColor })
        : buttonType === "primaryDark"
          ? primaryDark({ isLoading, iconColor, suffixIconColor })
          : buttonType === "primaryNeutralDark"
            ? primaryNeutralDark({ isLoading, iconColor, suffixIconColor })
            : buttonType === "transparent"
              ? transparent({ isLoading, iconColor, suffixIconColor })
              : buttonType === "transparentDark"
                ? transparentDark({ isLoading, iconColor, suffixIconColor })
                : buttonType === "transparentDark2"
                  ? transparentDark2({ isLoading, iconColor, suffixIconColor })
                  : buttonType === "tertiary"
                    ? tertiary({ iconColor, suffixIconColor })
                    : buttonType === "tertiaryNeutral"
                      ? tertiaryNeutral({ iconColor, suffixIconColor })
                      : buttonType === "tertiaryNeutralDark"
                        ? tertiaryNeutralDark({ iconColor, suffixIconColor })
                        : null;
  }}

  &:disabled {
    cursor: not-allowed;
  }

  & .loader::before {
    border: ${({ buttonType }) => {
    if (buttonType === "primaryDark" || buttonType === "primaryNeutralDark") {
      return "2.5px solid rgb(123, 123, 123)";
    } else {
      return "2.5px solid #FFF";
    }
  }};
  }

  & .icon {
    display: flex;
    justify-content: center;
    & svg {
      width: ${({ size }) => getSize[size]["iconSize"] + "px"};
      height: ${({ size }) => getSize[size]["iconSize"] + "px"};
    }
  }

  & .suffix {
    display: flex;
    justify-content: center;
    & svg {
      width: ${({ size }) => getSize[size]["iconSize"] + "px"};
      height: ${({ size }) => getSize[size]["iconSize"] + "px"};
    }
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;

const primary = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) =>
    isLoading ? theme.colors.purple[400] : theme.colors.purple[800]};
  color: ${({ theme }) => theme.colors.base.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[600]};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.purple[900]};
  }
  &:disabled {
    opacity: 0.6;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.base.white};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.base.white};
  }
  ${isLoading &&
  css`
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.colors.purple[400]};
    }
  `}
`;

const primaryNeutral = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) =>
    isLoading ? theme.colors.grayCool[950] : theme.colors.grayCool[900]};
  color: ${({ theme }) => theme.colors.base.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayCool[800]};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.grayCool[950]};
  }
  &:disabled {
    /* background-color: ${({ theme }) => theme.colors.grayCool[200]}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.base.white};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.base.white};
  }
  ${isLoading &&
  css`
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.colors.grayCool[900]};
    }
  `}
`;

const primaryDark = ({ isLoading, iconColor, suffixIconColor }) => css`
  color: ${({ theme }) => theme.colors.purple[800]};
  background-color: ${({ theme }) => theme.colors.base.white};
  border: ${({ theme }) => `1px solid ${theme.colors.purple[200]}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[100]};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.purple[200]};
  }
  &:disabled {
    /* background-color: ${({ theme }) => theme.colors.grayCool[100]}; */
    opacity: 0.7;
    /* color: ${({ theme }) => theme.colors.purple[300]}; */
    border: ${({ theme }) => `1px solid ${theme.colors.grayCool[200]}`};
  }

  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.purple[800]};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.purple[800]};
  }

  ${isLoading &&
  css`
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.colors.base.white};
    }
  `}
`;

const primaryNeutralDark = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) => theme.colors.base.white};
  color: ${({ theme }) => theme.colors.grayCool[900]};
  border: ${({ theme }) => `1px solid ${theme.colors.grayCool[200]}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayCool[100]};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.grayCool[200]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.grayCool[300]}; */
    /* background-color: ${({ theme }) => theme.colors.grayCool[100]}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.grayCool[900]};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.grayCool[900]};
  }
  ${isLoading &&
  css`
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.colors.base.white};
    }
  `}
`;

const transparent = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) =>
    isLoading ? theme.colors.alphaBlack["01"] : theme.colors.alphaBlack["01"]};
  color: ${({ theme }) => theme.colors.base.black};
  &:hover {
    background-color: ${({ theme }) => theme.colors.alphaBlack["02"]};
  }
  &:active {
    color: ${({ theme }) => theme.colors.alphaBlack["03"]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.alphaBlack["01"]}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.grayCool[900]};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.grayCool[900]};
  }
  ${isLoading &&
  css`
    cursor: not-allowed;
  `}
`;

const transparentDark = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) =>
    isLoading ? theme.colors.alphaBlack["01"] : theme.colors.alphaBlack["01"]};
  color: ${({ theme }) => theme.colors.base.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.alphaBlack["02"]};
    color: ${({ theme }) => theme.colors.base.white};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.alphaBlack["03"]};
    color: ${({ theme }) => theme.colors.base.white};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.alphaBlack["01"]}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.base.white};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.base.white};
  }
  ${isLoading &&
  css`
    cursor: not-allowed;
  `}
`;

const transparentDark2 = ({ isLoading, iconColor, suffixIconColor }) => css`
  background-color: ${({ theme }) =>
    isLoading ? theme.colors.alphaWhite["01"] : theme.colors.alphaWhite["01"]};
  color: ${({ theme }) => theme.colors.base.white};
  &:hover {
    color: ${({ theme }) => theme.colors.base.white};
    background-color: ${({ theme }) => theme.colors.alphaWhite["02"]};
  }
  &:active {
    color: ${({ theme }) => theme.colors.base.white};
    background-color: ${({ theme }) => theme.colors.alphaWhite["03"]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.base.white}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.base.white};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.base.white};
  }

  ${isLoading &&
  css`
    cursor: not-allowed;
  `}
`;

const tertiary = ({ iconColor, suffixIconColor }) => css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.purple[600]};
  &:hover {
    color: ${({ theme }) => theme.colors.purple[800]};
  }
  &:active {
    color: ${({ theme }) => theme.colors.purple[500]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.purple[300]}; */
    opacity: 0.7;
  }

  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.purple[600]};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.purple[600]};
  }
`;

const tertiaryNeutral = ({ iconColor, suffixIconColor }) => css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grayCool[900]};
  &:hover {
    color: ${({ theme }) => theme.colors.grayCool[800]};
  }
  &:active {
    color: ${({ theme }) => theme.colors.grayCool[950]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.grayCool[200]}; */
    opacity: 0.7;
  }

  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.grayCool[900]};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.grayCool[900]};
  }
`;
const tertiaryNeutralDark = ({ iconColor, suffixIconColor }) => css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.base.white};
  &:hover {
    color: ${({ theme }) => theme.colors.grayCool[200]};
  }
  &:active {
    color: ${({ theme }) => theme.colors.grayCool[400]};
  }
  &:disabled {
    /* color: ${({ theme }) => theme.colors.grayCool[600]}; */
    opacity: 0.7;
  }
  & .icon > svg > path {
    stroke: ${({ theme }) => iconColor || theme.colors.base.white};
  }
  & .suffix > svg > path {
    stroke: ${({ theme }) => suffixIconColor || theme.colors.base.white};
  }
`;
