export interface OptionType {
  optionId: number;
  key?: number;
  text: string;
  isActive?: boolean;
}
export enum choiceEnum {
  SINGLE_CHOICE = "SingleChoice",
  MULTIPLE_CHOICE = "MultipleChoice",
}
export interface MultipleChoicePropsTypes {
  options: OptionType[];
  handleChange: (options: OptionType[]) => void;
  choiceType?: choiceEnum;
  setSelectedQuestionIndex: (index) => void;
}
