import { NAMESPACE_TYPE } from "@/utils/types.enums";

import { appBaseCreateApi } from "./BaseQueryApiClient";
import {
  AssignIgRequestParams,
  CapstoneQueryMeta,
  GetQueryRequestParams,
  LearningQueryMeta,
  Query,
  QueryConversationRequestParams,
  QueryConversionApiResponse,
  QueryWithNamespace,
} from "./types/queryApi.type";

export const getLearnerQuery = appBaseCreateApi({
  reducerPath: "getLearnerQuery",
  tagTypes: ["CREATE-QUERY", "CREATE-QUERY-THREAD", "GET-QUERY-TAT"],
  endpoints: (builder) => ({
    getQueryList: builder.query<QueryWithNamespace[], GetQueryRequestParams>({
      query: (query) =>
        `/v1/client/${query.isUserMentor ? "mentor" : "workspace"}/${query.cohortId}/queries`,
      providesTags: ["CREATE-QUERY", "CREATE-QUERY-THREAD"],
      transformResponse: (baseQueryReturnValue: Query[]) => {
        return baseQueryReturnValue.map((item) => {
          return {
            ...item,
            namespace: (item.meta as LearningQueryMeta).curriculumDetails
              ? NAMESPACE_TYPE.LEARNING
              : (item.meta as CapstoneQueryMeta).capstoneDetails
                ? NAMESPACE_TYPE.CAPSTONE
                : NAMESPACE_TYPE.CAREER,
          };
        });
      },
    }),
    getTatData: builder.query({
      query: (query) => {
        return {
          url: `/v1/client/mentor/tat/${query.cohortId}?isQueryTat=true`,
          method: "GET",
        };
      },
      providesTags: ["GET-QUERY-TAT"],
    }),
    createQuery: builder.mutation({
      query(payload) {
        return {
          url: `/v2/client/lms/${payload.cohortId}/query`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CREATE-QUERY"],
    }),
    getQueryConversation: builder.query<QueryConversionApiResponse, QueryConversationRequestParams>(
      {
        query: (query) =>
          `/v1/client/${query.isUserMentor ? "mentor" : "workspace"}/${
            query.cohortId
          }/query?query-id=${query.queryId}`,
        providesTags: ["CREATE-QUERY-THREAD"],
        transformResponse: (baseQueryReturnValue: QueryConversionApiResponse) => {
          return {
            ...baseQueryReturnValue,
            query: {
              ...baseQueryReturnValue.query,
              namespace: (baseQueryReturnValue.query.meta as LearningQueryMeta).curriculumDetails
                ? NAMESPACE_TYPE.LEARNING
                : (baseQueryReturnValue.query.meta as CapstoneQueryMeta).capstoneDetails
                  ? NAMESPACE_TYPE.CAPSTONE
                  : NAMESPACE_TYPE.CAREER,
            },
          };
        },
      }
    ),
    createQueryThread: builder.mutation({
      query(payload) {
        return {
          url: `/v1/client/${payload.isUserMentor ? "mentor" : "workspace"}/${
            payload.cohortId
          }/query-thread?query-id=${payload.queryId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CREATE-QUERY-THREAD", "GET-QUERY-TAT"],
    }),
    getDropdownList: builder.query({
      query: (query) => {
        return {
          url: `v2/client/lms/${query.cohortId}/cohort-node-data/list/${query.namespace}`,
          method: "GET",
        };
      },
    }),
    setMarkResolved: builder.mutation({
      query(payload) {
        return {
          url: `v2/client/lms/${payload.cohortId}/learner/query-resolve?query-id=${payload.queryId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CREATE-QUERY-THREAD"],
    }),
    sendFeedback: builder.mutation({
      query(payload) {
        return {
          url: `v2/client/lms/${payload.cohortId}/query/feedback?query-id=${payload.queryId}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CREATE-QUERY-THREAD"],
    }),
    getChannelUsers: builder.query({
      query: (query) => {
        return {
          url: `/v2/client/lms/${query.cohortId}/channel-users?query-id=${query.queryId}`,
          method: "GET",
        };
      },
    }),
    getTaskList: builder.query({
      query: (cohortId) => `v1/client/mentor/reports/${cohortId}`,
    }),
    getAggSkillLogs: builder.query({
      query: (cohortId) => `v1/client/lms/product/${cohortId}/skill-logs/agg`,
    }),
    assignIG: builder.mutation<any, AssignIgRequestParams>({
      query(payload) {
        return {
          url: `/v2/client/lms/${payload.cohortId}/query/assign-ig/?query-id=${payload.queryId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["CREATE-QUERY-THREAD"],
    }),
  }),
});

export const {
  useGetQueryListQuery,
  useGetTatDataQuery,
  useCreateQueryMutation,
  useGetQueryConversationQuery,
  useCreateQueryThreadMutation,
  useGetDropdownListQuery,
  useSetMarkResolvedMutation,
  useGetChannelUsersQuery,
  useGetTaskListQuery,
  useGetAggSkillLogsQuery,
  useSendFeedbackMutation,
  useAssignIGMutation,
} = getLearnerQuery;

export const querySelector = (param: GetQueryRequestParams) =>
  getLearnerQuery.endpoints.getQueryList.select(param);

export const selectedQuerySelector = (param: QueryConversationRequestParams) =>
  getLearnerQuery.endpoints.getQueryConversation.select(param);
