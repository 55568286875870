import SizeShiftAnimation from "@/design-system/Transitions/SideShiftAnimation";
import useMediaQuery from "@/design-system/useMediaQuery";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

import { globalNavBar, leadMentorGlobalNavBar, mentorGlobalNavBar } from "./data/globalNavbar";
import GlobalNavBar from "./globar-nav/GlobalNavBar";
import GlobalNavBarMobile from "./globar-nav/GlobalNavBarMobile";
import { SidebarMenu, SidebarWrapper } from "./sidebarStyle";

const Sidebar = ({
  globalNavBar,
  globalSideBarToggle,
  isMentorDashboard,
  isLeadMentorDashboard,
  lockedPages,
}) => {
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  return (
    <SidebarWrapper globalSideBarToggle={globalSideBarToggle}>
      {isMobile ? (
        <SidebarMenu showMenu={globalSideBarToggle}>
          <SizeShiftAnimation duration={0.3}>
            <GlobalNavBarMobile
              items={globalNavBar}
              // globalSideBarToggle={globalSideBarToggle}
              isMentorDashboard={isMentorDashboard}
              isLeadMentorDashboard={isLeadMentorDashboard}
              lockedPages={lockedPages}
            />
          </SizeShiftAnimation>
          {/* <ChannelDataMobile
            items={channelsData}
            globalSideBarToggle={globalSideBarToggle}
            handleItemClick={handleItemClick}
            selectedItem={selectedItem}
          />
          <SupportAndSettingDataMobile
            items={supportAndSettingData}
            globalSideBarToggle={globalSideBarToggle}
            handleItemClick={handleItemClick}
            selectedItem={selectedItem}
          /> */}
        </SidebarMenu>
      ) : (
        <SidebarMenu showMenu={globalSideBarToggle}>
          <GlobalNavBar
            items={globalNavBar}
            globalSideBarToggle={globalSideBarToggle}
            isMentorDashboard={isMentorDashboard}
            isLeadMentorDashboard={isLeadMentorDashboard}
            lockedPages={lockedPages}
          />
          {/* <ChannelData
            items={channelsData}
            globalSideBarToggle={globalSideBarToggle}
            handleItemClick={handleItemClick}
            selectedItem={selectedItem}
          />
          <SupportAndSettingData
            items={supportAndSettingData}
            globalSideBarToggle={globalSideBarToggle}
            handleItemClick={handleItemClick}
            selectedItem={selectedItem}
          /> */}
        </SidebarMenu>
      )}
    </SidebarWrapper>
  );
};

const SideBarMain = ({
  globalSideBarToggle,
  isMentorDashboard,
  lockedPages,
  isLeadMentorDashboard,
}) => {
  const { aiBotEnabled } = useAppSelector((state) => state.rootReducer.cohortInfo);

  return (
    <Sidebar
      globalNavBar={
        isLeadMentorDashboard
          ? leadMentorGlobalNavBar
          : isMentorDashboard
            ? mentorGlobalNavBar
            : globalNavBar(aiBotEnabled)
      }
      globalSideBarToggle={globalSideBarToggle}
      isMentorDashboard={isMentorDashboard}
      isLeadMentorDashboard={isLeadMentorDashboard}
      lockedPages={lockedPages}
    />
  );
};

export default SideBarMain;
